// summary.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Depot, PaymentMethodEnum, Processor, ProcessorTypeEnum, SelectedProcessorPaymentMethodNames, SelectedProcessorPaymentMethods, Terminal } from '@por/por-pay/shared';

import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { BehaviorSubject } from 'rxjs';
import { PaymentAdminStripeRegistrationInput, PaymentConfigExtended, ProcessorTypeEnumPretty } from '../../models';
import { AdminService } from '../../services';
import { WizardProcessorStepsEnum } from '../processor-wizard/wizard-processor-steps.enum';
import { WizardStepsUnion, WizardTerminalStepsEnum } from '../terminal-wizard/wizard-terminal-steps.enum';

@Component({
    selector: 'por-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    providers: [{ provide: MatStepper, useExisting: SummaryComponent }]
})
export class SummaryComponent implements OnInit {
    constructor(
        readonly paymentAdminService: AdminService,
        readonly translate: TranslateService
    ) {}

    @Input() processor: Processor = {} as Processor;
    @Input() terminal: Terminal = {} as Terminal;
    @Input() orgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;
    @Input() depot: Depot = {} as Depot;
    @Input() depotName = '';
    @Input() selectedProcessorPaymentMethods: SelectedProcessorPaymentMethods[] = [];
    @Input() httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
    @Input() isTerminalWizard = false;

    @Output() editStep = new EventEmitter<WizardStepsUnion>();
    @Output() save = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();
    @Output() readonly stripeRegistrationEvent = new EventEmitter<PaymentAdminStripeRegistrationInput>();
    @Output() savedProcessor = new EventEmitter<Processor>();

    protected readonly processorTypeEnumPretty = ProcessorTypeEnumPretty;

    registrationCode = '';
    title = '';
    stripeRegistrationInput: PaymentAdminStripeRegistrationInput = {} as PaymentAdminStripeRegistrationInput;
    selectedPaymentMethodsNames: string[] = [];
    isValidTerminal = false;
    isLoading$ = new BehaviorSubject<boolean>(true);

    ngOnInit() {
        this.isLoading$.next(false);
        if (this.terminal?.Terminal?.registration_code || this.terminal?.Terminal?.DeviceId || this.terminal?.Terminal?.IpAddress || this.terminal?.Terminal?.POSRegisterID) {
            this.title =
                (this.terminal?.Terminal?.registration_code ?? 'Registration Code') ||
                (this.terminal?.Terminal?.DeviceId ?? 'Device ID') ||
                (this.terminal?.Terminal?.IpAddress ?? 'IP Address') ||
                (this.terminal?.Terminal?.POSRegisterID ?? 'POS Register ID');
            this.registrationCode = this.terminal?.Terminal?.registration_code || this.terminal?.Terminal?.DeviceId || this.terminal?.Terminal?.IpAddress || this.terminal?.Terminal?.POSRegisterID;
        }
        this.selectedPaymentMethodsNames = this.selectedProcessorPaymentMethods.map(method => SelectedProcessorPaymentMethodNames[method]);
    }

    onEditProcessor() {
        this.editStep.emit(WizardProcessorStepsEnum.AddProcessor);
    }

    onEditTerminal() {
        const nextStep = this.isTerminalWizard ? WizardTerminalStepsEnum.Terminal : WizardProcessorStepsEnum.Terminal;
        this.editStep.emit(nextStep);
    }

    onEditStoreConfig() {
        this.editStep.emit(WizardProcessorStepsEnum.OrgConfig);
    }

    async onSave() {
        this.isLoading$.next(true);
        if (this.isTerminalWizard) {
            await this.onSaveTerminal();
        } else {
            await this.onSaveProcessor();
            await this.onSaveTerminal();
        }

        if (this.isValidTerminal || !this.registrationCode) {
            const message = this.isTerminalWizard ? 'Terminal added' : 'Merchant added';
            this.paymentAdminService.handleSuccess(this.translate.instant(message));
            this.isLoading$.next(false);
            if (!this.processor.RemoteDepotId) {
                this.savedProcessor.emit(this.processor);
            }
            if (this.processor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe && !this.isTerminalWizard) {
                this.editStep.emit(WizardProcessorStepsEnum.StripeRegistration);
            } else {
                this.save.emit();
            }
        }
    }

    onCancel() {
        this.paymentAdminService.handleSuccess(this.translate.instant('Cancelled.'));
        this.cancel.emit();
    }
    getProcessorType(processor: Processor): string {
        return processor.ProcessorTypeEnum ? this.processorTypeEnumPretty[processor.ProcessorTypeEnum] : 'Default Value';
    }

    private async onSaveTerminal() {
        // if we have a registrationCode a terminal is being added, so save it here.
        if (this.registrationCode) {
            this.terminal.ProcessorId = this.processor.ProcessorId;
            this.terminal.OrganizationId = this.processor.OrganizationId;
            try {
                this.terminal = await this.paymentAdminService.upsertTerminal(this.terminal, this.httpOptions);
                this.isValidTerminal = true;
            } catch (error) {
                this.isLoading$.next(false);
                this.isValidTerminal = false;
                return this.paymentAdminService.handleError(error);
            }
        }
    }

    private async onSaveProcessor() {
        if (this.orgConfig.isValid) {
            await this.paymentAdminService.submitPaymentConfig(this.orgConfig, this.httpOptions);
        }

        // Set the payment methods for the processor
        this.processor.PaymentMethods = this.selectedProcessorPaymentMethods
            .map(method => method as number)
            .filter(method => Object.values(PaymentMethodEnum).includes(method))
            .map(method => method as PaymentMethodEnum);

        // remove the selected processor payment methods from the processors that previously had these selected.
        const rawProcessors: Processor[] = await this.paymentAdminService.fetchProcessors(this.httpOptions);
        const processors: Processor[] = rawProcessors.filter(processor => processor.RemoteDepotId === this.depot.toString());

        let aCHProcessor: Processor[] = [];
        let cFProcessor: Processor[] = [];
        let ccProcessor: Processor[] = [];

        this.selectedProcessorPaymentMethods.forEach(method => {
            if (method === SelectedProcessorPaymentMethods.ACH) {
                aCHProcessor = processors?.filter(processor => processor.PaymentMethods?.includes(PaymentMethodEnum.ACH));
            }
            if (method === SelectedProcessorPaymentMethods.CreditCardCustomerFacing) {
                cFProcessor = processors?.filter(processor => processor.IsCustomerFacing === 1);
            }
            if (method === SelectedProcessorPaymentMethods.CreditCard) {
                ccProcessor = processors?.filter(processor => processor.PaymentMethods?.includes(PaymentMethodEnum.CreditCard));
            }
        });

        if (aCHProcessor.length > 0) {
            for (const processor1 of aCHProcessor) {
                processor1.PaymentMethods = processor1.PaymentMethods?.filter(method => method !== PaymentMethodEnum.ACH);
                await this.paymentAdminService.updateProcessor(processor1, this.httpOptions);
            }
        }
        if (cFProcessor.length > 0) {
            for (const processor1 of cFProcessor) {
                processor1.IsCustomerFacing = 0;
                await this.paymentAdminService.updateProcessor(processor1, this.httpOptions);
            }
        }
        if (ccProcessor.length > 0) {
            for (const processor1 of ccProcessor) {
                processor1.PaymentMethods = processor1.PaymentMethods?.filter(method => method !== PaymentMethodEnum.CreditCard);
                await this.paymentAdminService.updateProcessor(processor1, this.httpOptions);
            }
        }

        // Set the customer facing flag
        this.processor.IsCustomerFacing = this.selectedProcessorPaymentMethods.includes(SelectedProcessorPaymentMethods.CreditCardCustomerFacing) ? 1 : 0;

        if (!this.processor.ProcessorId) {
            this.processor = await this.paymentAdminService.createProcessor(this.processor, this.httpOptions);
            if (this.processor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe) {
                this.stripeRegistrationInput = {
                    processorId: this.processor.ProcessorId ?? '',
                    successUrl: this.paymentAdminService.paymentAdminConfig$.getValue().SuccessUrl,
                    failureUrl: this.paymentAdminService.paymentAdminConfig$.getValue().FailureUrl,
                    httpOptions: this.httpOptions
                };
                this.stripeRegistrationEvent.emit(this.stripeRegistrationInput);
            }
        }
    }
}
