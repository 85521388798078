/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * [Payment] The common response given by the user registration flow against a Processor.
 */
export interface PaymentAppGetRegistrationStatusResponse {
    Items?: PaymentAppGetRegistrationStatus[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
}
export interface PaymentAppGetRegistrationStatus {
    /**
     * The ProcessorId for the registrationStatus
     */
    ProcessorId?: string;
    /**
     * A human-readable message that elaborates on the ResponseCode.
     */
    Message: string;
    RegistrationStatusCode?: RegistrationStatusCode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
}

/**
 * Status codes that will tell the user if they need more info to finish their processor registration
 */
// eslint-disable-next-line no-shadow
export enum RegistrationStatusCode {
    Yay = 1,
    Nay = 2,
    Unknown = 3
}
