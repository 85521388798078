<div class="por-pay-select-processor">
    <h4 class="por-pay-title por-pay-ui-form-fields-header" translate="Processor Selection"></h4>
    <form id="por-pay-select-processor-form">
        <mat-form-field appearance="outline" class="por-pay-select por-pay-body-input">
            <mat-label>{{ 'Select Processor' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedProcessorType" name="selectedProcessor">
                <mat-option *ngFor="let processorFeature of prodReadyProcessorTypes$ | async" [value]="processorFeature">
                    {{ processorFeature.processorName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div class="por-pay-button-container">
        <div class="por-pay-button-save-container por-pay-right">
            <button class="por-pay-process-button" [disabled]="!selectedProcessorType.processorType" (click)="createProcessor()">
                {{ 'Next' | translate }}
            </button>
            <button class="por-pay-cancel-button" (click)="cancel()">
                {{ 'Cancel' | translate }}
            </button>
        </div>
    </div>
</div>
