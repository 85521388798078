import { Injectable } from '@angular/core';
import { BottomSheetElement } from '../../models';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../../components/bottom-sheet/bottom-sheet.component';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BottomSheetService {
    constructor(private readonly bottomSheet: MatBottomSheet) {}

    open(message: string, elements: BottomSheetElement[], closeWhenFocusLost?: boolean): Promise<BottomSheetElement> {
        const configBottomSheet: MatBottomSheetConfig = new MatBottomSheetConfig();
        configBottomSheet.data = { message: message, elements: elements };
        configBottomSheet.disableClose = !!closeWhenFocusLost;

        const sheet: MatBottomSheetRef<BottomSheetComponent> = this.bottomSheet.open(BottomSheetComponent, configBottomSheet);

        return firstValueFrom(sheet.afterDismissed().pipe(map((button: BottomSheetElement) => button as BottomSheetElement)));
    }
}
