import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WizardStepsUnion, WizardTerminalStepsEnum } from './wizard-terminal-steps.enum';
import { TranslateService } from '@ngx-translate/core';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { PaymentAdminStripeRegistrationInput, PaymentConfigExtended } from '../../models';
import { Depot, Processor, ProcessorTypeEnum, SelectedProcessorPaymentMethods, Terminal } from '@por/por-pay/shared';
import { TerminalEditConfig } from '../terminals/terminal-edit/terminalEditConfig';
import { AdminService } from '../../services';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'por-terminal-wizard',
    templateUrl: './terminal-wizard.component.html',
    styleUrls: ['./terminal-wizard.component.css']
})
export class TerminalWizardComponent implements OnInit {
  @Input() httpOptions: PaymentApiServiceOptions = {};
  @Input() existingOrgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;

  @Output() readonly wizardComplete = new EventEmitter<void>();
  @Output() readonly terminalEditEvent = new EventEmitter<Terminal>();
  @Output() readonly changedTerminalEvent = new EventEmitter<boolean>();
  @Output() readonly terminalsInputChanged = new EventEmitter<{
    processor: Processor;
    terminals: Terminal[];
    httpOptions: PaymentApiServiceOptions;
  }>();
  @Output() readonly wizardCanceled = new EventEmitter<void>();

  @ViewChild('stepper') stepper!: MatStepper;

  protected readonly wizardStepsEnum = WizardTerminalStepsEnum;
  currentStep$: BehaviorSubject<WizardStepsUnion> = new BehaviorSubject<WizardStepsUnion>(WizardTerminalStepsEnum.Loading);
  defaultProcessorInput = {
    processor: {} as Processor,
    terminals: [] as Terminal[],
    httpOptions: {} as PaymentApiServiceOptions
  };
  // Data to save
  orgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;
  selectedDepot: Depot = {} as Depot;
  selectedDepotName = '';
  selectedProcessor: Processor = {} as Processor;
  selectedTerminal: Terminal = {} as Terminal;
  selectedPaymentMethods: SelectedProcessorPaymentMethods[] = [];
  terminalToEdit$ = new BehaviorSubject<TerminalEditConfig>({} as TerminalEditConfig);
  defaultTerminalInput = {
    processor: {} as Processor,
    terminal: {} as Terminal,
    httpOptions: {} as PaymentApiServiceOptions
  };
  isEditingTerminal$ = new BehaviorSubject<boolean>(false);
  stripeRegistrationInput: PaymentAdminStripeRegistrationInput = {} as PaymentAdminStripeRegistrationInput;
  errorMessageToShow = '';
  isSetupWizard = false;
  processors: Processor[] = [];
  isTerminalSetup = false;
  orgDepots: Depot[] = [];
  constructor(readonly paymentAdminService: AdminService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.isTerminalSetup = true;
    this.loadDepotData().then(() => {
      if(this.existingOrgConfig.isValid) {
        const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
        this.stepper.selectedIndex = summaryStepIndex;
        this.currentStep$.next(WizardTerminalStepsEnum.StoreQuestion);
      } else {
        this.errorMessageToShow = 'Your organization is not valid. Please update your organization info on the Organization Info tab.';
        this.currentStep$.next(WizardTerminalStepsEnum.Error);
      }
    }).catch(() => {
      this.errorMessageToShow = 'Unable to retrieve your depot information. Please call support.'
      this.currentStep$.next(WizardTerminalStepsEnum.Error);
    });
  }

  // Choose a Store

  async loadDepotData() {
    this.orgDepots = await this.paymentAdminService.fetchDepots(this.httpOptions, false, true);
    this.orgDepots = this.orgDepots.filter(depot => depot.Id);
    this.processors = await this.paymentAdminService.fetchProcessors(this.httpOptions);
    // as per ticket #70144 no new Ezdebit processors or terminals.
    this.processors = this.processors.filter(processor => (processor.ProcessorTypeEnum !== ProcessorTypeEnum.PeachPayments)
      && (processor.ProcessorTypeEnum !== ProcessorTypeEnum.EziDebit));
  }

  onChooseStoreComplete($event: Depot) {
    this.selectedDepot = $event;
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Merchant');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardTerminalStepsEnum.ChooseProcessor);
  }

  onSelectDepotName($event: string) {
    this.selectedDepotName = $event;
  }

  getStripeRegistrationInput($event:PaymentAdminStripeRegistrationInput) {
    this.stripeRegistrationInput = $event;
  }

  onChooseStoreCancel() {
    this.wizardCanceled.emit();
  }


  async onProcessorBack($event: WizardTerminalStepsEnum) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardTerminalStepsEnum.StoreQuestion);
  }

  async onChooseStoreBack($event: WizardStepsUnion) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardTerminalStepsEnum.StoreQuestion);
  }

  // Terminal
  async savedTerminal(updatedTerminal: Terminal) {
    if (!updatedTerminal) {return;}
    this.isTerminalSetup = true;
    this.selectedTerminal = updatedTerminal;
    this.terminalEditEvent.emit(updatedTerminal);
  }

  setTerminalIsDirty(isTerminalDirty: boolean) {
    this.isEditingTerminal$.next(isTerminalDirty);
  }

  onTerminalBack($event: WizardStepsUnion) {
    let storeName = '';
    if($event === WizardTerminalStepsEnum.Summary) {
      storeName = 'Summary'
    } else {
      storeName = 'Choose Merchant'
    }
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === storeName);
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);
  }

  onTerminalComplete($event: Terminal) {
    this.selectedTerminal = $event;
    this.terminalEditEvent.emit(this.selectedTerminal);
    this.terminalToEdit$.next({httpOptions: this.httpOptions, processor: this.selectedProcessor, terminal: this.selectedTerminal});
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Summary');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardTerminalStepsEnum.Summary);
  }

  // Summary
  onSummaryCancel() {
    this.wizardCanceled.emit();
  }

  onSummaryComplete() {
    this.wizardComplete.emit();
  }

  onSummaryEdit($event: WizardStepsUnion) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Add Terminal');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);
  }

  async editProcessor(processorToEdit: Processor) {
    this.selectedProcessor = processorToEdit;
    if(this.selectedTerminal.Terminal) {
      this.terminalToEdit$.next({httpOptions: this.httpOptions, processor: processorToEdit, terminal: this.selectedTerminal});
    } else {
      this.terminalToEdit$.next({httpOptions: this.httpOptions, processor: processorToEdit, terminal: {} as Terminal});
    }
    this.isTerminalSetup = true;
    this.isSetupWizard = true;
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Add Terminal');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardTerminalStepsEnum.Terminal);
  }
}
