<div class="por-pay-processor-info por-pay-info-container">
    <div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
        <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
        <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div *ngIf="(isLoading$ | async) === false" class="por-pay-info-content por-pay-processor-info-content">
        <div class="por-pay-footer-buttons">
          <span *ngIf="invalidProcessorCount > 0" class="por-pay-error-flag">
            {{ invalidProcessorCount }}
          </span>
            <button apxButton class="por-pay-button por-pay-button-add" variant="primary" (click)="addProcessor()">
                {{ 'Add Payment Processors' | translate }}
            </button>
        </div>
        <table id="por-pay-processor-table">
            <tr id="por-pay-processor-table-header-row">
                <th id="por-pay-processor-label" translate="Name"></th>
                <th id="por-pay-processor-name" translate="Merchant"></th>
                <th id="por-pay-processor-Id" translate="Merchant Id"></th>
                <th id="por-pay-processor-registration-status" translate="Registration Status"></th>
            </tr>
            <ng-container *ngFor="let rowProcessorDisplay of processorDisplay$ | async; let i = index">
                <tr id="por-pay-processor-row{{ i }}" class="por-pay-row-status-{{ rowProcessorDisplay.registrationStatus.RegistrationStatusCode }}" (click)="editProcessor(rowProcessorDisplay)">
                    <td class="por-pay-processor-label">
                        <span>{{ rowProcessorDisplay.processor.Label }}</span>
                    </td>
                    <td class="por-pay-processor-name">
                        <span>{{ getProcessorType(rowProcessorDisplay.processor) }}</span>
                    </td>
                    <td class="por-pay-processor-Id">
                        <div class="por-pay-action-btn por-pay-action-btn-copy">
                            <button
                                matTooltip="{{ 'Copy to Clipboard' | translate }}"
                                [cdkCopyToClipboard]="rowProcessorDisplay.processor.ProcessorId?.toString() ?? ''"
                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                                (click)="$event.stopPropagation()">
                                <img class="copy-icon" [src]="copyToClipBoard" />
                            </button>
                        </div>
                        <span>{{ rowProcessorDisplay.processor.ProcessorId }}</span>
                    </td>
                    <td class="por-pay-processor-registration-status">
                        <span>{{ rowProcessorDisplay.registrationStatus.Message }}</span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
