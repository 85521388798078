<div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
  <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
  <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
</div>
<div class="chooseStoreComponent" *ngIf="(isLoading$ | async) === false">
  <h2 class="por-pay-title por-pay-ui-form-fields-header">{{ 'Choose A Store' | translate }}</h2>
  <p>{{ title | translate}}</p>
    <div class="por-pay-depot-config-input-fields">
      <form id="adminForm" [formGroup]="depotForm" (keydown.enter)="$event.preventDefault()">
        <div class="depot-select-div">
        <mat-form-field class="por-pay-select por-pay-body-input" appearance="outline">
          <mat-label class="input-title" translate="Store"></mat-label>
          <mat-select formControlName="depotId">
            <mat-option *ngFor="let config of depots;" [value]="config.Id">
              <span *ngIf="config.Id"> {{ config.Id }}</span>
              <span *ngIf="config.Name && config.Id"> - </span>
              <span *ngIf="config.Name">{{ config.Name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="por-pay-button-container">
          <div class="por-pay-button-save-container">
            <button
              matStepperPrevious
              class="por-pay-cancel-button"
              type="submit"
              id="por-pay-cancel-payment"
              (click)="back()">
              {{ 'Back' | translate }}
            </button>
            <button
              matStepperNext
              class="por-pay-next-button"
              type="submit"
              id="por-pay-process-payment"
              [disabled]="depotForm.invalid"
              (click)="next()">
              {{ 'Next' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
</div>
