/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  isDevMode,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  Depot,
  Processor,
  ProcessorFeature,
  ProcessorTypeEnum
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { AdminService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { WizardProcessorStepsEnum } from '../../processor-wizard/wizard-processor-steps.enum';
import { MatStepper } from '@angular/material/stepper';
import { PaymentConfigExtended } from '../../../models';

@Component({
  selector: 'por-choose-processor',
  templateUrl: './choose-processor.component.html',
  styleUrls: ['./choose-processor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: MatStepper, useExisting: ChooseProcessorComponent}]
})
export class ChooseProcessorComponent implements OnInit {
  @Input() httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
  @Input() selectedProcessorType: ProcessorFeature = {} as ProcessorFeature;
  @Input() depot: Depot = {} as Depot;
  @Input() orgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;

  @Output() readonly newProcessorAdded = new ReplaySubject<Processor>(1);
  @Output() editStep = new EventEmitter<WizardProcessorStepsEnum>();
  @Output() readonly processorEditEvent = new EventEmitter<Processor>();
  @Output() readonly previous = new EventEmitter<void>();
  @Output() readonly selectedProcessorTypeEvent = new EventEmitter<ProcessorFeature>();
  @Output() readonly selectedProcessorName = new EventEmitter<ProcessorFeature>();


  prodReadyProcessorTypes$ = new BehaviorSubject<ProcessorFeature[]>([]);
  constructor(
    private readonly paymentAdminService: AdminService,
    private readonly translateService: TranslateService,
  ) {
  }
  selectedProcessor: Processor = {} as Processor;
  currentStep$: BehaviorSubject<WizardProcessorStepsEnum> = new BehaviorSubject<WizardProcessorStepsEnum>(WizardProcessorStepsEnum.ChooseProcessor);


  async ngOnInit(): Promise<void> {
    this.fetchProcessorFeatures();
  }

  fetchProcessorFeatures() {
    this.paymentAdminService.fetchProcessorFeatures().then((data: ProcessorFeature[]) => {
      const prodReadyAndCountrySupported = data.filter(feature =>
        // show only the processors that are supported in production
        // if productionSupport is false, show if IsGAdmin is true
        (feature.formSupport === true && (isDevMode() ? true : (feature.productionSupport || this.orgConfig.isGAdmin))) &&
        feature?.country?.includes(this.orgConfig?.Country || '')
      );

      if(prodReadyAndCountrySupported.length === 0) {
        prodReadyAndCountrySupported.push({processorName: 'No merchants supported'});
      }

      if (data && this.selectedProcessorType) {
        this.selectedProcessorType = data.find(item => item.processorName === this.selectedProcessorType.processorName) || this.selectedProcessorType;
      }
      this.prodReadyProcessorTypes$.next(prodReadyAndCountrySupported);
    });
  }

  async cancel() {
    this.editStep.emit(WizardProcessorStepsEnum.StoreQuestion);
  }

  async createProcessor() {
    if (!this.selectedProcessorType.processorType) {
      this.editStep.emit(WizardProcessorStepsEnum.ChooseProcessor);
      return this.paymentAdminService.handleError(this.translateService.instant('You must select a Merchant.'));
    }
    this.selectedProcessorTypeEvent.emit(this.selectedProcessorType);
    let currencyCode = this.orgConfig?.DefaultCurrencyCode ?? '';

    if (this.selectedProcessorType.currency && !this.selectedProcessorType.currency.includes(currencyCode)) {
      currencyCode = this.selectedProcessorType.currency[0];
    }

    const procData: Processor = {
      ProcessorTypeEnum: this.selectedProcessorType.processorType as unknown as ProcessorTypeEnum,
      DefaultCurrencyCode: currencyCode,
      Config: {}
    };

    this.processorEditEvent.emit(procData);
    this.currentStep$.next(WizardProcessorStepsEnum.AddProcessor);
  }

  getHeader() {
    if(this.depot) {
      return 'Choose the Merchant you will be adding to ' + this.depot;
    } else {
      return 'Choose the Merchant you will be adding';
    }
  }
}
