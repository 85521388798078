/* eslint-disable @typescript-eslint/naming-convention */
import { BehaviorSubject } from 'rxjs';
import { SupportedProcessorPaymentMethod } from '../routes';

export enum StateEnums {
    not_ready = 1, // This one is for the initial load. It has to be tracked separately from loading because some DOM elements need to be initialized at specific times (replaces isNotReady$)
    ready = 2, // This one is default ready state of the element (replaces showForm$)
    select_merchant_form = 4, // This one enables the select merchant element
    credit_card_form = 3, // This one enables our custom credit card element
    iframe_form = 5, // This one is for enabling the iframe where merchant elements are mounted (replaces showIframe$)
    terminal_waiting = 6, // This one is for tracking Terminal specific loading (replaces isWaitingOnTerminal$)
    loading = 7, // This one is for interstitial loading (replaces isLoading$)
    processing = 8, // This one is for tracking the processing of the payment (replaces isPaymentProcessing$)
    cancelling = 9, // This one is for cancelling terminals transactions (replaces isCancellingTerminal$)
    submitted = 10 // This one disables submit button (replaces isccFormSubmitted$)
}

export type State = {
    prev: StateEnums;
    curr: StateEnums;
    show_wait: boolean;
    show_payment_methods: boolean;
};

export class CreditCardState {
    constructor(_paymentMethods$: BehaviorSubject<SupportedProcessorPaymentMethod[]>) {
        _paymentMethods$.subscribe(value => (this._payment_methods = value));
    }

    _payment_methods: SupportedProcessorPaymentMethod[] = [];

    // The different types of loads we have
    _wait_states: StateEnums[] = [StateEnums.loading, StateEnums.processing, StateEnums.terminal_waiting, StateEnums.cancelling];

    readonly _state: State = {
        prev: StateEnums.not_ready,
        curr: StateEnums.not_ready,
        show_wait: false, // Used in judgement for displaying loading element
        show_payment_methods: false // Used in judgement for displaying payment method element
    };

    get current() {
        return this._state.curr as StateEnums;
    }

    set next(nextState: StateEnums) {
        this._state.prev = this._state.curr;
        this._state.curr = nextState;
        this._state.show_wait = this._wait_states.includes(this._state.curr);

        // Only show payment methods when we are not in a wait state and we have hit the ready state with more than 1 supported payment method
        this._state.show_payment_methods = !this._state.show_wait && (this._state.show_payment_methods || (this._state.curr === StateEnums.ready && this._payment_methods.length > 1));
    }

    get showWait() {
        return this._state.show_wait;
    }

    get showPaymentMethods() {
        return this._state.show_payment_methods;
    }

    revert() {
        this.next = this._state.prev;
    }
}
