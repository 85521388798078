/* eslint-disable @typescript-eslint/naming-convention */

import { PaymentMethodEnum } from "../enums";

/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface Processor {
    ProcessorId?: string;
    OrganizationId?: number;
    RemoteDepotId?: string;
    ProcessorTypeEnum?: ProcessorTypeEnum;
    PaymentMethods?: PaymentMethodEnum[];
    IsCustomerFacing?: 0 | 1;
    Config?: ProcessorStripe | ProcessorCayan | ProcessorPeachPayments | ProcessorEziDebit | ProcessorGPIMITC;
    DefaultCurrencyCode?: string;
    TestAccount?: 0 | 1;
    Label?: string | null;
    Disabled?: 0 | 1;
    Hidden?: 0 | 1;
    CreditCard?: 0 | 1;
    DebitCard?: 0 | 1;
    ACH?: 0 | 1;
    // [k: string]: any;
}

export interface ProcessorStripe {
    UserId: string;
    DefaultLocationId?: string;
    ApplicationFeeBasisPoints?: number | null;
    [k: string]: any;
}
export interface ProcessorCayan {
    MerchantName?: string;
    MerchantSiteId?: string;
    MerchantKey?: string;
    ApiKey?: string;
    [k: string]: any;
}
export interface ProcessorPeachPayments {
    AuthorizationHeader?: string;
    ThreeDSecureEntityId?: string;
    RecurringEntityId?: string;
    [k: string]: any;
}
export interface ProcessorEziDebit {
    DigitalKey?: string;
    PublicApiKey?: string;
    [k: string]: any;
}
export interface ProcessorGPIMITC {
    XwebId?: string;
    XwebTerminalId?: string;
    XwebKey?: string;
    [k: string]: any;
}

/**
 * Processor Type, can be any of the following:
 *  1=Stripe, 2=Cayan, 3=PeachPayments, 4=EziDebit
 */
export enum ProcessorTypeEnum {
    Stripe = 1,
    Cayan = 2,
    PeachPayments = 3,
    EziDebit = 8,
    GPIMITC = 9
}
