<div class="por-pay-select-processor">
  <h2 class="por-pay-title por-pay-ui-form-fields-header">{{ getHeader() | translate }}</h2>
  <p>{{'Choose the merchant you will be adding a terminal to.' | translate}}</p>
    <form id="por-pay-select-processor-form">
      <div class="processor-list-div">
        <mat-form-field appearance="outline" class="por-pay-select por-pay-body-input">
          <mat-label class="input-title">{{ 'Select Merchant' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedSavedProcessor" name="processorId">
            <mat-option *ngFor="let processor of processors;" [value]="processor">
              <span *ngIf="depot"> {{ depot }}</span>
              <span *ngIf="depot"> - </span>
              <span>{{ getProcessorType(processor) }} - {{ processor.Label }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="por-pay-button-container">
        <div class="por-pay-button-save-container">
          <button class="por-pay-cancel-button" (click)="cancel()">
            {{ 'Back' | translate }}
          </button>
          <button [disabled]="selectedProcessorType === null" class="por-pay-process-button" (click)="next()">
            {{ 'Next' | translate }}
          </button>
        </div>
      </div>
    </form>
</div>
