/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CountryCodes,
    Currencies,
    Depot,
    DepotConfig,
    PaymentAppCommonRegistrationResponse,
    PaymentAppCommonReportResponse,
    PaymentAppCommonTerminalResponse,
    PaymentAppGetRegistrationStatus,
    PaymentConfig,
    Processor,
    ProcessorFeature,
    ProcessorTypeEnum,
    Terminal,
    emailValidatorPattern,
    phoneNumberValidatorPattern
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions, PaymentBaseApiService } from '@por/por-pay/shared/ui';
import { BehaviorSubject, Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CayanTerminalResponse, PaymentAppAdminInput, PaymentConfigExtended } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AdminService extends PaymentBaseApiService {
    paymentAdminConfig$ = new BehaviorSubject<PaymentAppAdminInput>({
        OrganizationId: 1,
        AdminApiKey: '',
        ApiUrl: '',
        SuccessUrl: '',
        FailureUrl: '',
        IsGAdmin: false
    });
    terminalsData$ = new ReplaySubject<Terminal[]>(1);

    // Defining a getter because most components access this just in time
    get paymentAdminConfig(): PaymentAppAdminInput {
        return this.paymentAdminConfig$.getValue();
    }

    private buildXFilterHeader(headers: HttpHeaders, filter: Array<{ field: string, value: any, type?: string }> = []): HttpHeaders {
        headers = headers.append(
            'X-Filter',
            JSON.stringify(filter)
        );
        return headers;
    }

    constructor(protected http: HttpClient, private readonly translate: TranslateService) {
        super(http);
    }

    handleModalError(error: string | object | unknown) {
        this.handleError(error, true);
    }

    handleModalSuccess(success: string) {
        this.handleSuccess(success, true);
    }

    hideModalFailureMessage() {
        this.hideFailureMessage(true);
    }

    hideModalSuccessMessage() {
        this.hideSuccessMessage(true);
    }

    handleError(error: string | object | unknown, isModal = false) {
        let msg = 'There was an error processing your request. Please contact support';
        if (typeof error === 'string') {
            msg = error;
        } else {
            // getting the message from the error object
            // so that it can be displayed in the UI.
            msg = (<any>error)?.error?.Message || (<any>error)?.error?.message || (<any>error)?.message || msg;
        }
        const failureMessage = document.getElementById(isModal ? 'por-pay-modal-failure-message' : 'por-pay-failure-message');

        if (failureMessage === null) {
            throw new Error(this.translate.instant('failureMessage is undefined'));
        }

        const message = failureMessage.getElementsByClassName('por-pay-message-text')[0];
        failureMessage.className += ' show';
        message.innerHTML = msg;
        failureMessage.style.zIndex = '9999';
        setTimeout(function () {
            failureMessage.className = failureMessage.className.replace(' show', '');
            message.innerHTML = '';
        }, 5000);
    }

    handleSuccess(success: string, isModal = false) {
        const successMessage = document.getElementById(isModal ? 'por-pay-modal-success-message' : 'por-pay-success-message');
        if (successMessage === null) {
            throw new Error(this.translate.instant('successMessage is undefined'));
        }
        const message = successMessage.getElementsByClassName('por-pay-message-text')[0];
        successMessage.className += ' show';
        message.innerHTML = success;
        return setTimeout(function () {
            successMessage.className = successMessage.className.replace(' show', '');
            message.innerHTML = '';
        }, 5000);
    }

    hideFailureMessage(isModal = false) {
        const failureMessage = document.getElementById(isModal ? 'por-pay-modal-failure-message' : 'por-pay-failure-message');

        if (failureMessage === null) {
            throw new Error(this.translate.instant('failureMessage is null'));
        }

        const message = failureMessage.getElementsByClassName('por-pay-message-text')[0];
        failureMessage.className = failureMessage.className.replace(' show', '');
        message.innerHTML = '';
    }

    hideSuccessMessage(isModal = false) {
        const successMessage = document.getElementById(isModal ? 'por-pay-modal-success-message' : 'por-pay-success-message');

        if (successMessage === null) {
            throw new Error(this.translate.instant('successMessage is null'));
        }

        const message = successMessage.getElementsByClassName('por-pay-message-text')[0];
        successMessage.className = successMessage?.className.replace(' show', '') ?? '';
        message.innerHTML = '';
    }

    public fetchCountryCodes(): Array<{ code: string; name: string }> {
        const countryCodesArray: Array<{ code: string; name: string }> = [];

        Object.entries(CountryCodes).forEach(countryCode =>
            countryCodesArray.push({
                code: countryCode[0],
                name: countryCode[1]
            } as {
                code: string;
                name: string;
            })
        );

        return countryCodesArray;
    }

    public fetchCurrencies(): Array<{ code: string; name: string }> {
        const currencyArray: Array<{ code: string; name: string }> = [];

        const cur = Currencies;
        // eslint-disable-next-line guard-for-in
        for (const currency in cur) {
            currencyArray.push({
                code: cur[currency].code,
                name: cur[currency].name
            });
        }

        return currencyArray;
    }

    public generateUrl(endpoint: string, publicRoute = false) {
        // essentials aws signer transactions creation
        // copy only using the new url
        return publicRoute
            ? // public routes;
            `${this.buildUrl('public')}/${endpoint}`
            : // building url, always pass the adminRoute parameter as true.
            `${this.buildUrl('apikey', true)}/${endpoint}`;
    }

    async fetchProcessors(options: PaymentApiServiceOptions, remoteDepotId?: string | null): Promise<Processor[]> {
        try {
            if (options?.headers === undefined) throw new Error('options.headers is null');

            const headers = this.buildXFilterHeader(options?.headers, !remoteDepotId ? [] : [{
                field: 'RemoteDepotId',
                value: remoteDepotId
            }]);

            return firstValueFrom(
                this.sendHttpRequest<Processor[]>('GET', this.generateUrl('processors'), {
                    headers
                })
            );
        } catch (error) {
            this.handleError(error);
            return [];
        }
    }

    async fetchDepots(options: PaymentApiServiceOptions, isModal = false, throwTheError = false, remoteDepotId?: string | null): Promise<Depot[]> {
        try {
            if (options?.headers === undefined) throw new Error('options.headers is null');

            const headers = this.buildXFilterHeader(options?.headers, !remoteDepotId ? [] : [{
                field: 'Id',// RemoteDepotId is just Id in Expert
                value: remoteDepotId,
                type: '='
            }]);

            return await firstValueFrom(
                this.sendHttpRequest<Depot[]>('GET', this.generateUrl('depots'), {
                    headers
                })
            );
        } catch (error) {
            if (throwTheError) {
                throw error;
            }
            this.handleError(error, isModal);
            return [];
        }
    }

    fetchRegistrationStatusForProcessor(processor: Processor, options: PaymentApiServiceOptions): Promise<PaymentAppGetRegistrationStatus> {
        const url = this.generateUrl('registration');

        return new Promise((resolve, reject) => {
            this.sendHttpRequest<PaymentAppGetRegistrationStatus>('POST', url, {
                body: processor,
                headers: options?.headers
            }).subscribe({
                next: (result: PaymentAppGetRegistrationStatus) => {
                    resolve(result);
                },
                error: error => {
                    reject(error);
                }
            });
        });
    }

    fetchRegistrationStatuses(options: PaymentApiServiceOptions): Observable<PaymentAppGetRegistrationStatus> {
        return new Observable(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.generateUrl('registration'), true);

            // Set any headers from the options
            if (options?.headers) {
                const headers = options.headers;
                headers.keys().forEach(key => {
                    const value = headers.get(key);
                    if (value !== null) {
                        xhr.setRequestHeader(key, value);
                    }
                });
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status !== 200) {
                        observer.error(xhr.statusText);
                    }
                }
            };

            let lastReadPosition = 0;

            xhr.onprogress = () => {
                const newText = xhr.responseText.substring(lastReadPosition);
                lastReadPosition = xhr.responseText.length;
                const lines = newText.split('\n');
                lines.forEach(line => {
                    if (line.trim().startsWith('data:')) {
                        const json = line.substring(5).trim(); // Remove the "data:" prefix
                        const data: PaymentAppGetRegistrationStatus = JSON.parse(json);
                        observer.next(data);
                    }
                });
            };

            xhr.onloadend = () => {
                observer.complete();
            };

            xhr.send();
        });
    }

    async fetchProcessorFeatures(): Promise<ProcessorFeature[]> {
        try {
            return await firstValueFrom(this.sendHttpRequest<ProcessorFeature[]>('GET', this.generateUrl('processorFeatures', true)));
        } catch (error) {
            this.handleError(error);
            return [];
        }
    }

    async fetchTerminals(options: PaymentApiServiceOptions): Promise<Array<Terminal & { ProcessorName?: string }>> {
        try {
            if (options?.headers === undefined) throw new Error('options.headers is null');

            return await firstValueFrom(
                this.sendHttpRequest<Array<Terminal & { ProcessorName?: string }>>('GET', this.generateUrl('terminals'), {
                    headers: options?.headers
                })
            );
        } catch (error) {
            this.handleModalError(error);
        }
        return [];
    }

    async deleteTerminal(terminalId: string, options: PaymentApiServiceOptions): Promise<boolean> {
        try {
            await firstValueFrom(this.sendHttpRequest('DELETE', `${this.generateUrl('terminals')}/${terminalId}`, options));
            return true;
        } catch (error) {
            this.handleModalSuccess(this.translate.instant('Terminal deleted.'));
            return false;
        }
    }

    async checkCayanTerminal(ipaddress: string): Promise<boolean> {
        let validTerminal = true;
        try {
            const url = `https://${ipaddress}:8443/v2/pos?Action=Status&Format=JSON`;
            const response = await firstValueFrom(this.sendHttpRequest<CayanTerminalResponse>('GET', url, { timeout: 2000 }));
            validTerminal = response.Status === 'Online';
        } catch (e) {
            // this.handleError('Terminal is not available. Please check the information and try again');
            validTerminal = false;
        }
        return validTerminal;
    }

    async upsertTerminal(terminal: Terminal, options: PaymentApiServiceOptions): Promise<Terminal> {
        let url = this.generateUrl('terminals');
        if (terminal.TerminalId) {
            url = `${url}/${terminal.TerminalId}`;
        }

        return new Promise((resolve, reject) => {
            this.sendHttpRequest<PaymentAppCommonTerminalResponse>('POST', url, {
                body: terminal,
                headers: options?.headers
            }).subscribe({
                next: (result: PaymentAppCommonTerminalResponse) => {
                    this.terminalsData$.next([result]);
                    resolve(result.TerminalData as Terminal);
                },
                error: error => {
                    reject(error);
                }
            });
        });
    }

    async processorHasTransactions(processorId: string, options: PaymentApiServiceOptions): Promise<boolean> {
        return new Promise(resolve =>
            this.sendHttpRequest<PaymentAppCommonReportResponse>('GET', `${this.generateUrl('reports')}/TransactionCount?ProcessorId=${processorId}`, options)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        if (result.ReportData[0] && result.ReportData[0]['Transaction_Count'] > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    },
                    error: () => resolve(true)
                })
        );
    }

    async processorIsAssociatedWithDepots(processorId: string, options: PaymentApiServiceOptions): Promise<boolean> {
        const depotConfigs = await this.fetchDepotConfigs(options);
        let includesId = false;

        if (depotConfigs.length > 0) {
            includesId = depotConfigs.some(depotConfig =>
                depotConfig.CreditCard === processorId ||
                depotConfig.CreditCardCustomerFacing === processorId ||
                depotConfig.ACH === processorId);
        }
        return includesId;
    }


    async updateProcessor(processor: Processor, options: PaymentApiServiceOptions): Promise<Processor> {
        let url = this.generateUrl('processors');
        if (processor.ProcessorId) {
            url = `${url}/${this.paymentAdminConfig.OrganizationId}/${processor.ProcessorId}`;
        }

        return await firstValueFrom(
            this.sendHttpRequest<Processor>('POST', url, {
                body: processor,
                headers: options?.headers
            })
        );
    }

    async deleteProcessor(processor: Processor, options: PaymentApiServiceOptions): Promise<boolean> {
        const url = `${this.generateUrl('processors')}/${this.paymentAdminConfig.OrganizationId}/${processor.ProcessorId}`;

        return new Promise(resolve =>
            this.sendHttpRequest('DELETE', url, options)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.handleSuccess(this.translate.instant('Processor deleted.'));
                        resolve(true);
                    },
                    error: err => {
                        this.handleError(err);
                        resolve(false);
                    }
                })
        );
    }

    async createProcessor(processor: Processor, options: PaymentApiServiceOptions): Promise<Processor> {
        try {
            return await firstValueFrom(
                this.sendHttpRequest<Processor>('POST', this.generateUrl('processors'), {
                    body: { ...processor, OrganizationId: this.paymentAdminConfig$.getValue().OrganizationId },
                    headers: options?.headers
                })
            );
        } catch (e) {
            // TODO Fix this crap later
            this.handleError(e);
            return {};
        }
    }

    async getRegistrationForm(processorTypeEnum: ProcessorTypeEnum, httpOptions: PaymentApiServiceOptions): Promise<PaymentAppCommonRegistrationResponse | any> {
        try {
            return await firstValueFrom(this.sendHttpRequest<PaymentAppCommonRegistrationResponse>('GET', this.generateUrl(`registration/${processorTypeEnum}/registrationForm`), httpOptions));
        } catch (e) {
            return e;
        }
    }

    async fetchPaymentConfig(options: PaymentApiServiceOptions): Promise<PaymentConfigExtended> {
        return new Promise((resolve, reject) => {
            this.sendHttpRequest<PaymentConfig[]>('GET', this.generateUrl('paymentConfigs'), options).subscribe({
                next: (result: PaymentConfig[]) => {
                    const paymentConfigExtended = this.extendOrgConfig(result[0] || {});
                    resolve(paymentConfigExtended);
                },
                error: error => {
                    // A 404 error is expected if no payment config data has ever been created
                    if (error?.error?.statusCode === 404) {
                        // TODO Check if this causes issues
                        const paymentConfig: PaymentConfigExtended = {
                            OrganizationId: this.paymentAdminConfig.OrganizationId,
                            Address1: '',
                            City: '',
                            State: '',
                            PostalCode: '',
                            Country: '',
                            isValid: false,
                            isGAdmin: false
                        };
                        return resolve(paymentConfig);
                    }

                    this.handleError(error);

                    return reject(error);
                }
            });
        });
    }

    async submitPaymentConfig(config: PaymentConfigExtended, options: PaymentApiServiceOptions): Promise<PaymentConfig | any> {
        try {
            const paymentConfig: PaymentConfig = {
                OrganizationId: config.OrganizationId,
                PrimaryPhone: config.PrimaryPhone,
                PrimaryPhoneCountryCode: config.PrimaryPhoneCountryCode,
                Email: config.Email,
                Address1: config.Address1,
                Address2: config.Address2,
                City: config.City,
                State: config.State,
                PostalCode: config.PostalCode,
                Country: config.Country,
                DefaultCurrencyCode: config.DefaultCurrencyCode,
            }
            const updateConfig = await firstValueFrom(this.sendHttpRequest('POST', this.generateUrl('paymentConfigs', false), { body: paymentConfig, headers: options?.headers }));
            this.handleSuccess(this.translate.instant('Payment Config updated.'));
            return updateConfig;
        } catch (error) {
            if ((<any>error)?.error?.error === 'Validation failed') {
                this.handleError(this.translate.instant('You must correct all of the errors in the form.'));
            } else {
                this.handleError(error);
            }
            throw error;
        }
    }

    async fetchDepotConfigs(options: PaymentApiServiceOptions, remoteDepotId?: string | null): Promise<DepotConfig[]> {
        try {
            if (options?.headers === undefined) throw new Error('options.headers is null');
            const headers = this.buildXFilterHeader(options?.headers, remoteDepotId ? [{
                field: 'RemoteDepotId',
                value: remoteDepotId
            }] : []);

            return firstValueFrom(
                this.sendHttpRequest<DepotConfig[]>('GET', `${this.generateUrl('depotConfigs')}`, {
                    headers
                }).pipe(map((res: any) => res.DepotConfigs))
            );
        } catch (error) {
            this.handleError(error);
            return [];
        }
    }

    async saveDepotConfig(config: DepotConfig, options: PaymentApiServiceOptions, isModal = false): Promise<DepotConfig> {
        try {
            const url = config.DepotConfigId ? `${this.generateUrl('depotConfigs')}/${config.DepotConfigId}` : `${this.generateUrl('depotConfigs')}`;

            delete config.DepotConfigId;

            return await firstValueFrom(
                this.sendHttpRequest<DepotConfig>('POST', url, {
                    body: config,
                    headers: options?.headers
                })
            );
        } catch (e) {
            this.handleError(e, isModal);
            return {};
        }
    }

    async deleteDepotConfig(config: DepotConfig, options: PaymentApiServiceOptions): Promise<boolean> {
        try {
            const url = `${this.generateUrl('depotConfigs')}/${config.DepotConfigId}`;

            await firstValueFrom(
                this.sendHttpRequest<DepotConfig>('DELETE', url, {
                    headers: options?.headers
                })
            );

            return true;
        } catch (error) {
            this.handleError(error);
            return false;
        }
    }

    extendOrgConfig(config: PaymentConfig): PaymentConfigExtended {
        const isValidPhone: boolean = config.PrimaryPhone !== undefined && phoneNumberValidatorPattern.test(config.PrimaryPhone);
        const isValidEmail: boolean = config.Email !== undefined && emailValidatorPattern.test(config.Email);
        const isValidAddress: boolean =
            config.Address1 !== undefined &&
            config.City !== undefined &&
            config.State !== undefined &&
            config.PostalCode !== undefined &&
            config.Country !== undefined;
        const isValidCurrency: boolean = config.DefaultCurrencyCode !== undefined && Currencies[config.DefaultCurrencyCode] !== undefined
        return {
            ...config,
            isValid: isValidPhone && isValidEmail && isValidAddress && isValidCurrency,
            isGAdmin: this.paymentAdminConfig$.value.IsGAdmin ?? false
        };
    }
}
