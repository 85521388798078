<!-- summary.component.html -->
<div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
  <div class="por-pay-ui-loading-header">{{ 'Loading... Please do not navigate away from this page.' | translate }}</div>
  <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
</div>
<div class="summary-tab" *ngIf="(isLoading$ | async) === false">
  <div class="summary-container">
    <h2 class="por-pay-title por-pay-ui-form-fields-header">{{ 'Summary' | translate }}</h2>
    <div class="summary-section">
      <div class="por-pay-button-container">
        <div class="title-wrapper">
          <h4>{{ 'Organization Information' | translate }}</h4>
        </div>
        <button class="summary-edit-btn" *ngIf="!isTerminalWizard" apxButton (click)="onEditStoreConfig()">{{ 'Edit' | translate }}</button>
      </div>
      <div class="summary-fields">
        <p><span class="field-title">{{ 'Phone:' | translate }}</span>{{ orgConfig.PrimaryPhone }}</p>
        <p><span class="field-title">{{ 'Email:' | translate }}</span>{{ orgConfig.Email }}</p>
        <p><span class="field-title">{{ 'Default Currency:' | translate }}</span>{{ orgConfig.DefaultCurrencyCode }}</p>
      </div>
      <div class="summary-fields">
        <div class="address">
          <span class="field-title">{{ 'Address:' | translate }}</span>
          <span>{{ orgConfig.Address1 }}</span>
          <span *ngIf="orgConfig.Address2 !== ''"><span>{{ 'Address(2):' | translate }}</span>{{ orgConfig.Address2 }}</span>
          <span>{{ orgConfig.City }}</span>
          <span>{{ orgConfig.State }}</span>
          <span>{{ orgConfig.PostalCode }}</span>
          <span>{{ orgConfig.Country }}</span>
        </div>
      </div>
    </div>
    <div class="summary-section">
      <div class="por-pay-button-container">
        <div class="title-wrapper">
          <h4>{{ 'Merchant' | translate }}</h4>
        </div>
        <button class="summary-edit-btn" *ngIf="!isTerminalWizard" apxButton (click)="onEditProcessor()">{{ 'Edit' | translate }}</button>
      </div>
      <div class="summary-fields">
        <p><span class="field-title">{{ 'Name:' | translate }}</span>{{ processor.Label }}</p>
        <p><span class="field-title">{{ 'Type:' | translate }}</span>{{ getProcessorType(processor) }}</p>
        <p><span class="field-title">{{ 'Currency:' | translate }}</span>{{ processor.DefaultCurrencyCode }}</p>
        <p><span class="field-title">{{ 'Use Case(s):' | translate }}</span>{{ selectedPaymentMethodsNames.length > 0 ? selectedPaymentMethodsNames.toString().split(',').join(', ') : 'None' }}</p>
      </div>
    </div>
    <div class="summary-section" *ngIf="registrationCode">
      <div class="por-pay-button-container">
        <div class="title-wrapper">
          <h4>{{ 'Terminal' | translate }}</h4>
        </div>
        <button class="summary-edit-btn" apxButton (click)="onEditTerminal()">{{ 'Edit' | translate }}</button>
      </div>
      <div class="summary-fields summary-fields-terminal">
        <p class="terminal-field"><span class="field-title">{{ 'Name:' | translate }}</span>{{ terminal.Label }}</p>
        <p class="terminal-field" *ngIf="registrationCode !== ''"><span class="field-title">{{ title | translate }}:</span>{{ registrationCode }}</p>
      </div>
    </div>
  </div>
  <div class="por-pay-button-container">
    <div class="por-pay-button-save-container">
      <button apxButton id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" variant="secondary" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
      <button matStepperNext apxButton id="por-pay-save-processor-edit" class="por-pay-process-button" color="primary" (click)="onSave()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</div>
