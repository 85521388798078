export function trimStringProperties(obj: any): void {
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (typeof value === 'string') {
        obj[key] = value.trim();
      } else if (typeof value === 'object') {
        trimStringProperties(value); // Recursion for nested objects
      }
    });
  }
}
