/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface TransactionLog {
    TransactionLogId?: number | string;
    TransactionId: string;
    ProcessorId?: string | null;
    TransactionLogLevel: TransactionLogLevel;
    Message?: string | null;
    /**
     * The data as returned from the Processor, or arbitrary data that we define.
     */
    Data?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [k: string]: any;
    };
}

/**
 * The severity of this TransactionLog. This uses some common log levels.
 */
// eslint-disable-next-line no-shadow
export enum TransactionLogLevel {
    ALL = 1,
    DEBUG = 2,
    INFO = 3,
    WARN = 4,
    ERROR = 5,
    FATAL = 6
}
