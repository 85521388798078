import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  Depot,
  Processor,
  ProcessorTypeEnum,
  ProcessorTypeEnumPretty,
  Terminal,
  TerminalTableSchema
} from '@por/por-pay/shared';
import { BottomSheetElement } from '../../../models';
import { AdminService, BottomSheetService } from '../../../services';
import { TerminalEditConfig } from './terminalEditConfig';
import { BehaviorSubject } from 'rxjs';
import { ipValidatorPattern } from '@por/por-pay/shared/ui';
import { WizardProcessorStepsEnum } from '../../processor-wizard/wizard-processor-steps.enum';
import { WizardStepsUnion, WizardTerminalStepsEnum } from '../../terminal-wizard/wizard-terminal-steps.enum';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
    selector: 'por-terminal-edit',
    templateUrl: './terminal-edit.component.html',
    styleUrls: ['./terminal-edit.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: TerminalEditComponent }]
})
export class TerminalEditComponent implements OnChanges, AfterViewInit {
    @Input() terminalConfig: TerminalEditConfig = {
        processor: {},
        terminal: {},
        httpOptions: {}
    };
    @Input() isWizardSetup = false;
    @Input() isTerminalWizard = false;
    @Input() depot: Depot = {} as Depot;
    @Input() depotName = '';
    @Input() isGAdmin? = false;

    @Output() editStep = new EventEmitter<WizardStepsUnion>();
    @Output() readonly terminalConfigChangeEvent = new EventEmitter<boolean>();
    @Output() readonly terminalCancelEditEvent = new EventEmitter<{ isDirty: boolean; terminalId?: string }>();
    @Output() readonly terminalDeletedEvent = new EventEmitter<string>();
    @Output() readonly terminalSavedEvent = new EventEmitter<Terminal>();
    @Output() readonly terminalFinishedEditingEvent = new EventEmitter<Terminal>();

  protected readonly processorTypeEnum = ProcessorTypeEnum;
    terminalForm!: FormGroup;
    terminalFormProps: Array<{ key: string; label: string; hint: string; error: string }> = [];
    terminalLocationAddressForm: FormGroup = new FormGroup([]);
    terminalLocationAddressFormProps: Array<{ key: string; label: string; hint: string }> = [];
    processor: Processor = {} as Processor;
    terminal: Terminal = {} as Terminal;
    countryCodes: Array<{ code: string; name: string }> = [];
    terminalLocationLoaded$ = new BehaviorSubject<boolean>(false);
    isLoading$ = new BehaviorSubject<boolean>(true);

  get city() {
        return this.terminalLocationAddressForm.get('city');
    }
    get state() {
        return this.terminalLocationAddressForm.get('state');
    }
    get postalCode() {
        return this.terminalLocationAddressForm.get('postal_code');
    }

    constructor(
        private readonly paymentAdminService: AdminService,
        private readonly bottomSheetService: BottomSheetService,
        private readonly translateService: TranslateService,
        private readonly snackBar: MatSnackBar
    ) {}

    async ngOnChanges(): Promise<void> {
        this.isLoading$.next(true);
        await this.setupTerminal();
    }

    ngAfterViewInit(): void {
      this.terminalForm.valueChanges.subscribe(() => {
          this.terminalConfigChangeEvent.emit(this.terminalForm.dirty);
        });
    }

    get ipAddress() {
        return this.terminalForm.get('IpAddress');
    }

    checkTerminal: AsyncValidatorFn = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        if (this.processor.ProcessorTypeEnum === undefined) {
            throw new Error(this.translateService.instant('ProcessorTypeEnum is undefined'));
        }

        const val = await this.paymentAdminService.checkCayanTerminal(control.value);
        if (!val) {
            return { terminalUnreachable: {} };
        }

        return null; // Return null if validation passes
    };

    async setupTerminal() {
        this.countryCodes = this.paymentAdminService.fetchCountryCodes();
        this.processor = this.terminalConfig?.processor;
        this.terminal = this.terminalConfig?.terminal;
        // IMPORTANT: The enum string value of the selected Processor must match
        // the format 'Terminal' + '<ProcessorName>' and it is case-sensitive.
      let processorType = '';
      if(this.processor.ProcessorTypeEnum) {
          processorType = ProcessorTypeEnumPretty[this.processor.ProcessorTypeEnum];
        }
        // Set the terminal name
        if(typeof this.depot === 'string' && this.depot !== '') {
          this.terminal.Label = this.depot + ' ' + processorType + ' Terminal';
        }

      // copy from the Terminal object
        this.terminal = {
            ...this.terminalConfig?.terminal,
            // if the Terminal is not null, fill in existing terminals data, otherwise
            // initialize using an empty object.
          // eslint-disable-next-line @typescript-eslint/naming-convention
            Terminal: this.terminal.Terminal ? { ...this.terminal.Terminal } : { location: { address: {} } },
            // always set the processorId
          // eslint-disable-next-line @typescript-eslint/naming-convention
            ProcessorId: this.processor.ProcessorId
        };

        if (this.processor.ProcessorTypeEnum === undefined) {
            throw new Error(this.translateService.instant('ProcessorTypeEnum is undefined'));
        }
        const processorTypeEnumString = ProcessorTypeEnum[this.processor.ProcessorTypeEnum];

        // find the JSON schema for the Terminal using the ProcessorTypeEnum
        const terminalSchema =
            Object.assign(TerminalTableSchema)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .properties.Terminal.anyOf.filter((terminal: any) => terminal.title === `Terminal${processorTypeEnumString}`)
                // array.filter returns an array but since we are absolute that it will only return 1 match
                // call shift() to get that first item from the filtered result.
                .shift() || {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const terminalGroup: any = {
            terminalId: new FormControl({ value: this.terminal.TerminalId, disabled: true }),
            terminalLabel: new FormControl(this.terminal.Label)
        };

        // reset the dummy container for our label and hint, this will be used on the html side to loop for the controls
        // that needs to be rendered to the UI.
        this.terminalFormProps = []; // reset;

        // setup the form using the Terminal properties from the selected JSON schema.
        Object.keys(terminalSchema.properties)
            // Schema like Stripe have a lot of properties which we don't really require to show in the UI.
            // In the Stripe Terminal's JSON schema the required[] field is populated with the property (registration_code) that
            // we want to show to the user and here we filter that. On the other hand if the required[] is not defined in the
            // Terminal's JSON schema like Cayan/Meet in the Cloud and EziDebit just return true, we want them to show up in the UI.
            .filter(propKey => (terminalSchema.required ? terminalSchema.required.includes(propKey) : true))
            .forEach(propKey => {
                if (this.terminal.Terminal === undefined) {
                    throw new Error(this.translateService.instant('terminalSetup.Terminal is undefined'));
                }

                // initialize the FormControl for each of the Terminal's property that we want to show in the UI
                // and populate with the initial (state) value. The initial value is coming from the Terminal record that was passed along
                // by the calling function when editing an existing Terminal or just giving it a default value of empty string
                // if the Terminal object is null in the scenario where a new Terminal is being added.
                if (propKey === 'IpAddress') {
                    terminalGroup[propKey] = new FormControl(this.terminal.Terminal?.[propKey] || '', [Validators.pattern(ipValidatorPattern)], [this.checkTerminal]);
                } else {
                    terminalGroup[propKey] = new FormControl({
                        value: this.terminal.Terminal?.[propKey] || '',
                        disabled: terminalSchema.properties[propKey]?.readOnly
                    });
                }

                // update our dummy property container
                this.terminalFormProps.push({
                    key: propKey,
                    // if title is defined in the JSON schema let's use that, otherwise use the propKey.
                    label: terminalSchema.properties[propKey]?.title || propKey,
                    // if title is description in the JSON schema let's use that, otherwise empty string is fine.
                    hint: terminalSchema.properties[propKey]?.description || '',
                    error: terminalSchema.properties[propKey]?.errorMessage || ''
                });
            });

        this.terminalForm = new FormGroup(terminalGroup);
        this.terminalForm.updateValueAndValidity();

        if (this.processor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe) {
            // Default Stripe Terminal City and State to Organization settings (Fields required by Stripe Locations)
            this.terminalLocationAddressFormProps = [];

            this.terminalLocationAddressFormProps = [
                {
                    key: 'city',
                    label: 'City *',
                    hint: ''
                },
                {
                    key: 'state',
                    label: 'State *',
                    hint: ''
                },
                {
                    key: 'country',
                    label: 'Country *',
                    hint: ''
                },
                {
                    key: 'postal_code',
                    label: 'Postal Code *',
                    hint: ''
                }
            ];

            // Convert Location to object if we had initially saved as string
            if (this.terminal.Terminal && typeof this.terminal.Terminal.location === 'string') {
                this.terminal.Terminal.location = {
                    id: this.terminal?.Terminal.location,
                    address: {}
                };
            }

            if (!this.terminal?.TerminalId) {
                await this.paymentAdminService.fetchPaymentConfig(this.terminalConfig.httpOptions).then(result => {
                    if (this.terminal?.Terminal === undefined) {return;}
                    this.terminal.Terminal.location.address['city'] = result.City;
                    this.terminal.Terminal.location.address['state'] = result.State;
                    this.terminal.Terminal.location.address['country'] = result.Country;
                    this.terminal.Terminal.location.address['postal_code'] = result.PostalCode;
                });
            }
            this.terminalLocationLoaded$.next(true);

          const terminalLocationAddressFormGroup = {
                city: new FormControl(this.terminal.Terminal?.location.address.city, Validators.required),
                state: new FormControl(this.terminal.Terminal?.location.address.state, Validators.required),
                country: new FormControl(this.terminal.Terminal?.location.address.country, Validators.required),
              // eslint-disable-next-line @typescript-eslint/naming-convention
                postal_code: new FormControl(this.terminal.Terminal?.location.address.postal_code, Validators.required)
            };

            this.terminalLocationAddressForm = new FormGroup(terminalLocationAddressFormGroup);
            this.terminalLocationAddressForm.updateValueAndValidity();
        }
        this.isLoading$.next(false);
    }

  getHeader() {
    let label = this.depotName ?? '';

    if (this.terminalConfig.processor.ProcessorTypeEnum) {
      label += ' | ' + ProcessorTypeEnumPretty[this.terminalConfig.processor.ProcessorTypeEnum];
    }

    return label;
  }

    isRequired(fieldName: string): boolean {
        if(fieldName.toLowerCase() === 'country') {return false;}
        const field = (fieldName === 'state') ? this.state : (fieldName === 'city') ? this.city : this.postalCode;
        return field && field?.errors && field?.errors['required'] && (field.dirty || field.touched);
    }

    cancel() {
        this.terminalCancelEditEvent.emit({ isDirty: this.terminalForm.dirty, terminalId: this.terminal.TerminalId });
        if(this.isWizardSetup || this.isTerminalWizard) {
          const nextStep = this.isTerminalWizard ? WizardTerminalStepsEnum.ChooseProcessor : WizardProcessorStepsEnum.TerminalQuestion;
          this.editStep.emit(nextStep);
        }
    }

    async delete() {
        const message = 'Are you certain you want to delete this terminal?';

        const buttons: BottomSheetElement[] = [
            {
                title: 'Yes'
            },
            {
                title: 'Cancel'
            }
        ];

        const bottomSheet = await this.bottomSheetService.open(message, buttons);
        if (bottomSheet?.title === 'Yes' && this.terminal.TerminalId !== undefined) {
            const deletedTerminal = await this.paymentAdminService.deleteTerminal(this.terminal.TerminalId, this.terminalConfig.httpOptions);
            if (deletedTerminal) {
                this.terminalDeletedEvent.emit(this.terminal.TerminalId);
            }
        }
    }

    async save() {
      const newTerminal = this.getTerminalDataFromForm();
      const hasRegistrationCode = newTerminal.Terminal?.registration_code || newTerminal.Terminal?.DeviceId || newTerminal.Terminal?.IpAddress || newTerminal.Terminal?.POSRegisterID;
      // as per QA Bug #74675 we need to check both the terminalForm AND terminalLocationAddressForm for validity.
      const checkValidity = (this.isWizardSetup || this.isTerminalWizard) ? this.terminalForm.valid && this.terminalLocationAddressForm.valid : ((this.terminalForm.dirty && this.terminalForm.valid) ||
        (this.terminalLocationAddressForm.dirty && this.terminalLocationAddressForm.valid));
      // TODO Remove this short circuit when apx button disablement is properly fixed
        if (!checkValidity || !hasRegistrationCode) {
            return;
        }

        if (this.processor.ProcessorTypeEnum === undefined) {
            throw new Error(this.translateService.instant('ProcessorTypeEnum is undefined'));
        }

        if (newTerminal.TerminalId === 'New Terminal') {
            delete newTerminal.TerminalId;
        }

        delete newTerminal['ProcessorName'];

        // Grab Terminal Location fields
        if (newTerminal.Terminal && this.processor?.ProcessorTypeEnum === ProcessorTypeEnum.Stripe) {
            newTerminal.Terminal.location = this.getTerminalLocationAddressDataFromForm(newTerminal.Terminal.location);
        }

        if(this.isTerminalWizard || this.isWizardSetup) {
          this.terminalFinishedEditingEvent.emit(newTerminal);
        } else {
          this.paymentAdminService.upsertTerminal(newTerminal, this.terminalConfig.httpOptions).then(updatedTerminal => {
            this.terminalForm.markAsPristine();
            this.terminalConfigChangeEvent.emit(this.terminalForm.dirty);
            this.terminalSavedEvent.emit(updatedTerminal);
          }).catch((error) => {
            return this.paymentAdminService.handleError(error);
          });
        }
    }

    getTerminalDataFromForm(): Terminal {
        // get our updated values from the form.
        const formValue = this.terminalForm.value;

        // copy the values to the newTerminal;
        const newTerminal: Terminal = { ...this.terminal };

        if (newTerminal.Terminal === undefined) {
            throw new Error(this.translateService.instant('terminals?.Terminal.Terminal is undefined'));
        }

        const newTerminalTerminal = newTerminal.Terminal;

        // update the label with data from the form.
        newTerminal.Label = formValue.terminalLabel;

        // we don't need this so delete.
        delete formValue.terminalLabel;

        // update the values of the Terminal from the form.
        Object.keys(formValue).forEach(key => (newTerminalTerminal[key] = formValue[key]));

        return newTerminal;
    }

    getTerminalLocationAddressDataFromForm(location: { id: string; address?: object } | string) {
        // Convert Location to object if we had initially saved as string
        if (typeof location === 'string') {
            location = {
                id: location
            };
        }

        const address: { [key: string]: string } = {};
        const locationAddressFormValue = this.terminalLocationAddressForm.value;
        Object.keys(locationAddressFormValue).forEach(key => (address[key] = locationAddressFormValue[key]));
        location.address = address;

        return location;
    }

    public onClipboardCopy(successful: boolean): void {
        if (successful) {
            this.snackBar.open(this.translateService.instant('Terminal Id was copied to clipboard'), undefined, {
                duration: 5000,
                panelClass: ['success-snackbar']
            });
        } else {
            this.snackBar.open(this.translateService.instant('Copy to clipboard was NOT successful'), undefined, {
                duration: 5000,
                panelClass: ['error-snackbar']
            });
        }
    }
}
