/* eslint-disable @typescript-eslint/naming-convention */
import { CayanReasonToPorPayErrorMessage, CayanErrorCodeToPorPayErrorCode, PaymentAppPayOutput } from '@por/por-pay/shared';

export class PorPayError extends Error {
    paymentAppOutput: PaymentAppPayOutput;

    constructor(paymentAppOutput: PaymentAppPayOutput) {
        super(paymentAppOutput.Message);
        this.paymentAppOutput = paymentAppOutput;
        this.name = 'PorPayError';

        // This is to ensure that the instance of check works correctly.
        Object.setPrototypeOf(this, PorPayError.prototype);
    }

    static convertCayanError(error: Array<{ error_code: string; reason: string }>, transactionId?: string) {
        // Only throw the first error
        const { error_code, reason } = error[0];
        return new PorPayError({
            TransactionId: transactionId,
            Message: CayanReasonToPorPayErrorMessage[reason] || 'An unknown error occurred. Refresh the page and try again.',
            ResponseCode: CayanErrorCodeToPorPayErrorCode[error_code]
        });
    }
}
