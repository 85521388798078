/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Depot } from '@por/por-pay/shared';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from '../../services';
import { WizardProcessorStepsEnum } from '../processor-wizard/wizard-processor-steps.enum';
import { WizardStepsUnion, WizardTerminalStepsEnum } from '../terminal-wizard/wizard-terminal-steps.enum';

@Component({
  selector: 'por-choose-a-store',
  templateUrl: './choose-a-store.component.html',
  styleUrls: ['./choose-a-store.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ChooseAStoreComponent implements OnInit {
  @Input() depots: Depot[] = [];
  @Input() isTerminalWizard = false;
  @Input() selectedDepotId: Depot = {} as Depot;

  @Output() readonly selectedDepot = new EventEmitter<Depot>();
  @Output() readonly selectedDepotName = new EventEmitter<string>();
  @Output() readonly wizardCanceled = new EventEmitter<void>();
  @Output() editStep = new EventEmitter<WizardStepsUnion>();
  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public translate: TranslateService,
    private readonly paymentAdminService: AdminService,
    private readonly translateService: TranslateService
  ) {}

  depotForm!: FormGroup;
  title = ''

  ngOnInit() {
    this.depots.push({
      ModelType:'',
      Id:'',
      Name:'Unassigned Merchant',
      Addresses: [],
      Emails: [],
      Identifiers: null,
      Phones:[],
      AdditionalFields: null,
      CreatedDateTime:'',
      UpdatedDateTime:'',
      Hidden: 0}
    );
    this.title = this.isTerminalWizard ? 'Choose the store you will be adding a terminal for.' : 'Choose the store you will be adding a merchant for.';
    this.depotForm  = new FormGroup({
      depotId: new FormControl(this.selectedDepotId),
    });
    this.isLoading$.next(false);
  }

  back() {
    this.wizardCanceled.emit();
  }
  next() {
    const depotName = this.depots.find(depot => depot.Id === this.depotForm.value.depotId)?.Name;
    const depotId = this.depots.find(depot => depot.Id === this.depotForm.value.depotId)?.Id;
    if(!depotId && (depotId !== '')) {
      const step = this.isTerminalWizard ? WizardTerminalStepsEnum.StoreQuestion : WizardProcessorStepsEnum.StoreQuestion
      this.editStep.emit(step);
      return this.paymentAdminService.handleError(this.translateService.instant('You must select an option.'));
    } else {
      this.selectedDepot.emit(this.depotForm.value.depotId);
      this.depots.pop();
      this.selectedDepotName.emit(depotName)
    }
  }
}
