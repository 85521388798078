/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  Currencies,
  Depot,
  PaymentMethodEnum,
  Processor,
  ProcessorCayan,
  ProcessorEziDebit,
  ProcessorFeature,
  ProcessorGPIMITC,
  ProcessorPeachPayments,
  ProcessorTypeEnum, SelectedProcessorPaymentMethodNames, SelectedProcessorPaymentMethods,
  Terminal
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { AdminService, BottomSheetService } from '../../../services';
import {
  BottomSheetElement,
  PaymentConfigExtended,
  ProcessorExtended,
  ProcessorTypeEnumPretty
} from '../../../models';
import { WizardProcessorStepsEnum } from '../../processor-wizard/wizard-processor-steps.enum';
import { MatStepper } from '@angular/material/stepper';
import { PaymentMethodsComponent } from '../../payment-methods/payment-methods.component';

@Component({
  selector: 'por-processor-edit-component',
  templateUrl: './processor-add.component.html',
  styleUrls: ['./processor-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatStepper, useExisting: ProcessorAddComponent }]
})
export class ProcessorAddComponent implements OnInit {
  constructor(
    public paymentAdminService: AdminService,
    private readonly bottomSheetService: BottomSheetService,
  ) {
  }

  @Input() orgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;
  @Input() processorInput = {
    processor: {} as ProcessorExtended,
    terminals: [] as Terminal,
    httpOptions: {} as PaymentApiServiceOptions
  };
  @Input() depot: Depot = {} as Depot;
  @Input() depotName = '';
  @Input() savedProcessor: Processor = {} as Processor;
  @Input() selectedPaymentMethodsInput: SelectedProcessorPaymentMethods[] = [];

  @Output() readonly savedProcessorEvent = new EventEmitter<boolean>();
  @Output() readonly processorConfigChangeEvent = new EventEmitter<boolean>();
  @Output() readonly updatedProcessor = new EventEmitter<ProcessorExtended>();
  @Output() readonly previous = new EventEmitter<void>();
  @Output() editStep = new EventEmitter<WizardProcessorStepsEnum>();
  @Output() readonly processorPaymentMethods = new EventEmitter<SelectedProcessorPaymentMethods[]>();
  @Output() readonly userHasFinishedThisPage = new EventEmitter<boolean>();

  protected readonly ProcessorTypeEnum = ProcessorTypeEnum;

  selectedProcessorCurrencies: Array<{ code: string; name: string }> = [];
  selectedProcessorType: ProcessorFeature[] = [];
  selectedProcessorTypeEnum?: ProcessorTypeEnum;
  selectedProcessor: Processor & { supportedPaymentMethods?: PaymentMethodEnum[] } = {};
  selectedProcessorPaymentMethods: Array<{ label: string; key: string }> = [];
  existingCFProcessorName = '';
  existingACHProcessorName = '';
  existingCCProcessorName = '';

  @ViewChild(PaymentMethodsComponent) paymentMethodsComponent!: PaymentMethodsComponent;

  async ngOnInit(): Promise<void> {
    const allProcessorFeatures = await this.paymentAdminService.fetchProcessorFeatures();
    this.selectedProcessorType = allProcessorFeatures.filter(feature => feature.processorType === this.processorInput.processor.ProcessorTypeEnum);
    this.processorConfigChangeEvent.emit(true);
    const cur = Currencies;
    const currencyArray = [];
    // eslint-disable-next-line guard-for-in
    for (const currency in cur) {
      currencyArray.push({
        code: cur[currency].code,
        name: cur[currency].name
      });
    }
    this.selectedProcessorCurrencies = currencyArray.filter(c => this.selectedProcessorType[0].currency?.includes(c.code));

    if (this.processorInput.processor.ProcessorTypeEnum === this.savedProcessor.ProcessorTypeEnum) {
      this.processorInput.processor = this.savedProcessor;
    }

    this.selectedProcessor = this.processorInput.processor;
    this.selectedProcessorTypeEnum = this.selectedProcessor.ProcessorTypeEnum as ProcessorTypeEnum;

    let processorType = '';
    if (this.selectedProcessor.ProcessorTypeEnum) {
      processorType = ProcessorTypeEnumPretty[this.selectedProcessor.ProcessorTypeEnum];
    }
    // Set the terminal name
    if (!this.selectedProcessor.Label) {
      this.selectedProcessor.Label = this.depot + ' ' + processorType + ' Merchant';
    }
  }
  get cayanConfig(): ProcessorCayan {
    return this.selectedProcessor.Config as ProcessorCayan;
  }

  get peachConfig(): ProcessorPeachPayments {
    return this.selectedProcessor.Config as ProcessorPeachPayments;
  }

  get mITCConfig(): ProcessorGPIMITC {
    return this.selectedProcessor.Config as ProcessorGPIMITC;
  }

  get eziDebitConfig(): ProcessorEziDebit {
    return this.selectedProcessor.Config as ProcessorEziDebit;
  }

  async cancel() {
    this.editStep.emit(WizardProcessorStepsEnum.ChooseProcessor);
  }

  getExistingCFProcessorName(event: string) {
    this.existingCFProcessorName = event;
  }

  getExistingACHProcessorName(event: string) {
    this.existingACHProcessorName = event;
  }

  getExistingCCProcessorName(event: string) {
    this.existingCCProcessorName = event;
  }

  async next() {
    const chosenPaymentMethods: SelectedProcessorPaymentMethods[] = [];
    const overrideProcessorNames = [];
    const buttons: BottomSheetElement[] = [
      {
        title: 'Yes'
      },
      {
        title: 'Cancel'
      }
    ];

    if(this.paymentMethodsComponent) {
      const paymentMethods = this.paymentMethodsComponent.getFormValue();

      if (paymentMethods?.creditCard) {
        chosenPaymentMethods.push(SelectedProcessorPaymentMethods.CreditCard);
      }
      if (paymentMethods?.creditCardCustomerFacing) {
        chosenPaymentMethods.push(SelectedProcessorPaymentMethods.CreditCardCustomerFacing);
      }
      if (paymentMethods?.aCH) {
        chosenPaymentMethods.push(SelectedProcessorPaymentMethods.ACH);
      }

      if (this.existingACHProcessorName && chosenPaymentMethods.includes(SelectedProcessorPaymentMethods.ACH)) {
        overrideProcessorNames.push(SelectedProcessorPaymentMethodNames[SelectedProcessorPaymentMethods.ACH]);
      }
      if (this.existingCFProcessorName && chosenPaymentMethods.includes(SelectedProcessorPaymentMethods.CreditCardCustomerFacing)) {
        overrideProcessorNames.push(SelectedProcessorPaymentMethodNames[SelectedProcessorPaymentMethods.CreditCardCustomerFacing])
      }
      if (this.existingCCProcessorName && chosenPaymentMethods.includes(SelectedProcessorPaymentMethods.CreditCard)) {
        overrideProcessorNames.push(SelectedProcessorPaymentMethodNames[SelectedProcessorPaymentMethods.CreditCard])
      }
    }

    if (overrideProcessorNames.length > 0) {
      const message = `The following use cases already have merchants assigned: ${overrideProcessorNames.toString().split(',').join(', ')}. Are you sure you want to change the merchants for these use cases?`;
      const bottomSheet = await this.bottomSheetService.open(message, buttons);
      if (bottomSheet?.title === 'Yes') {
        this.userHasFinishedThisPage.emit(true);
        this.processorInput.processor.RemoteDepotId = this.depot.toString();
        this.updatedProcessor.emit(this.processorInput.processor);
        this.processorPaymentMethods.emit(chosenPaymentMethods);
      } else {
        this.editStep.emit(WizardProcessorStepsEnum.AddProcessor);
      }
    } else {
      this.userHasFinishedThisPage.emit(true);
      if(this.depot) {
        this.processorInput.processor.RemoteDepotId = this.depot.toString();
      }
      this.updatedProcessor.emit(this.processorInput.processor);
      this.processorPaymentMethods.emit(chosenPaymentMethods);
    }
  }

  getHeader() {
    let label = this.depotName ?? this.depot;

    if (this.selectedProcessor.ProcessorTypeEnum) {
      label += ' | ' + ProcessorTypeEnumPretty[this.selectedProcessor.ProcessorTypeEnum];
    }

    return label;
  }

  newCurrencySelected() {
    const achNotInSelectedMethods = !this.selectedProcessorPaymentMethods.some(method => method.key === 'ACH');
    const achNotInSupportedMethods = !this.processorInput.processor?.supportedPaymentMethods?.some((value: number) => value === 4);
    const country = this.processorInput.processor?.paymentConfig?.Country;

    this.processorInput.processor?.supportedPaymentMethods?.forEach((spt: PaymentMethodEnum) => {
      if (PaymentMethodEnum[spt] === 'ACH' && this.selectedProcessor.DefaultCurrencyCode !== 'USD') {
        // If ACH is present, remove it
        const index = this.selectedProcessorPaymentMethods.findIndex(method => method.key === 'ACH');
        if (index !== -1) {
          this.selectedProcessorPaymentMethods.splice(index, 1);
        }
      }
    });

    // If USD Is The DefaultCurrencyCode and ACH is NOT In the Array AND ACH is a supported method: add it back in
    if (this.selectedProcessor.DefaultCurrencyCode === 'USD' && country === 'US' && achNotInSelectedMethods && !achNotInSupportedMethods) {
      this.selectedProcessorPaymentMethods.push({
        label: 'ACH',
        key: 'ACH'
      });
    } else if (this.selectedProcessor['ACH'] === 1 && this.selectedProcessor.DefaultCurrencyCode !== 'USD') {
      // Uncheck The ACH box if we've just removed it
      this.selectedProcessor['ACH'] = 0;
    }
  }
}
