/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface PaymentConfig {
    OrganizationId?: number | string;
    /**
     * Must be E.64 format with country code. ie: +18001234567.
     */
    PrimaryPhone?: string;
    PrimaryPhoneCountryCode?: string;
    Email?: string;
    Address1?: string;
    Address2?: string;
    City?: string;
    State?: string;
    PostalCode?: string;
    Country?: string;
    DefaultCurrencyCode?: string;
    Hidden?: 0 | 1;
    LogLevel?: LogLevelEnum;
}

export enum LogLevelEnum {
  ERROR = 1,
  DEBUG = 2
}
