<mat-card class="por-pay-payment-admin">
  <div id="por-pay-success-message" class="por-pay-message success">
    <div class="por-pay-message-header">
      <span class="por-pay-message-title" translate="Success"></span>
      <span (click)="paymentAdminService.hideSuccessMessage()" class="por-pay-message-close">&times;</span>
    </div>
    <span class="por-pay-message-text"></span>
  </div>
  <div id="por-pay-failure-message" class="por-pay-message failure">
    <div class="por-pay-message-header">
      <span class="por-pay-message-title" translate="Error"></span>
      <span (click)="paymentAdminService.hideFailureMessage()" class="por-pay-message-close">&times;</span>
    </div>
    <span class="por-pay-message-text"></span>
  </div>
  <ng-container>
    <ng-template *ngIf="apiKeyIsValid$ | async" [ngTemplateOutlet]="showContents"></ng-template>
  </ng-container>
</mat-card>
<ng-template #showContents>
  <mat-tab-group class="por-pay-tab-group" #tabGroup>
    <mat-tab>
      <ng-template class="por-pay-tab-label" mat-tab-label>
        {{ 'Organization Info' | translate }}
        <span *ngIf="!paymentConfigInput.paymentConfig.isValid && !paymentConfigInput.isLoading"
          class="por-pay-error-flag">!</span>
      </ng-template>
      <ng-template matTabContent>
        <por-payment-config-admin-form [paymentConfigInput]="paymentConfigInput"
          (savePaymentConfigEvent)="savePaymentConfig($event)">
        </por-payment-config-admin-form>
      </ng-template>
    </mat-tab>
    <!-- TODO make the titles into variables -->
    <mat-tab>
      <ng-template class="por-pay-tab-label" mat-tab-label>
        <span>{{ 'Store Configuration' | translate }}</span>
      </ng-template>
      <ng-template matTabContent>
        <por-depot-config-list *ngIf="!showDepotConfigForm" (storeEditEvent)="editDepot($event)"
          [httpOptions]="httpOptions" [existingOrgConfig]="paymentConfigInput.paymentConfig" (processorFeaturesEvent)="setProcessorFeatures($event)"
          [remoteDepotId]="(adminConfig$|async)?.RemoteDepotId" [terminalsChanged$]="terminalsChanged$"
          (savePaymentConfigEvent)="savePaymentConfig($event)"
          [processorsChanged$]="processorsChanged$">
        </por-depot-config-list>
        <por-store-config-edit [existingOrgConfig]="paymentConfigInput.paymentConfig" (exit)="onDepotEditCancel()" *ngIf="showDepotConfigForm" [config]="depotConfigData"
          [httpOptions]="httpOptions" [processorFeatures]="processorFeatures">
        </por-store-config-edit>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>
