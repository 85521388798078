/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface DepotConfig {
    /**
     * The UUID that the Payment App uses to refer to the Processor config that was created. This UUID can be used in other API calls.
     */
    DepotConfigId?: string;
    OrganizationId?: number;
    /**
     * The Depot Id that this configuration is associated with.
     */
    RemoteDepotId?: string;
    /**
     * The Depot Label that this configuration is associated with.
     */
    RemoteDepotLabel?: string;
    /**
     * User defined label to easily identify the Depot Config.
     */
    Label?: string;
    /**
     * The Processor Id that this configuration has set as default Credit Card.
     */
    CreditCard?: string;
    /**
     * The Processor Id that this configuration has set as default Credit Card that is customer facing.
     */
    CreditCardCustomerFacing?: string;
    /**
     * The Processor Id that this configuration has set as default ACH.
     */
    ACH?: string;
    /**
     * Whether this config is soft deleted or not
     */
    Hidden?: number;
    [k: string]: any;
}
