/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { AdminService } from '../../../services';
import { copyToClipBoard, PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { PaymentAppGetRegistrationStatus, PaymentConfig, Processor, ProcessorFeature, Terminal } from '@por/por-pay/shared';
import { ProcessorTypeEnumPretty } from '../../../models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProcessorWRegistrationStatus } from '../../../models/ProcessorWRegistrationStatus';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProcessorAddFormComponent } from '../../processor/add/processor-add-form/processor-add-form.component';
import { ProcessorFormConfig } from '../../../models/ProcessorFormConfig';
import { ProcessorEditFormComponent } from '../../processor-edit/processor-edit-form/processor-edit-form.component';

export interface ProcessorListInput {
    httpOptions: PaymentApiServiceOptions;
    paymentConfig: PaymentConfig;
    isGAdmin?: boolean;
}
@Component({
    selector: 'por-processor-admin',
    templateUrl: './processor-admin-list.component.html',
    styleUrls: ['./processor-admin-list.component.scss']
})
export class ProcessorAdminListComponent implements OnInit, OnDestroy {
    @Input() processorListInput: ProcessorListInput = {
        httpOptions: {},
        paymentConfig: {},
        isGAdmin: false
    };
    @Input() processorsChanged$ = new BehaviorSubject<boolean>(false);
    @Input() terminalsChanged$ = new BehaviorSubject<boolean>(false);

    invalidProcessorCount = 0;

    protected readonly processorTypeEnumPretty = ProcessorTypeEnumPretty;
    private subscription?: Subscription;

    isLoading$ = new BehaviorSubject<boolean>(true);
    processorDisplay$ = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);
    terminalsDisplay: Terminal[] = [];
    private processorFeatures: ProcessorFeature[] = [];
    copyToClipBoard = copyToClipBoard;

  constructor(private readonly translateService: TranslateService, private readonly snackBar: MatSnackBar, private readonly paymentAdminService: AdminService, public dialog: MatDialog) {}

    ngOnInit() {
      this.isLoading$.next(false);
      this.loadProcessors();
        this.processorsChanged$.subscribe(value => {
            if (value) {
                this.loadProcessors();
                this.processorsChanged$.next(false);
            }
        });

        this.terminalsChanged$.subscribe(value => {
            if (value) {
                this.fetchTerminals();
                this.terminalsChanged$.next(false);
            }
        });
        this.fetchProcessorFeatures();
        this.fetchTerminals();
    }
    ngOnDestroy() {
        // Clean up the subscription when the component is destroyed
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    loadProcessors() {
        this.isLoading$.next(true);
        this.fetchProcessors();
        this.fetchRegistrationStatus();
    }

    fetchProcessorFeatures() {
        this.paymentAdminService.fetchProcessorFeatures().then(features => {
            this.processorFeatures = features;
        });
    }

    fetchProcessors() {
        this.paymentAdminService.fetchProcessors(this.processorListInput.httpOptions).then((data: Processor[]) => {
            const processorsWithRegistrationStatus: ProcessorWRegistrationStatus[] = data.map((processor: Processor) => {
                return {
                    processor,
                    registrationStatus: {
                        ProcessorId: processor.ProcessorId,
                        Message: 'Loading...',
                        RegistrationStatusCode: 3
                    } as PaymentAppGetRegistrationStatus
                };
            });
            this.processorDisplay$.next(processorsWithRegistrationStatus);
        }).finally(() => this.isLoading$.next(false))
    }

    fetchRegistrationStatus() {
        // Unsubscribe from previous subscription if there is one
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        const processors = this.processorDisplay$.getValue().map((processor: ProcessorWRegistrationStatus) => processor.processor);
        const initialStatus: ProcessorWRegistrationStatus[] = processors.map((processor: Processor) => ({
            processor,
            registrationStatus: {
                ProcessorId: processor.ProcessorId,
                Message: 'Loading...',
                RegistrationStatusCode: 3
            }
        }));

        this.processorDisplay$.next(initialStatus);

        this.subscription = this.paymentAdminService.fetchRegistrationStatuses(this.processorListInput.httpOptions).subscribe({
            next: (status: PaymentAppGetRegistrationStatus) => {
                const currentDisplay = this.processorDisplay$.getValue();
                const processorIndex = currentDisplay.findIndex(p => p.processor.ProcessorId === status.ProcessorId);
                if (processorIndex > -1) {
                    currentDisplay[processorIndex].registrationStatus = status;
                    this.processorDisplay$.next([...currentDisplay]); // Spread to create a new array, triggering change detection
                }
            },
            error: error => {
                console.error('Error fetching registration statuses:', error);
            },
            complete: () => {
              this.invalidProcessorCount = this.processorDisplay$.getValue().filter((processor: ProcessorWRegistrationStatus) => processor.registrationStatus.RegistrationStatusCode !== 1).length
            }
        });
    }

    fetchTerminals() {
        this.paymentAdminService.fetchTerminals(this.processorListInput.httpOptions).then(terminals => (this.terminalsDisplay = terminals));
    }

    addProcessor() {
        const dialogConfig: MatDialogConfig = {
            data: {
                httpOptions: this.processorListInput.httpOptions,
                paymentConfig: this.processorListInput.paymentConfig
            },
            width: '1000px', // You can set custom size, etc.
            height: '750px'
        };
        const processorAddModal = this.dialog.open(ProcessorAddFormComponent, dialogConfig);
        processorAddModal.componentInstance.processorAdded.subscribe(value => {
            this.editProcessor({
                processor: value,
                registrationStatus: {
                    Message: 'Loading...'
                }
            });
            processorAddModal.close();
        });
    }

    async editProcessor(rowProcessor: ProcessorWRegistrationStatus) {
        const processorFeature = this.processorFeatures.find(feature => {
            return feature.processorType === rowProcessor.processor.ProcessorTypeEnum;
        });

        if (processorFeature === undefined) {
            throw new Error(this.translateService.instant('processorFeature is undefined'));
        }

        const processorEditConfig: ProcessorFormConfig = {
            processorWRegistrationStatus: rowProcessor,
            terminals: this.terminalsDisplay.filter(t => {
                return t.ProcessorId === rowProcessor.processor.ProcessorId;
            }),
            processorFeatures: processorFeature,
            httpOptions: this.processorListInput.httpOptions,
            isGAdmin: this.processorListInput.isGAdmin,
            paymentConfig: this.processorListInput.paymentConfig
        };

        const dialogConfig: MatDialogConfig = {
            data: { ...processorEditConfig },
            width: '1000px', // You can set custom size, etc.
            height: '750px'
        };
        const processorEditModal = this.dialog.open(ProcessorEditFormComponent, dialogConfig);
        processorEditModal.componentInstance.changedProcessorEvent.subscribe(async () => {
            this.processorsChanged$.next(true);
        });

        processorEditModal.componentInstance.changedTerminalEvent.subscribe(async () => {
            this.terminalsChanged$.next(true);
        });
    }

    public onClipboardCopy(successful: boolean): void {
        if (successful) {
            this.snackBar.open(this.translateService.instant('Processor Id was copied to clipboard'), undefined, {
                duration: 5000,
                panelClass: ['success-snackbar']
            });
        } else {
            this.snackBar.open(this.translateService.instant('Copy to clipboard was NOT successful'), undefined, {
                duration: 5000,
                panelClass: ['error-snackbar']
            });
        }
    }

    getProcessorType(processor: Processor): string {
        return processor.ProcessorTypeEnum ? this.processorTypeEnumPretty[processor.ProcessorTypeEnum] : 'Default Value';
    }
}
