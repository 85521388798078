<div class="por-pay-stripe-registration-component">
    <div class="por-pay-ui-loading" *ngIf="loading">
        <div class="por-pay-ui-loading-header">{{ 'Verifying Stripe Registration...' | translate }}</div>
        <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div class="por-pay-error" *ngIf="hasRegistrationError">
        {{ errorMessage | translate }}
    </div>
    <div class="por-pay-account-results" *ngIf="!loading && !hasRegistrationError">
        <div class="por-pay-external-action-necessary" *ngIf="externalActionNecessary && accountLink; else noActionNecessary">
            <h4 translate>More Information Required</h4>
            <p translate>You need to fill out more information on Stripe's website to finish configuring this merchant.</p>
            <p>
                <span translate>Please click the following link to finish your registration:</span>
                <a [href]="accountLink" translate>Finish Stripe Registration</a>
            </p>
            <div class="por-pay-button-container">
              <div class="por-pay-button-save-container">
                <button id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
              </div>
            </div>
        </div>
        <ng-template #noActionNecessary>
            <div class="por-pay-no-action-necessary">
                <h4 translate>Registration Complete</h4>
                <p translate>Your Stripe account is registered and connected to Point of Rental.</p>
                <p>
                    <span translate>If you updated any of your company information, please make sure your info is correct in Stripe at this link:</span>
                    <a href="https://dashboard.stripe.com/" target="_blank" translate>Stripe Dashboard</a>
                </p>
            </div>
        </ng-template>
    </div>
</div>
