import { CurrencyInfo, Currencies } from '../models';
import currency from 'currency.js';

export class CurrencyHandlerService {
    // Conversions for the APIs.
    static convertDatabaseToDisplay(amount: number, currencyCode: string, useSymbol = true): string {
        const currencyInfo = this.getCurrencyInfo(currencyCode);
        return currency(amount, {
            precision: currencyInfo.decimalDigits,
            fromCents: true,
            // there are instances where we might want to omit the currency symbol and let the UI
            // handle it for translation.
            symbol: useSymbol ? currencyInfo.symbol : ''
        }).format();
    }

    static convertEziDebitToDatabase(amount: number | string, currencyCode: string, fromCents = true): number {
        const currencyInfo = this.getCurrencyInfo(currencyCode);
        return currency(amount, { precision: currencyInfo.decimalDigits, fromCents }).intValue;
    }

    static convertDatabaseToPeachPayments(amount: number, currencyCode: string): string {
        const currencyInfo = this.getCurrencyInfo(currencyCode);
        return currency(amount, { precision: currencyInfo.decimalDigits, fromCents: true, pattern: '#' }).format();
    }

    static convertPeachPaymentsToDatabase(amount: string): number {
        return currency(amount).intValue;
    }

    // Cayan is a special case because it needs both numbers and strings depending on the use case so we just return the currency object and let cayan.ts
    static convertDatabaseToCayan(amount: number | string, params: object = { fromCents: true, pattern: '#' }): currency {
        return currency(amount, { precision: 2, ...params });
    }

    static convertApiStringToDatabase(amount: string | number) {
        return currency(amount).intValue;
    }

    private static getCurrencyInfo(currencyCode: string): CurrencyInfo {
        return Currencies[currencyCode];
    }
}
