/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    Currencies,
    PaymentMethodEnumNames,
    PaymentMethodEnum,
    Processor,
    ProcessorCayan,
    ProcessorEziDebit,
    ProcessorGPIMITC,
    ProcessorPeachPayments,
    ProcessorTypeEnum,
    Terminal,
    trimStringProperties
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { BehaviorSubject } from 'rxjs';
import { BottomSheetElement, PaymentAdminStripeRegistrationInput, ProcessorTypeEnumPretty } from '../../../models';
import { ProcessorFormConfig } from '../../../models/ProcessorFormConfig';
import { AdminService, BottomSheetService } from '../../../services';
import { TerminalEditConfig } from '../../terminals/terminal-edit/terminalEditConfig';
import { cloneDeep } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'por-processor-edit',
    templateUrl: './processor-edit-form.component.html',
    styleUrls: ['./processor-edit-form.component.scss']
})
export class ProcessorEditFormComponent implements OnInit {
    constructor(
        readonly paymentAdminService: AdminService,
        private readonly dialogRef: MatDialogRef<ProcessorEditFormComponent>,
        public translate: TranslateService,
        private readonly bottomSheetService: BottomSheetService,
        private readonly snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public processorEditInput: ProcessorFormConfig
    ) {
        dialogRef.disableClose = true;
    }

    @Output() readonly changedProcessorEvent = new EventEmitter<Processor>();
    @Output() readonly changedTerminalEvent = new EventEmitter<boolean>();

    selectedProcessorCurrencies: Array<{ code: string; name: string }> = [];
    selectedProcessorType?: ProcessorTypeEnum;
    selectedProcessor: Processor = {} as Processor;
    selectedProcessorPaymentMethods: Array<{ label: string; key: string }> = [];

    isEditingTerminal$ = new BehaviorSubject<boolean>(false);
    stripeRegistrationInput: PaymentAdminStripeRegistrationInput = {} as PaymentAdminStripeRegistrationInput;

    defaultTerminalsInput = {
        processor: {} as Processor,
        terminals: [] as Terminal[],
        httpOptions: {} as PaymentApiServiceOptions
    };

    terminalsInput$ = new BehaviorSubject<{
        processor: Processor;
        terminals: Terminal[];
        httpOptions: PaymentApiServiceOptions;
    }>(this.defaultTerminalsInput);

    terminalToEdit$ = new BehaviorSubject<TerminalEditConfig>({} as TerminalEditConfig);

    ngOnInit(): void {
        const cur = Currencies;
        const currencyArray = [];
        // eslint-disable-next-line guard-for-in
        for (const currency in cur) {
            currencyArray.push({
                code: cur[currency].code,
                name: cur[currency].name
            });
        }
        this.selectedProcessorCurrencies = currencyArray.filter(c => this.processorEditInput?.processorFeatures?.currency?.includes(c.code));
        const country = this.processorEditInput?.paymentConfig?.Country;
        if (!this.processorEditInput?.processorWRegistrationStatus?.processor) {
            throw new Error('ProcessorEditFormComponent: processorEditInput.processorWRegistrationStatus is undefined');
        }
        this.selectedProcessor = this.processorEditInput?.processorWRegistrationStatus.processor;
        this.selectedProcessorType = this.selectedProcessor.ProcessorTypeEnum as ProcessorTypeEnum;
        this.stripeRegistrationInput = {
            processorId: this.selectedProcessor.ProcessorId ?? '',
            successUrl: this.paymentAdminService.paymentAdminConfig$.getValue().SuccessUrl,
            failureUrl: this.paymentAdminService.paymentAdminConfig$.getValue().FailureUrl,
            httpOptions: this.processorEditInput?.httpOptions
        };

        this.processorEditInput?.processorFeatures?.supportedPaymentMethods?.forEach(spt => {
            if ((PaymentMethodEnum[spt] && this.selectedProcessor.DefaultCurrencyCode === 'USD' && country === 'US') || PaymentMethodEnum[spt] !== 'ACH') {
                this.selectedProcessorPaymentMethods.push({
                    label: PaymentMethodEnumNames[spt],
                    key: PaymentMethodEnum[spt]
                });
            }
        });

        this.terminalsInput$.next({
            processor: this.selectedProcessor,
            terminals: this.processorEditInput.terminals ?? [],
            httpOptions: this.processorEditInput.httpOptions
        });

        this.terminalToEdit$.next({
            processor: this.selectedProcessor,
            terminal: {},
            httpOptions: this.processorEditInput.httpOptions
        });
    }

    get processorId() {
      return this.processorEditInput?.processorWRegistrationStatus?.processor?.ProcessorId ?? '';
    }

    set processorId(value: string) {
      if (this.processorEditInput && this.processorEditInput.processorWRegistrationStatus && this.processorEditInput.processorWRegistrationStatus.processor) {
        this.processorEditInput.processorWRegistrationStatus.processor.ProcessorId = value;
      }
    }

    get cayanConfig(): ProcessorCayan {
        return this.selectedProcessor.Config as ProcessorCayan;
    }

    get peachConfig(): ProcessorPeachPayments {
        return this.selectedProcessor.Config as ProcessorPeachPayments;
    }

    get mITCConfig(): ProcessorGPIMITC {
        return this.selectedProcessor.Config as ProcessorGPIMITC;
    }

    get eziDebitConfig(): ProcessorEziDebit {
        return this.selectedProcessor.Config as ProcessorEziDebit;
    }

    async save() {
        trimStringProperties(this.selectedProcessor);

        await this.paymentAdminService.updateProcessor(this.selectedProcessor, this.processorEditInput?.httpOptions).then(async (updatedProcessor: Processor) => {
            this.changedProcessorEvent.emit(updatedProcessor);
            this.dialogRef.close();
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    getHeader() {
        // const rawLabel = this.selectedProcessor.Label;
        let label = this.selectedProcessor.Label ?? '';

        if (label !== '') {
            label += ' | ';
        }

        if (this.selectedProcessor.ProcessorTypeEnum) {
            label += ProcessorTypeEnumPretty[this.selectedProcessor.ProcessorTypeEnum];
        }

        return label;
    }

    async deleteProcessor() {
        let deleteProc = false;

        if (this.selectedProcessor.ProcessorId === undefined) {
            throw new Error(this.translate.instant('processor.ProcessorId is undefined'));
        }

        let message = 'Are you certain you want to delete this merchant?';

        if (await this.paymentAdminService.processorHasTransactions(this.selectedProcessor.ProcessorId, this.processorEditInput.httpOptions)) {
            message = 'Warning! This processor has transactions. Are you certain you want to delete this processor anyway?';
        }

        // if (await this.paymentAdminService.processorIsAssociatedWithDepots(this.selectedProcessor.ProcessorId, this.processorEditInput.httpOptions)) {
        //     message += ' You are deleting a payment processor that is associated with a depot. This will cause transactions to fail!';
        // }

        const buttons: BottomSheetElement[] = [
            {
                title: 'Yes'
            },
            {
                title: 'Cancel'
            }
        ];
        const bottomSheet = await this.bottomSheetService.open(message, buttons);
        deleteProc = bottomSheet?.title === 'Yes';

        if (deleteProc) {
            if (this.processorEditInput.terminals !== undefined) {
                this.processorEditInput.terminals.forEach(terminal => {
                    if (terminal.TerminalId === undefined) {
                        throw new Error(this.translate.instant('terminals.TerminalId is undefined'));
                    }

                    this.paymentAdminService.deleteTerminal(terminal.TerminalId, this.processorEditInput.httpOptions);
                });
            }
          const deleted = await this.paymentAdminService.deleteProcessor(this.selectedProcessor, this.processorEditInput.httpOptions);
          if (deleted) {

                const deletedProcessor: Processor = { ...cloneDeep(this.selectedProcessor), Hidden: 1 };

                this.changedProcessorEvent.emit(deletedProcessor);
                this.dialogRef.close();
            }
        }
    }

    supportsTerminals() {
        return this.processorEditInput?.processorFeatures?.terminalSupport;
    }

    getTerminalList() {
        return this.terminalsInput$.value;
    }

    setTerminalList(value: any) {
        this.terminalsInput$.next(value);
    }

    async editTerminal(terminalToEdit: Terminal) {
        const isEditingTerminal = this.isEditingTerminal$.value;

        if (isEditingTerminal) {
            await this.cancelTerminalEdit({ isDirty: isEditingTerminal });
            return;
        }

        this.isEditingTerminal$.next(true);
        this.terminalToEdit$.next({
            ...this.terminalToEdit$.value,
            terminal: terminalToEdit
        });
    }

    getTerminalToEditValue() {
        return this.terminalToEdit$.value;
    }

    setTerminalIsDirty(isTerminalDirty: boolean) {
        this.isEditingTerminal$.next(isTerminalDirty);
    }

    /**
     * a helper method that removes a terminal from the list of terminals
     * @param terminalId unique id of the terminal to be removed.
     */
    private removeTerminalFromList(terminalId?: string) {
        const terminals = this.terminalsInput$.value.terminals;

        const removeTerminalIndex = terminals.findIndex(t => t.TerminalId === (terminalId ?? ''));
        if (removeTerminalIndex >= 0) {
            terminals.splice(removeTerminalIndex, 1);
        }

        this.terminalsInput$.next({
            ...this.terminalsInput$.value,
            terminals
        });
    }

    async cancelTerminalEdit(cancelEvent: { isDirty: boolean; terminalId?: string }) {
        if (!cancelEvent.terminalId) {
            // occurs when cancelling on a new terminal that hasn't been saved yet.
            // terminalId is an empty string in this case.

            // remove the terminal from the list so it will be reflected in the ui.
            this.removeTerminalFromList(cancelEvent.terminalId);
        }
        if (cancelEvent.isDirty) {
            const buttons: BottomSheetElement[] = [
                {
                    title: 'Yes'
                },
                {
                    title: 'Cancel'
                }
            ];
            const result = await this.bottomSheetService.open('There are unsaved changes to the current Terminal, are you sure you want to cancel?', buttons);
            cancelEvent.isDirty = !(result?.title?.toLowerCase() === 'yes');
        }
        this.isEditingTerminal$.next(cancelEvent.isDirty);
    }

    deleteTerminal(terminalId: string) {
        // remove the terminal from the list so it will be reflected in the ui.
        this.removeTerminalFromList(terminalId);
        // exit out of edit mode.
        this.isEditingTerminal$.next(false);
        this.changedTerminalEvent.emit(true);
    }

    async savedTerminal(updatedTerminal: Terminal) {
        if (!updatedTerminal) { return; }

        let terminals = this.terminalsInput$.value.terminals;

        if (this.terminalsInput$.value?.httpOptions?.headers === undefined) {
            return;
        }

        const processorFilter = this.terminalsInput$.value.httpOptions.headers.set(
            'X-Filter',
            JSON.stringify({
                type: 'AND',
                value: [{ field: 'ProcessorId', type: '=', value: updatedTerminal.ProcessorId ?? '' }]
            })
        );

        terminals = await this.paymentAdminService.fetchTerminals({
            ...this.terminalsInput$.value.httpOptions,
            headers: processorFilter
        });

        this.terminalsInput$.next({
            ...this.terminalsInput$.value,
            terminals: terminals
        });
        this.changedTerminalEvent.emit(true);
    }

    newCurrencySelected() {
        const achNotInSelectedMethods = !this.selectedProcessorPaymentMethods.some(method => method.key === 'ACH');
        const achNotInSupportedMethods = !this.processorEditInput?.processorFeatures?.supportedPaymentMethods?.some(value => value === 4);
        const country = this.processorEditInput?.paymentConfig?.Country;

        this.processorEditInput?.processorFeatures?.supportedPaymentMethods?.forEach(spt => {
            if (PaymentMethodEnum[spt] === 'ACH' && this.selectedProcessor.DefaultCurrencyCode !== 'USD') {
                // If ACH is present, remove it
                const index = this.selectedProcessorPaymentMethods.findIndex(method => method.key === 'ACH');
                if (index !== -1) {
                    this.selectedProcessorPaymentMethods.splice(index, 1);
                }
            }
        });

        // If USD Is The DefaultCurrencyCode and ACH is NOT In the Array AND ACH is a supported method: add it back in
        if (this.selectedProcessor.DefaultCurrencyCode === 'USD' && country === 'US' && achNotInSelectedMethods && !achNotInSupportedMethods) {
            this.selectedProcessorPaymentMethods.push({
                label: 'ACH',
                key: 'ACH'
            });
        } else if (this.selectedProcessor['ACH'] === 1 && this.selectedProcessor.DefaultCurrencyCode !== 'USD') {
            // Uncheck The ACH box if we've just removed it
            this.selectedProcessor['ACH'] = 0;
        }
    }

  public onClipboardCopy(successful: boolean): void {
    if (successful) {
      this.snackBar.open(this.translate.instant('Merchant Id was copied to clipboard'), undefined, {
        duration: 5000,
        panelClass: ['success-snackbar']
      });
    } else {
      this.snackBar.open(this.translate.instant('Copy to clipboard was NOT successful'), undefined, {
        duration: 5000,
        panelClass: ['error-snackbar']
      });
    }
  }

  protected readonly ProcessorTypeEnum = ProcessorTypeEnum;
}
