<div class="question-container">
  <p>{{ question }}</p>
  <div class="buttons-container">
    <button
      *ngFor="let button of buttons"
      (click)="button.callback()"
      matStepperNext
      [ngClass]="button.label === 'Yes' ? 'por-pay-process-button' : 'por-pay-cancel-button'"
    >
      {{ button.label }}
    </button>
  </div>
</div>
