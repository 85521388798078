import {Component, Input} from '@angular/core';
import {ButtonOption} from './button-options.interface';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'por-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    providers: [
      { provide: MatStepper, useExisting: QuestionComponent }
    ]
})
export class QuestionComponent {
  @Input() question = '';
  @Input() buttons: ButtonOption[] = [];
}
