import * as CurrencyJSON from './currencies.json';

export interface CurrencyInfo {
    symbol: string;
    name: string;
    symbolNative: string;
    decimalDigits: number;
    rounding: number;
    code: string;
    namePlural: string;
}

export const Currencies: {
    [k: string]: CurrencyInfo;
} = CurrencyJSON;
