/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  Depot,
  PaymentAppGetRegistrationStatus, PaymentMethodEnum,
  Processor,
  ProcessorFeature, ProcessorTypeEnum, RegistrationStatusCode,
  Terminal
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaymentConfigExtended, ProcessorTypeEnumPretty, ProcessorWRegistrationStatus } from '../../../models';
import { ProcessorFormConfig } from '../../../models/ProcessorFormConfig';
import { AdminService } from '../../../services';
import { ProcessorListInput } from '../../processor-admin/processor-admin-list/processor-admin-list.component';
import { ProcessorEditFormComponent } from '../../processor-edit/processor-edit-form/processor-edit-form.component';

/**
 * Drop-in Configuration Wizard for registering and updating Payment App data in a calling app
 */
@Component({
  selector: 'por-store-config-edit',
  templateUrl: './store-config-edit.component.html',
  styleUrls: ['./store-config-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreConfigEditComponent implements OnInit {
  @Input() config: Depot = {} as Depot;
  @Input() httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
  @Input() processorsChanged$ = new BehaviorSubject<Processor>({});
  @Input() terminalsChanged$ = new BehaviorSubject<boolean>(false);
  @Input() processorListInput: ProcessorListInput = {
    httpOptions: {},
    paymentConfig: {},
    isGAdmin: false
  };
  @Input() existingOrgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;

  @Output() readonly configurationChanged = new EventEmitter<Depot>();
  @Output() readonly configurationDeleted = new EventEmitter<Depot>();
  @Output() exit = new EventEmitter<void>();

  isLoading$ = new BehaviorSubject<boolean>(true);
  processorDisplay$ = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);
  protected readonly processorTypeEnumPretty = ProcessorTypeEnumPretty;
  private subscription?: Subscription;
  terminalsDisplay: Terminal[] = [];
  private processorFeatures: ProcessorFeature[] = [];

  processors: ProcessorWRegistrationStatus[] = [];
  invalidProcessorCount = 0;
  paymentMethods!: FormArray;
  dropDownMethods!: FormGroup;
  protected readonly PaymentMethodEnum = PaymentMethodEnum;
  protected readonly ProcessorTypeEnum = ProcessorTypeEnum;
  achProcessors: ProcessorWRegistrationStatus[] = [];
  onlineCCProcessors: ProcessorWRegistrationStatus[] = [];
  inStoreCCProcessors: ProcessorWRegistrationStatus[] = [];

  achProcessors$ = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);
  onlineCCProcessors$ = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);
  inStoreCCProcessors$  = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);

  constructor(
    readonly paymentAdminService: AdminService,
    public translate: TranslateService, private readonly snackBar: MatSnackBar,
    public dialog: MatDialog, private readonly cd: ChangeDetectorRef, private readonly fb: FormBuilder) {
    this.paymentMethods = this.fb.array([]);
  }

  async ngOnInit(): Promise<void> {
    this.terminalsChanged$.subscribe(value => {
      if (value) {
        this.fetchTerminals();
        this.terminalsChanged$.next(false);
      }
    });
    this.processorsChanged$.subscribe((updatedProcessor: Processor) => {

      if (Object.keys(updatedProcessor).length > 0) {

        const assignedProcessorsDisplay = this.processorDisplay$.getValue();
        const unassignedProcessorIndex = assignedProcessorsDisplay.findIndex(p => p.processor.ProcessorId === updatedProcessor.ProcessorId);

        if (updatedProcessor.Hidden === 1 && unassignedProcessorIndex > -1) {
          assignedProcessorsDisplay.splice(unassignedProcessorIndex, 1);
          this.processorDisplay$.next([...assignedProcessorsDisplay]);
        } else {
          this.paymentAdminService.fetchRegistrationStatusForProcessor(updatedProcessor, this.httpOptions)
            .then((status: PaymentAppGetRegistrationStatus) => {
              status = { ...status, ProcessorId: updatedProcessor.ProcessorId };
              if (unassignedProcessorIndex > -1) {
                assignedProcessorsDisplay[unassignedProcessorIndex].registrationStatus = { ...status };
                this.processorDisplay$.next([...assignedProcessorsDisplay]); // Spread to create a new array, triggering change detection
              }
            })
        }
      }
    });
    await this.loadProcessors();
    this.fetchProcessorFeatures();
    this.fetchTerminals();
    await this.updateProcessorsRegistrationStatus();
    this.cd.detectChanges();
  }

  getProcessorType(processor: Processor): string {
    return processor.ProcessorTypeEnum ? this.processorTypeEnumPretty[processor.ProcessorTypeEnum] : 'Default Value';
  }

  async loadProcessors() {
    this.isLoading$.next(true); // Set loading state to true
    this.fetchProcessorFeatures(); // Ensure features are loaded and filtered

    this.paymentAdminService.fetchProcessors(this.httpOptions).then((data: Processor[]) => {
      const processorsWithRegistrationStatus: ProcessorWRegistrationStatus[] = data
        .filter((processor: Processor) => processor.RemoteDepotId === this.config.Id)
        .map((processor: Processor) => {
          // Find the corresponding processor features
          const feature = this.processorFeatures.find(f => f?.currency?.includes(processor['DefaultCurrencyCode'] ?? '') && f?.processorType === processor['ProcessorTypeEnum']);

          if (!feature) {
            return null; // Skip if no features are found
          }

          // if a processor does not have a label give it one here so it shows up in the dropdown properly.
          if(processor.Label === '') {
            processor.Label = this.getProcessorType(processor)
          }

          return {
            processor,
            registrationStatus: {
              ProcessorId: processor.ProcessorId,
              Message: 'Loading...',
              RegistrationStatusCode: RegistrationStatusCode.Unknown
            } as PaymentAppGetRegistrationStatus,
            ach: !!feature?.supportedPaymentMethods?.includes(PaymentMethodEnum.ACH),
            creditCard: feature?.supportedPaymentMethods?.includes(PaymentMethodEnum.CreditCard) ? 1 as 0 | 1 : 0 as 0 | 1,
            customerFacing: !!processor?.IsCustomerFacing,
          };
        })
        .filter(p => p !== null) as ProcessorWRegistrationStatus[]; // Remove null entries and ensure the type

      this.processorDisplay$.next(processorsWithRegistrationStatus);
      this.processors = processorsWithRegistrationStatus;
      const achProcessor = processorsWithRegistrationStatus.find(p => p.processor?.PaymentMethods?.includes(PaymentMethodEnum.ACH));
      const customerFacingProcessor = processorsWithRegistrationStatus.find(p => p.processor.IsCustomerFacing === 1);
      const creditCardProcessor = processorsWithRegistrationStatus.find(p => p.processor?.PaymentMethods?.includes(PaymentMethodEnum.CreditCard));
      this.achProcessors = [
        {processor: { ProcessorId: '', Label: 'None' }, registrationStatus: {Message: ''}},
        ...processorsWithRegistrationStatus
          .filter(p => (p.processor?.ProcessorTypeEnum === ProcessorTypeEnum.Stripe) && (p.processor?.DefaultCurrencyCode === 'USD'))
      ];
      this.onlineCCProcessors = [
        {processor: { ProcessorId: '', Label: 'None' }, registrationStatus: {Message: ''}},
        ...processorsWithRegistrationStatus
      ];
      this.inStoreCCProcessors = [
        ...processorsWithRegistrationStatus
      ];
      this.achProcessors$.next(this.achProcessors);
      this.inStoreCCProcessors$.next(this.inStoreCCProcessors);
      this.onlineCCProcessors$.next(this.onlineCCProcessors);
      this.dropDownMethods = new FormGroup({
        ach: new FormControl(achProcessor?.processor ?? ''),
        customerFacing: new FormControl(customerFacingProcessor?.processor ?? ''),
        creditCard: new FormControl(creditCardProcessor?.processor ?? '', Validators.required)
      });
    }).finally(() => this.isLoading$.next(false));
  }

  async updateProcessorsRegistrationStatus() {
    // Unsubscribe from previous subscription if there is one
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.paymentAdminService.fetchRegistrationStatuses(this.httpOptions).subscribe({
      next: (status: PaymentAppGetRegistrationStatus) => {
        this.updateProcessorDisplay(this.processorDisplay$, status);

        this.updateProcessorCategoryDisplay(this.inStoreCCProcessors$, status);
        this.updateProcessorCategoryDisplay(this.onlineCCProcessors$, status);
        this.updateProcessorCategoryDisplay(this.achProcessors$, status);
      },
      error: error => {
        console.error('Error fetching registration statuses:', error);
      },
      complete: () => {
        this.invalidProcessorCount =
          this.processorDisplay$.getValue().filter((processor: ProcessorWRegistrationStatus) => processor.registrationStatus.RegistrationStatusCode !== RegistrationStatusCode.Yay).length
      }
    });
  }

  private updateProcessorDisplay(display$: BehaviorSubject<ProcessorWRegistrationStatus[]>, status: PaymentAppGetRegistrationStatus) {
    const display = display$.getValue();
    const processorIndex = display.findIndex(p => p.processor.ProcessorId === status.ProcessorId);

    if (processorIndex > -1) {
      display[processorIndex].registrationStatus = status;
      display$.next([...display]); // Spread to create a new array, triggering change detection
    }
  }

  private updateProcessorCategoryDisplay(display$: BehaviorSubject<ProcessorWRegistrationStatus[]>, status: PaymentAppGetRegistrationStatus) {
    const display = display$.getValue();
    const processorIndex = display.findIndex(p => p.processor.ProcessorId === status.ProcessorId);

    if (processorIndex > -1 && status.RegistrationStatusCode === RegistrationStatusCode.Nay) {
      display.splice(processorIndex, 1);
      display$.next([...display]);
    }
  }

  fetchProcessorFeatures() {
    this.paymentAdminService.fetchProcessorFeatures().then(features => {
      this.processorFeatures = features.map(feature => {
        // Filter supported payment methods based on availability in countries
        const supportedPaymentMethodsInCountries = feature?.supportedPaymentMethods;

        return {
          ...feature,
          supportedPaymentMethods: supportedPaymentMethodsInCountries
        };
      });
    });
  }

  async editProcessor(rowProcessor: ProcessorWRegistrationStatus) {
    const processorFeature = this.processorFeatures.find(feature => {
      return feature.processorType === rowProcessor.processor.ProcessorTypeEnum;
    });

    if (processorFeature === undefined) {
      throw new Error(this.translate.instant('processorFeature is undefined'));
    }

    const processorEditConfig: ProcessorFormConfig = {
      processorWRegistrationStatus: rowProcessor,
      terminals: this.terminalsDisplay.filter(t => {
        return t.ProcessorId === rowProcessor.processor.ProcessorId;
      }),
      processorFeatures: processorFeature,
      httpOptions: this.httpOptions,
      isGAdmin: this.existingOrgConfig.isGAdmin,
      paymentConfig: this.processorListInput.paymentConfig
    };

    const dialogConfig: MatDialogConfig = {
      data: { ...processorEditConfig },
      width: '1000px', // You can set custom size, etc.
      height: '750px'
    };
    const processorEditModal = this.dialog.open(ProcessorEditFormComponent, dialogConfig);
    processorEditModal.componentInstance.changedProcessorEvent.subscribe(async (updatedProcessor: Processor) => {
      this.processorsChanged$.next(updatedProcessor);
    });

    processorEditModal.componentInstance.changedTerminalEvent.subscribe(async () => {
      this.terminalsChanged$.next(true);
    });
  }

  async save() {

    this.isLoading$.next(true);

    // getting all the processors
    const processors = this.processors.map(p => p.processor);

    const currentAchProcessor: Processor = processors.find(p => p.PaymentMethods?.includes(PaymentMethodEnum.ACH)) as Processor;
    const currentCustomerFacingProcessor: Processor = processors.find(p => p.IsCustomerFacing === 1) as Processor;
    const currentCreditCardProcessor: Processor = processors.find(p => p.PaymentMethods?.includes(PaymentMethodEnum.CreditCard)) as Processor;

    const ach: Processor = this.dropDownMethods.value.ach;
    const customerFacing: Processor = this.dropDownMethods.value.customerFacing;
    const creditCard: Processor = this.dropDownMethods.value.creditCard;

    if (ach) {
      const save = ach.ProcessorId !== currentAchProcessor?.ProcessorId;
      if (save) {
        if (ach?.ProcessorId !== '') {
          ach?.PaymentMethods === null ? (ach.PaymentMethods = [PaymentMethodEnum.ACH]) : ach?.PaymentMethods?.push(PaymentMethodEnum.ACH);
          await this.paymentAdminService.updateProcessor(ach, this.httpOptions);
        }
        // if there is a current ach processor, remove ach from its payment methods and save
        if (currentAchProcessor) {
          currentAchProcessor.PaymentMethods = currentAchProcessor?.PaymentMethods?.filter(method => method !== PaymentMethodEnum.ACH);
          await this.paymentAdminService.updateProcessor(currentAchProcessor, this.httpOptions);
        }
      }
    }
    if (customerFacing) {
      const save = customerFacing.ProcessorId !== currentCustomerFacingProcessor?.ProcessorId;
      if (save) {
        if (customerFacing?.ProcessorId !== '') {
          customerFacing.IsCustomerFacing = 1;
          await this.paymentAdminService.updateProcessor(customerFacing, this.httpOptions);
        }
        // if there is a current customer facing processor, set it to not customer facing and save
        if (currentCustomerFacingProcessor) {
          currentCustomerFacingProcessor.IsCustomerFacing = 0;
          await this.paymentAdminService.updateProcessor(currentCustomerFacingProcessor, this.httpOptions);
        }
      }
    }
    if (creditCard) {
      const save = currentCreditCardProcessor?.ProcessorId !== creditCard.ProcessorId;
      if (save) {
        creditCard?.PaymentMethods === null ? (creditCard.PaymentMethods = [PaymentMethodEnum.CreditCard]) : creditCard?.PaymentMethods?.push(PaymentMethodEnum.CreditCard);
        await this.paymentAdminService.updateProcessor(creditCard, this.httpOptions);
        // if there is a current credit card processor, remove credit card from its payment methods and save
        if (currentCreditCardProcessor) {
          currentCreditCardProcessor.PaymentMethods = currentCreditCardProcessor?.PaymentMethods?.filter(method => method !== PaymentMethodEnum.CreditCard);
          await this.paymentAdminService.updateProcessor(currentCreditCardProcessor, this.httpOptions);
        }
      }
    }

    this.exit.emit()
    this.isLoading$.next(false);
  }


  cancel() {
    this.exit.emit()
  }


  fetchTerminals() {
    this.paymentAdminService.fetchTerminals(this.httpOptions).then(terminals => (this.terminalsDisplay = terminals));
  }

  protected readonly RegistrationStatusCode = RegistrationStatusCode;

  getTooltipMessage(statusCode: RegistrationStatusCode | undefined): string {
    switch (statusCode) {
      case this.RegistrationStatusCode.Nay:
        return 'Credentials are invalid';
      case this.RegistrationStatusCode.Yay:
        return 'Credentials are valid';
      default:
        return 'Validating credentials...';
    }
  }
}
