<mat-stepper [linear]="true" #stepper>
  <mat-step label="Choose Store">
    <div class="por-pay-ui-loading" *ngIf="(currentStep$ | async) === wizardStepsEnum.Loading">
      <div class="por-pay-ui-loading-header">{{ 'Loading, please wait...' | translate }}</div>
      <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div class="error-message-to-show" *ngIf="(currentStep$ | async) === wizardStepsEnum.Error">
      <p>{{this.errorMessageToShow | translate}}</p>
      <button (click)="onSummaryCancel()" id="por-pay-cancel-processor-edit" class="por-pay-cancel-button">{{'Back' | translate}}</button>
    </div>
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.StoreQuestion">
      <por-choose-a-store
        [selectedDepotId]="selectedDepot"
        [depots]="orgDepots"
        (editStep)="onChooseStoreBack($event)"
        (selectedDepot)="onChooseStoreComplete($event)"
        (selectedDepotName)="onSelectDepotName($event)"
        (wizardCanceled)="onChooseStoreCancel()"
        [isTerminalWizard]="isTerminalSetup">
      </por-choose-a-store>
    </div>
  </mat-step>
  <mat-step label="Choose Merchant">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.ChooseProcessor">
      <por-processor-list
        [httpOptions]="httpOptions"
        [processors]="processors"
        [selectedSavedProcessor]="selectedProcessor"
        (processorEditEvent)="editProcessor($event)"
        (editStep)="onProcessorBack($event)"
        [depot]=selectedDepot>
      </por-processor-list>
    </div>
  </mat-step>
  <mat-step label="Add Terminal">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.Terminal">
      <por-terminal-edit
        [depotName]="selectedDepotName"
        [depot]=selectedDepot
        [isWizardSetup]="isSetupWizard"
        [isTerminalWizard]="isTerminalSetup"
        [terminalConfig]="(terminalToEdit$ | async) ?? defaultTerminalInput" (editStep)="onTerminalBack($event)"
        (terminalFinishedEditingEvent)="onTerminalComplete($event)"
        (terminalConfigChangeEvent)="setTerminalIsDirty($event)"
        (terminalSavedEvent)="savedTerminal($event)"
        [isGAdmin]="existingOrgConfig.isGAdmin"
      ></por-terminal-edit>
    </div>
  </mat-step>
  <mat-step label="Summary">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.Summary">
      <por-summary
        [isTerminalWizard]="isTerminalSetup"
        [processor]=selectedProcessor
        [selectedProcessorPaymentMethods]=selectedPaymentMethods
        [terminal]=selectedTerminal
        [orgConfig]=existingOrgConfig
        [depot]=selectedDepot
        [depotName]="selectedDepotName"
        [httpOptions]="httpOptions"
        (editStep)="onSummaryEdit($event)"
        (stripeRegistrationEvent)="getStripeRegistrationInput($event)"
        (save)="onSummaryComplete()"
        (cancel)="onSummaryCancel()">
      </por-summary>
    </div>
  </mat-step>
</mat-stepper>
