<div class="por-pay-select-processor">
  <h2 class="por-pay-title por-pay-ui-form-fields-header">{{ getHeader() | translate }}</h2>
  <div class="processor-select-div">
  <form id="por-pay-select-processor-form">
    <mat-form-field appearance="outline" class="por-pay-select por-pay-body-input">
      <mat-label>{{ 'Select Merchant' | translate }}</mat-label>
      <mat-select [(ngModel)]="selectedProcessorType" name="selectedProcessor">
        <mat-option *ngFor="let processorFeature of prodReadyProcessorTypes$ | async" [value]="processorFeature">
          {{ processorFeature.processorName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  </div>
  <div class="por-pay-button-container">
    <div class="por-pay-button-save-container">
      <button matStepperPrevious class="por-pay-cancel-button"(click)="cancel()">
        {{ 'Back' | translate }}
      </button>
      <button [disabled]="selectedProcessorType === null" matStepperNext class="por-pay-process-button" (click)="createProcessor()">
        {{ 'Next' | translate }}
      </button>
    </div>
  </div>
</div>
