import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessorTypeEnum } from '@por/por-pay/shared';
import { AdminService } from '../../services';
import { SafeUrl } from '@angular/platform-browser';
import { PaymentAdminStripeRegistrationInput } from '../../models';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'por-stripe-registration',
    templateUrl: './stripe-registration.component.html',
    styleUrls: ['./stripe-registration.component.scss']
})
export class StripeRegistrationComponent implements OnInit {
    @Input() stripeRegistrationInput!: PaymentAdminStripeRegistrationInput;

    @Output() cancel = new EventEmitter<void>();

    hasRegistrationError = false;
    errorMessage!: string;
    accountLink!: SafeUrl;
    externalActionNecessary = false;
    newAccount!: boolean;
    loading = false;

    constructor(private readonly paymentAdminService: AdminService, readonly translate: TranslateService) {}

    async ngOnInit() {
        await this.initializeStripe();
    }

    async initializeStripe() {
        const httpOptions: PaymentApiServiceOptions = {
            params: {
                processorId: encodeURIComponent(this.stripeRegistrationInput.processorId),
                successUrl: encodeURIComponent(this.stripeRegistrationInput.successUrl),
                failureUrl: encodeURIComponent(this.stripeRegistrationInput.failureUrl)
            },
            headers: this.stripeRegistrationInput.httpOptions['headers']
        };

        this.hasRegistrationError = false;
        this.loading = true;
        this.paymentAdminService
            .getRegistrationForm(ProcessorTypeEnum.Stripe, httpOptions)
            .then(result => {
                this.externalActionNecessary = !!result?.RegistrationData?.['externalActionNecessary'];
                this.newAccount = !!result?.RegistrationData?.['newAccount'];
                this.accountLink = result?.RegistrationData?.['url'];
            })
            .catch(error => {
                this.hasRegistrationError = true;
                this.errorMessage = 'An error has occurred getting your Stripe registration data. Please contact Support. Blah BLah';
            })
            .finally(() => {
                this.loading = false;
            });
    }

  onCancel() {
    this.paymentAdminService.handleSuccess(this.translate.instant('Cancelled.'));
    this.cancel.emit();
  }
}
