export enum ProcessorTypeEnumPretty {
  Stripe = 1,
  Cayan = 2,
  PeachPayments = 3,
  // Elavon = 4,
  // Falabella = 5,
  // Moneris = 6,
  // Transbank = 7,
  EziDebit = 8,
  GPIMITC = 9,
}
