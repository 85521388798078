<div class="por-pay-processor-edit-modal modal-content" id="apx">
    <div class="por-pay-modal-messages por-pay-modal-center">
        <div id="por-pay-modal-success-message" class="por-pay-message success">
            <div class="por-pay-message-header">
                <span class="por-pay-message-title" translate="Success"></span>
                <span (click)="paymentAdminService.hideModalSuccessMessage()" class="por-pay-message-close">&times;</span>
            </div>
            <span class="por-pay-message-text"></span>
        </div>
        <div id="por-pay-modal-failure-message" class="por-pay-message failure">
            <div class="por-pay-message-header">
                <span class="por-pay-message-title" translate="Error"></span>
                <span (click)="paymentAdminService.hideModalFailureMessage()" class="por-pay-message-close">&times;</span>
            </div>
            <span class="por-pay-message-text"></span>
        </div>
    </div>
  <div class="por-pay-header-container">
    <h4 class="por-pay-title por-pay-ui-form-fields-header">
      {{ getHeader() }}
    </h4>
    <div class="id-container por-pay-title por-pay-ui-form-fields-header" *ngIf="processorEditInput && processorEditInput.processorWRegistrationStatus">
      <span>{{'Merchant ID: ' + processorEditInput.processorWRegistrationStatus.processor.ProcessorId}}</span>
      <button [cdkCopyToClipboard]="processorId" class="copyButton" (cdkCopyToClipboardCopied)="onClipboardCopy($event)">
        <svg class="copy-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7
          0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64
          128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0
          35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z" />
        </svg>
      </button>
    </div>
  </div>
  <apx-tab-group class="por-pay-tab-group por-pay-processor-edit">
    <apx-tab title="{{'Configuration' | translate}}">
      <div class="por-pay-processor-config-input-fields-container">
        <div class="por-pay-processor-config-input-fields" *ngIf="processorEditInput && processorEditInput.processorWRegistrationStatus">
          <div class="input-container">
            <apx-form-field class="por-pay-header-input" label="{{ 'Name' | translate }}">
              <input apxInput [(ngModel)]="processorEditInput.processorWRegistrationStatus.processor.Label" type="text" />
            </apx-form-field>
            <mat-form-field class="por-pay-header-input por-pay-select" appearance="outline">
              <mat-label class="input-title" translate="Currency"></mat-label>
              <mat-select [(ngModel)]="processorEditInput.processorWRegistrationStatus.processor.DefaultCurrencyCode" (selectionChange)="newCurrencySelected()">
                <mat-option *ngFor="let currency of selectedProcessorCurrencies" [value]="currency.code">
                  {{ currency.code }} - {{ currency.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="por-pay-processor-config-input-fields por-pay-stripe-registration-component-container" *ngIf="selectedProcessorType === ProcessorTypeEnum.Stripe">
          <por-stripe-registration class="por-pay-center" [stripeRegistrationInput]="stripeRegistrationInput"></por-stripe-registration>
        </div>
        <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.Cayan">
          <!--Cayan-->
          <apx-form-field class="por-pay-body-input" label="{{ 'Merchant Name' | translate }}">
            <input apxInput [(ngModel)]="cayanConfig.MerchantName" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Site ID' | translate }}">
            <input apxInput [disabled]="!processorEditInput.isGAdmin" [(ngModel)]="cayanConfig.MerchantSiteId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Key' | translate }}">
            <input apxInput [disabled]="!processorEditInput.isGAdmin" [(ngModel)]="cayanConfig.MerchantKey" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Web API Key' | translate }}">
            <input apxInput [(ngModel)]="cayanConfig.ApiKey" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.PeachPayments">
          <!--Peach-->
          <apx-form-field class="por-pay-body-input" label="{{ 'Authorization Header' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.AuthorizationHeader" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ '3D Secure Entity ID' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.ThreeDSecureEntityId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Recurring Entity ID' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.RecurringEntityId" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.EziDebit">
          <!--EziDebit-->
          <apx-form-field class="por-pay-body-input" label="{{ 'Digital Key' | translate }}">
            <input apxInput [(ngModel)]="eziDebitConfig.DigitalKey" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Public Key' | translate }}">
            <input apxInput [(ngModel)]="eziDebitConfig.PublicApiKey" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.GPIMITC">
          <!--GPI PayFields/MITC-->
          <apx-form-field class="por-pay-body-input" label="{{ 'X-Web ID' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'Terminal ID' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebTerminalId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-body-input" label="{{ 'AuthKey' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebKey" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-button-container por-pay-left">
          <div class="por-pay-button-delete-container">
            <button apxButton id="por-pay-delete-processor-edit" class="por-pay-process-button" variant="primary" (click)="deleteProcessor()">
              {{ 'Delete Merchant' | translate }}
            </button>
          </div>
          <div class="por-pay-button-save-container por-pay-right">
            <button apxButton id="por-pay-save-processor-edit" class="por-pay-process-button" color="primary" (click)="save()" translate="Save"></button>
            <button apxButton id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" variant="secondary" (click)="cancel()">
              {{ 'Cancel' | translate }}
            </button>
          </div>
        </div>
      </div>
    </apx-tab>
    <apx-tab *ngIf="supportsTerminals()" title="{{'Terminals' | translate}}">
      <div class="por-pay-terminal-list-container" [ngClass]="{ editing: (isEditingTerminal$ | async) }">
        <por-terminal-list
          class="por-pay-terminal-list"
          [disabledList]="(isEditingTerminal$ | async) ?? false"
          [class.full-width]="(isEditingTerminal$ | async) === false"
          [terminalsInput]="(terminalsInput$ | async) ?? defaultTerminalsInput"
          (terminalsInputChanged)="setTerminalList($event)"
          (terminalEditEvent)="editTerminal($event)"
          (terminalCancelEvent)="cancel()"></por-terminal-list>
        <por-terminal-edit
          *ngIf="isEditingTerminal$ | async"
          class="por-pay-terminal-edit"
          [isGAdmin]="processorEditInput.isGAdmin"
          [terminalConfig]="getTerminalToEditValue()"
          (terminalConfigChangeEvent)="setTerminalIsDirty($event)"
          (terminalCancelEditEvent)="cancelTerminalEdit($event)"
          (terminalDeletedEvent)="deleteTerminal($event)"
          (terminalSavedEvent)="savedTerminal($event)"></por-terminal-edit>
      </div>
    </apx-tab>
  </apx-tab-group>
</div>
