/**
 * An enumeration of supported payment types.
 * 1=CreditCard, 4=ACH
 */
export enum PaymentMethodEnum {
  CreditCard = 1,
  ACH = 4
}

export const PaymentMethodEnumNames = {
  [PaymentMethodEnum.CreditCard]: 'Credit Card',
  [PaymentMethodEnum.ACH]: 'ACH'
};

export const PaymentMethodComponentNames = {
  [PaymentMethodEnum.CreditCard]: 'Credit Card',
  [PaymentMethodEnum.ACH]: 'ACH'
};

export enum SelectedProcessorPaymentMethods {
  CreditCard = 1,
  CreditCardCustomerFacing = 2,
  ACH = 4
}

export const SelectedProcessorPaymentMethodNames = {
  [SelectedProcessorPaymentMethods.CreditCard]: 'In Store Credit Card Merchant',
  [SelectedProcessorPaymentMethods.ACH]: 'Online ACH Merchant',
  [SelectedProcessorPaymentMethods.CreditCardCustomerFacing]: 'Online Credit Card Merchant'
};
