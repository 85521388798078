<div class="por-pay-bottom-sheet">
    <div class="por-pay-bottom-sheet-message">{{ data.message | translate }}</div>
    <div class="por-pay-bottom-sheet-buttons">
      <ng-container *ngFor="let element of data.elements">
        <button [ngClass]="(element.title === 'Cancel') ? 'por-pay-cancel-button' : 'por-pay-process-button'"
                [attr.matStepperNext]="element.title === 'Yes' ? '' : null"
                class="por-pay-bottom-sheet-change" type="button" (click)="elementClicked(element)">
            {{ element.title | translate }}
        </button>
      </ng-container>
    </div>
</div>
