/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Depot, PaymentConfig, Processor, ProcessorFeature } from '@por/por-pay/shared';
import { HttpHeaders } from '@angular/common/http';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { BehaviorSubject } from 'rxjs';
import { PaymentAdminStripeRegistrationInput, PaymentAppAdminInput, PaymentConfigExtended } from '../../models';
import { AdminService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { ProcessorListInput } from '../processor-admin/processor-admin-list/processor-admin-list.component';
import { MatTabGroup } from "@angular/material/tabs";
import { CdkStepper } from '@angular/cdk/stepper';

/**
 * Drop-in Configuration Wizard for registering and updating Payment App data in a calling app
 */
@Component({
    selector: 'por-payment-app-config-wizard',
    templateUrl: './payment-admin.component.html',
    styleUrls: ['./payment-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{provide: CdkStepper, useExisting: PaymentAdminComponent}]
})
export class PaymentAdminComponent implements OnInit, OnChanges {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    constructor(readonly paymentAdminService: AdminService, public translate: TranslateService, public dialog: MatDialog) { }

    // Inputs for Stripe Component
    @Input() stripeRegistrationInput!: PaymentAdminStripeRegistrationInput;

    // This comes in as a string because it's an attribute on a custom element.
    @Input() configInput?: string;
    @Output() readonly paymentAdminElementLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() readonly isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
    // this observable will be used to determine we are able to make api calls using
    // the api key provided by the calling app.
    apiKeyIsValid$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    processorIssueCounter$: BehaviorSubject<number> = new BehaviorSubject(0);
    processorsChanged$ = new BehaviorSubject<Processor>({});
    terminalsChanged$ = new BehaviorSubject<boolean>(false);
    depotConfigData: Depot = {} as Depot;
    showDepotConfigForm = false;

    httpOptions: PaymentApiServiceOptions = {};

    adminConfig$: BehaviorSubject<PaymentAppAdminInput> = new BehaviorSubject<PaymentAppAdminInput>({} as PaymentAppAdminInput);


    get adminConfig(): PaymentAppAdminInput {
        return this.adminConfig$.value;
    }

    processorFeatures: ProcessorFeature[] = [];

    processorListInput: ProcessorListInput = {
        httpOptions: {} as PaymentApiServiceOptions,
        paymentConfig: {} as PaymentConfig,
        isGAdmin: false
    };

    /** Payment Config */
    paymentConfigInput = {
        paymentConfig: <PaymentConfigExtended>{
            Address1: '',
            City: '',
            State: '',
            PostalCode: '',
            Country: 'US',
            DefaultCurrencyCode: 'USD',
            isValid: false
        },
        isLoading: true
    };

    currencyArray: Array<{ code: string; name: string }> = [];
    countryCodesArray: Array<{ code: string; name: string }> = [];

    setupRequestHeaders() {
        this.httpOptions = {
            headers: new HttpHeaders().set('X-API-Key', this.adminConfig?.AdminApiKey || '')
            /* .set('X-Lang', this.config?.Locale || 'en')*/
        };
    }

    // Angular elements have to use ngOnInit and can't have this in the constructor for some reason?
    ngOnInit(): void {
        this.setupArrays();

        this.adminConfig$.subscribe(async config => {
            // make sure that the input object is set before calling getTransaction.
            // we can do these by checking that there's at least one key in the input object.
            if (Object.keys(config).shift()) {
                this.setupRequestHeaders();
                this.paymentAdminService.paymentAdminConfig$.next(config);
                this.paymentAdminService.apiBaseUrl = config.ApiUrl ?? '';
                this.loadPaymentConfig();
            }
        });

        // here we are subscribing to the paymentAdminElementLoaded event to set the value of the apiKeyIsValid$ observable
        this.paymentAdminElementLoaded.subscribe(value => {
            this.apiKeyIsValid$.next(value);
        });
    }

    ngOnChanges(): void {
        this.setupInputObject();
    }

    private setupArrays() {
        this.currencyArray = this.paymentAdminService.fetchCurrencies();
        this.countryCodesArray = this.paymentAdminService.fetchCountryCodes();
    }

    async loadPaymentConfig() {
        Promise.all([this.paymentAdminService.fetchPaymentConfig(this.httpOptions)])
            .then(async ([PaymentConfigE]: [PaymentConfigExtended]) => {
                this.paymentConfigInput = {
                    ...this.paymentConfigInput,
                    paymentConfig: PaymentConfigE
                };
                // we are emitting true because the Promises.all() was successful in making all the calls to the api
                this.paymentAdminElementLoaded.emit(true);
                this.loadProcessorList();
            })
            .catch(() => {
                // there is an error when making a call to the api. We are emitting false to the calling app to let it know
                this.paymentAdminElementLoaded.emit(false);
            })
            .finally(() => {
                this.paymentConfigInput = {
                    ...this.paymentConfigInput,
                    isLoading: false
                };
            });
    }

    setupInputObject() {
        if (this.configInput) {
            const config = JSON.parse(this.configInput);
            this.adminConfig$.next(config);

            // use the specified locale or default to English.
            this.translate.use(config.Locale || 'en');
        }
    }

    async savePaymentConfig(config: PaymentConfigExtended) {
        this.paymentConfigInput.isLoading = true;
        await this.paymentAdminService.submitPaymentConfig(config, this.httpOptions).then(
            paymentConfig => {
                this.paymentConfigInput = {
                    ...this.paymentConfigInput,
                    paymentConfig,
                    isLoading: false
                };
                this.paymentConfigInput.paymentConfig.isValid = config.isValid;
                this.loadProcessorList();
            },
            () =>
            (this.paymentConfigInput = {
                paymentConfig: config,
                isLoading: false
            })
        );
    }

    private loadProcessorList() {
        this.processorListInput = {
            httpOptions: this.httpOptions,
            paymentConfig: this.paymentConfigInput.paymentConfig,
            isGAdmin: this.adminConfig.IsGAdmin
        };
    }

    async editDepot(depotToEdit: Depot) {
        this.depotConfigData = depotToEdit;
        this.showDepotConfigForm = true;
    }

    onDepotEditCancel() {
        this.showDepotConfigForm = false;
    }
}
