import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import {
  PaymentAdminComponent,
  PaymentAdminElementModule,
} from '@por/por-pay/admin';

@NgModule({
  imports: [PaymentAdminElementModule],
})
export class AppModule implements DoBootstrap {
  constructor(private readonly injector: Injector) {}

  ngDoBootstrap(): void {
    const entryPoint = createCustomElement(PaymentAdminComponent, {
      injector: this.injector,
    });
    customElements.define('por-payment-app-config-wizard', entryPoint);
  }
}
