<mat-stepper #stepper [linear]="true">
  <mat-step label="Organization Info">
    <div class="por-pay-ui-loading" *ngIf="(currentStep$ | async) === wizardStepsEnum.Loading">
      <div class="por-pay-ui-loading-header">{{ 'Loading, please wait...' | translate }}</div>
      <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div class="error-message-to-show" *ngIf="(currentStep$ | async) === wizardStepsEnum.Error">
      <p>{{this.errorMessageToShow | translate}}</p>
      <button (click)="onSummaryCancel()" id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" variant="secondary" apxButton>{{'Back' | translate}}</button>
    </div>
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.OrgConfig">
      <por-payment-config-admin-form
        [isWizardSetup]="isSetupWizard"
        [paymentConfigInput]="{
            paymentConfig: existingOrgConfig,
            isLoading: false
        }"
        (cancel)="onSummaryCancel()"
        (savePaymentConfigEvent)="onSavePaymentConfig($event)">
      </por-payment-config-admin-form>
    </div>
  </mat-step>
  <mat-step label="Choose Store">
    <div class="por-pay-ui-loading" *ngIf="(currentStep$ | async) === wizardStepsEnum.Loading">
      <div class="por-pay-ui-loading-header">{{ 'Loading, please wait...' | translate }}</div>
      <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.StoreQuestion">
      <por-choose-a-store
        [selectedDepotId]="selectedDepot"
      [depots]="orgDepots"
      (selectedDepot)="onChooseStoreComplete($event)"
      (selectedDepotName)="onSelectDepotName($event)"
      (editStep)="onChooseStoreBack($event)"
      (wizardCanceled)="onChooseStoreCancel()">
      </por-choose-a-store>
    </div>
  </mat-step>
  <mat-step label="Merchant">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.ChooseProcessor">
      <por-choose-processor
        [depot]=selectedDepot
        [httpOptions]="httpOptions"
        [orgConfig]=existingOrgConfig
        (processorEditEvent)="editProcessor($event)"
        [selectedProcessorType]="selectedProcessorType"
        (selectedProcessorTypeEvent)="savedProcessorType($event)"
        (editStep)="onProcessorBack($event)">
      </por-choose-processor>
    </div>
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.AddProcessor">
        <por-processor-edit-component
          [depotName]="selectedDepotName"
          [depot]=selectedDepot
          [orgConfig]=existingOrgConfig
          [savedProcessor]="selectedProcessor"
          (userHasFinishedThisPage)="userHasFinishedThisPage($event)"
          (processorPaymentMethods)="selectedProcessorPaymentMethods($event)"
          [selectedPaymentMethodsInput]="selectedPaymentMethods"
          [processorInput]="(terminalsInput$ | async) ?? defaultProcessorInput"
          (updatedProcessor)="onProcessorComplete($event)"
          (editStep)="onProcessorAddBack($event)"></por-processor-edit-component>
    </div>
  </mat-step>
  <mat-step *ngIf="(selectedProcessorTypeEnum !== ProcessorTypeEnum.Stripe) && (selectedProcessorTypeEnum !== ProcessorTypeEnum.PeachPayments)" label="Terminal">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.TerminalQuestion">
      <por-question
        [question]="terminalQuestion"
        [buttons]="buttonOptions">
      </por-question>
    </div>
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.Terminal">
      <por-terminal-edit
        [depotName]="selectedDepotName"
        [depot]=selectedDepot
        [isWizardSetup]="isSetupWizard"
        [terminalConfig]="(terminalToEdit$ | async) ?? defaultTerminalInput" (editStep)="onTerminalBack($event)"
        (terminalFinishedEditingEvent)="onTerminalComplete($event)"
        (terminalConfigChangeEvent)="setTerminalIsDirty($event)"
        (terminalSavedEvent)="savedTerminal($event)"
        [isGAdmin]="existingOrgConfig.isGAdmin"
      ></por-terminal-edit>
    </div>
  </mat-step>
  <mat-step label="Summary">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.Summary">
      <por-summary
      [processor]=selectedProcessor
      [selectedProcessorPaymentMethods]=selectedPaymentMethods
      [terminal]=selectedTerminal
      [orgConfig]=existingOrgConfig
      [depot]=selectedDepot
      [depotName]="selectedDepotName"
      [httpOptions]="httpOptions"
      (editStep)="onSummaryEdit($event)"
      (stripeRegistrationEvent)="getStripeRegistrationInput($event)"
      (save)="onSummaryComplete()"
      (savedProcessor)="onSavedProcessor($event)"
      (cancel)="onSummaryCancel()">
      </por-summary>
    </div>
  </mat-step>
  <mat-step *ngIf="terminalsInput$.value.processor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe" label="Stripe Registration">
    <div *ngIf="(currentStep$ | async) === wizardStepsEnum.StripeRegistration">
      <por-stripe-registration class="por-pay-center" [stripeRegistrationInput]="stripeRegistrationInput" (cancel)="onRegistrationCancel()"></por-stripe-registration>
    </div>
  </mat-step>
</mat-stepper>
