/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Depot,
  PaymentAppGetRegistrationStatus,
  Processor,
  ProcessorFeature,
  RegistrationStatusCode, Terminal,
  ProcessorTypeEnum
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdminService, BottomSheetService } from '../../../services';
import { DisplayEnum } from "./display.enum";
import { ProcessorWRegistrationStatus } from "../../../models/ProcessorWRegistrationStatus";
import { BottomSheetElement, PaymentConfigExtended, ProcessorTypeEnumPretty } from '../../../models';
import { ProcessorFormConfig } from '../../../models/ProcessorFormConfig';
import { ProcessorEditFormComponent } from '../../processor-edit/processor-edit-form/processor-edit-form.component';
import { ProcessorListInput } from '../../processor-admin/processor-admin-list/processor-admin-list.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/**
 * Drop-in Configuration Wizard for registering and updating Payment App data in a calling app
 */
@Component({
  selector: 'por-depot-config-list',
  templateUrl: './depot-config-list.component.html',
  styleUrls: ['./depot-config-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepotConfigListComponent implements OnInit, OnDestroy {
  @Input() httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
  @Input() existingOrgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;
  @Input() processorsChanged$ = new BehaviorSubject<Processor>({});
  @Input() unassignedProcessors: ProcessorWRegistrationStatus[] = [];
  @Input() processorListInput: ProcessorListInput = {
    httpOptions: {},
    paymentConfig: {},
    isGAdmin: false
  };
  @Input() terminalsChanged$ = new BehaviorSubject<boolean>(false);

  @Output() readonly storeEditEvent = new EventEmitter<Depot>();
  @Output() readonly savePaymentConfigEvent = new EventEmitter();

  @Input() remoteDepotId?: string | null = null;

  dataToDisplay$ = new BehaviorSubject<Depot[]>([]);

  isLoading$ = new BehaviorSubject<boolean>(true);

  subscriptionList: Subscription[] = [];

  existingDepotConfig$ = new BehaviorSubject<Depot[]>([]);

  depots$ = new BehaviorSubject<Depot[]>([]);

  // this will be use to track the available depot ids
  availableDepotIds$ = new BehaviorSubject<string[]>([]);

  elementToShow$: BehaviorSubject<DisplayEnum> = new BehaviorSubject<DisplayEnum>(DisplayEnum.Stores);
  unassignedProcessorDisplay$ = new BehaviorSubject<ProcessorWRegistrationStatus[]>([]);
  terminalsDisplay: Terminal[] = [];
  existingDepots: Depot[] = [];
  depotForms: FormGroup[] = [];
  filteredData: Depot[] = [];
  displayLimit = 6;

  protected readonly DisplayEnum = DisplayEnum;
  private readonly subscription?: Subscription;
  private processorFeatures: ProcessorFeature[] = [];
  protected readonly processorTypeEnumPretty = ProcessorTypeEnumPretty;
  protected readonly console = console;

  constructor(private readonly fb: FormBuilder,
    readonly paymentAdminService: AdminService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private readonly bottomSheetService: BottomSheetService) { }

  async ngOnInit(): Promise<void> {
    this.unassignedProcessorDisplay$.subscribe((rows) => {
      if (this.depotForms.length === 0) {
        this.depotForms = rows.map(() => this.createDepotForm());
      }
    });

    await this.loadData();

    this.processorsChanged$.subscribe((updatedProcessor: Processor) => {
      if (Object.keys(updatedProcessor).length > 0) {
        const currentUnassignedDisplay = this.unassignedProcessorDisplay$.getValue();
        const unassignedProcessorIndex = currentUnassignedDisplay.findIndex(p => p.processor.ProcessorId === updatedProcessor.ProcessorId);

        if ((updatedProcessor.RemoteDepotId || updatedProcessor.Hidden === 1) && unassignedProcessorIndex > -1) {
          currentUnassignedDisplay.splice(unassignedProcessorIndex, 1);
          this.unassignedProcessorDisplay$.next([...currentUnassignedDisplay]);
        } else {
          this.paymentAdminService.fetchRegistrationStatusForProcessor(updatedProcessor, this.httpOptions)
            .then((status: PaymentAppGetRegistrationStatus) => {
              status = { ...status, ProcessorId: updatedProcessor.ProcessorId };
              if (unassignedProcessorIndex > -1) {
                currentUnassignedDisplay[unassignedProcessorIndex].registrationStatus = { ...status };
                this.unassignedProcessorDisplay$.next([...currentUnassignedDisplay]); // Spread to create a new array, triggering change detection
              } else if(!updatedProcessor.RemoteDepotId) {
                if(updatedProcessor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe && status['Status'] === 'Unknown') {
                  // If the status code is not Yay, it should be Nay.
                  status.RegistrationStatusCode = RegistrationStatusCode.Nay;
                  delete status['Status'];
                }
                currentUnassignedDisplay.push({ processor: updatedProcessor, registrationStatus: status });
                this.unassignedProcessorDisplay$.next([...currentUnassignedDisplay]);
              }
            })
        }
      }
    });

    this.terminalsChanged$.subscribe(value => {
      if (value) {
        this.loadTerminals();
      }
    });

    await this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(subscription => subscription?.unsubscribe());
  }

  createDepotForm(): FormGroup {
    return this.fb.group({
      depotId: ['', Validators.required]
    });
  }

  async loadData() {
    this.isLoading$.next(true);
    Promise.all([
      this.paymentAdminService.fetchProcessors(this.httpOptions),
      this.paymentAdminService.fetchProcessorFeatures(),
      this.paymentAdminService.fetchDepots(this.httpOptions, false, false, this.remoteDepotId),
      this.loadTerminals()
    ])
      .then(([processors, features, depots]: [Processor[], ProcessorFeature[], Depot[], void]) => {
        this.buildUnassignedProcessorsList(processors);

        depots = depots.filter(d => d.Id !== "");

        this.processorFeatures = features;

        this.existingDepotConfig$.next(depots);

        this.depots$.next(depots);

        this.updateRemoteDepotAvailable();

        this.existingDepots = depots;
        if(this.existingDepots.length === 0) {
          this.existingDepots = [{
            Id: '',
            Name: 'No Depots Available',
            Identifiers: '',
            Emails: [],
            Phones: [],
            AdditionalFields: '',
            ModelType: '',
            Addresses: [],
            CreatedDateTime: '',
            UpdatedDateTime: '',
            Hidden: 0
          }];
        }

        this.dataToDisplay$.next(depots);

        this.filteredData = depots;
      })
      .finally(() => this.isLoading$.next(false));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (!filterValue) {
      // If the filter value is empty, reset the filtered data to the full list
      this.dataToDisplay$.subscribe(data => {
        this.filteredData = data;
      });
    } else {
      this.dataToDisplay$.subscribe(data => {
        this.filteredData = data.filter(store => {
          try {
            return store.Id.toString().toLowerCase().includes(filterValue) || store.Name.toLowerCase().includes(filterValue)
          } catch (e) {
            console.log(e);
            return false;
          }
        }
        );
      });
    }
  }

  loadMore() {
    this.displayLimit += 6;
  }

  fetchProcessorFeatures() {
    this.paymentAdminService.fetchProcessorFeatures().then(features => {
      this.processorFeatures = features;
    });
  }

  async updateProcessorsRegistrationStatus() {
    this.paymentAdminService.fetchRegistrationStatuses(this.httpOptions).pipe().subscribe({
      next: (status: PaymentAppGetRegistrationStatus) => {
        const unassignedProcessors = this.unassignedProcessorDisplay$.getValue();
        const unassignedProcessorIndex = unassignedProcessors.findIndex(p => p.processor.ProcessorId === status.ProcessorId);
        if (unassignedProcessorIndex > -1) {
          unassignedProcessors[unassignedProcessorIndex].registrationStatus = status;
          this.unassignedProcessorDisplay$.next([...unassignedProcessors]); // Spread to create a new array, triggering change detection
        }
      },
      error: error => {
        this.console.error('Error fetching registration statuses:', error);
      }
    });
  }

  async editProcessor(rowProcessor: ProcessorWRegistrationStatus) {
    const processorFeature = this.processorFeatures.find(feature => {
      return feature.processorType === rowProcessor.processor.ProcessorTypeEnum;
    });

    if (processorFeature === undefined) {
      throw new Error(this.translate.instant('processorFeature is undefined'));
    }

    const processorEditConfig: ProcessorFormConfig = {
      processorWRegistrationStatus: rowProcessor,
      terminals: this.terminalsDisplay.filter(t => {
        return t.ProcessorId === rowProcessor.processor.ProcessorId;
      }),
      processorFeatures: processorFeature,
      httpOptions: this.httpOptions,
      isGAdmin: this.existingOrgConfig.isGAdmin,
      paymentConfig: this.processorListInput.paymentConfig
    };

    const dialogConfig: MatDialogConfig = {
      data: { ...processorEditConfig },
      width: '1000px', // You can set custom size, etc.
      height: '750px'
    };
    const processorEditModal = this.dialog.open(ProcessorEditFormComponent, dialogConfig);
    processorEditModal.componentInstance.changedProcessorEvent.subscribe(async (updatedProcessor: Processor) => {
      this.processorsChanged$.next(updatedProcessor);
    });

    processorEditModal.componentInstance.changedTerminalEvent.subscribe(async () => {
      this.terminalsChanged$.next(true);
    });
  }

  async saveDepot(newId: string, rowProcessor: ProcessorWRegistrationStatus) {
    if(newId === '') {
      return;
    }
    const buttons: BottomSheetElement[] = [
      {
        title: 'Yes'
      },
      {
        title: 'Cancel'
      }
    ];
    const message = `Are you sure you want to assign merchant ${rowProcessor.processor.Label} to store ${newId}? This action cannot be reversed.`;
    const bottomSheet = await this.bottomSheetService.open(message, buttons);
    if (bottomSheet?.title === 'Yes') {
      rowProcessor.processor.RemoteDepotId = newId;
      this.paymentAdminService.updateProcessor(rowProcessor.processor, this.httpOptions).then((updatedProcessor: Processor) => {
        this.processorsChanged$.next(updatedProcessor);
      });
    }
  }

  getProcessorType(processor: Processor): string {
    return processor.ProcessorTypeEnum ? this.processorTypeEnumPretty[processor.ProcessorTypeEnum] : 'Default Value';
  }

  editConfiguration(config: Depot) {
    // if there are no depots available and we are not editing/updating one, then return;
    if (this.availableDepotIds$.value.length === 0 && Object.keys(config).length === 0) return;
    const configToModify = cloneDeep(config);
    this.storeEditEvent.emit(configToModify);
  }

  private updateRemoteDepotAvailable() {
    const usedDepotIds = this.existingDepotConfig$.value.map(config => config.Id);

    this.availableDepotIds$.next(this.depots$.value.filter((d: Depot) => !usedDepotIds.includes(d.Id)).map((d: Depot) => d.Id));
  }

  onWizardComplete() {
    this.savePaymentConfigEvent.emit(this.existingOrgConfig);
    this.changeElementToShow(DisplayEnum.Stores);
  }

  onNewProcessor(processor: Processor) {
    this.processorsChanged$.next(processor);
  }

  onWizardCanceled() {
    this.changeElementToShow(DisplayEnum.Stores);
  }

  onChangedPaymentConfig(newConfig: PaymentConfigExtended) {
    this.existingOrgConfig = newConfig;
  }

  changeElementToShow(elementToShow: DisplayEnum) {
    this.elementToShow$.next(elementToShow);
  }

  async loadTerminals() {
    await this.paymentAdminService.fetchTerminals(this.httpOptions).then(terminals => (this.terminalsDisplay = terminals));
  }

  async buildUnassignedProcessorsList(processors: Processor[]) {
    const processorsWithRemoteDepotIdUnassigned: ProcessorWRegistrationStatus[] = processors.filter((processor: Processor) => !processor.RemoteDepotId).map((processor: Processor) => {
      return {
        processor,
        registrationStatus: {
          ProcessorId: processor.ProcessorId,
          Message: 'Loading...',
          RegistrationStatusCode: RegistrationStatusCode.Unknown
        } as PaymentAppGetRegistrationStatus,
      };
    });
    this.unassignedProcessorDisplay$.next(processorsWithRemoteDepotIdUnassigned);
    this.updateProcessorsRegistrationStatus();// update each processors status;
  }

  protected readonly RegistrationStatusCode = RegistrationStatusCode;

  getTooltipMessage(statusCode: RegistrationStatusCode | undefined): string {
    switch (statusCode) {
      case this.RegistrationStatusCode.Nay:
        return 'Credentials are invalid';
      case this.RegistrationStatusCode.Yay:
        return 'Credentials are valid';
      default:
        return 'Validating credentials...';
    }
  }
}
