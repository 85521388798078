import * as CustomerTableSchema from './Customer.table.json';
// eslint-disable-next-line camelcase
import * as Customer_ProcessorTableSchema from './Customer_Processor.table.json';
import * as DepotConfigTableSchema from './DepotConfig.table.json';
import * as PaymentConfigTableSchema from './PaymentConfig.table.json';
import * as ProcessorTableSchema from './Processor.table.json';
import * as RecurringBillingTableSchema from './RecurringBilling.table.json';
import * as TerminalTableSchema from './Terminal.table.json';
import * as TransactionTableSchema from './Transaction.table.json';
import * as TransactionDetailsTableSchema from './TransactionDetails.table.json';
import * as TransactionLogTableSchema from './TransactionLog.table.json';

export {
    CustomerTableSchema,
    // eslint-disable-next-line camelcase
    Customer_ProcessorTableSchema,
    DepotConfigTableSchema,
    PaymentConfigTableSchema,
    ProcessorTableSchema,
    RecurringBillingTableSchema,
    TerminalTableSchema,
    TransactionDetailsTableSchema,
    TransactionLogTableSchema,
    TransactionTableSchema
};
