<div *ngIf="paymentConfigInput" class="por-pay-organization-info por-pay-info-container">
    <div class="por-pay-ui-loading" *ngIf="paymentConfigInput.isLoading">
        <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
        <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div *ngIf="!paymentConfigInput.isLoading" class="por-pay-info-content por-pay-organization-info-content">
        <div class="por-pay-organization-info-container">
            <form id="adminForm" [formGroup]="paymentConfigForm" (keydown.enter)="$event.preventDefault()">
                <div class="por-pay-organization-info-input-fields">
                    <mat-form-field
                        class="por-pay-body-input">
                      <mat-label>{{ 'Phone' | translate }}</mat-label>
                      <input matInput formControlName="PrimaryPhone" type="text" />
                      <mat-error *ngIf="primaryPhone?.errors?.['pattern']">{{ 'Invalid phone number. Must be E.64 format with country code. ie: +18001234567.' | translate }}</mat-error>
                      <mat-error *ngIf="primaryPhone?.errors?.['required'] && primaryPhone?.invalid">{{ 'Required Field' | translate }}</mat-error>
                      <mat-hint>{{ 'Must be E.64 format with country code. ie: +18001234567.' | translate }}</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="por-pay-body-input">
                    <mat-label>{{ 'Email' | translate }}</mat-label>
                      <input matInput formControlName="Email" type="email" />
                      <mat-error *ngIf="email?.invalid && email?.errors?.['pattern']">{{ 'Invalid Email address.' | translate }}</mat-error>
                      <mat-error *ngIf="email?.errors?.['required'] && email?.invalid">{{ 'Required Field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="por-pay-organization-info-input-fields">
                  <mat-form-field
                    class="por-pay-body-input">
                    <mat-label>{{ 'Address' | translate }}</mat-label>
                    <input matInput formControlName="Address1" type="text" />
                    <mat-error *ngIf="address1?.errors?.['required'] && address1?.invalid">{{ 'Required Field' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="por-pay-body-input">
                    <mat-label>{{ 'Address (2)' | translate }}</mat-label>
                    <input matInput formControlName="Address2" type="text" />
                  </mat-form-field>
              </div>
              <div class="por-pay-organization-info-input-fields">
                <mat-form-field
                  class="por-pay-body-input">
                  <mat-label>{{ 'City' | translate }}</mat-label>
                  <input matInput formControlName="City" type="text" />
                  <mat-error *ngIf="city?.errors?.['required'] && city?.invalid">{{ 'Required Field' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="por-pay-body-input">
                  <mat-label>{{ 'State' | translate }}</mat-label>
                  <input matInput formControlName="State" type="text" />
                  <mat-error *ngIf="state?.errors?.['required'] && state?.invalid">{{ 'Required Field' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="por-pay-organization-info-input-fields">
                  <mat-form-field class="por-pay-body-input" id="postal-code-input">
                    <mat-label>{{ 'Postal Code' | translate }}</mat-label>
                    <input matInput formControlName="PostalCode" type="text" />
                    <mat-error *ngIf="postalCode?.errors?.['minlength'] || postalCode?.errors?.['maxlength']">
                      {{ 'Postal Code should be 4-10 characters.' | translate }}
                    </mat-error>
                    <mat-error *ngIf="postalCode?.errors?.['required'] && postalCode?.invalid">
                      {{ 'Required Field' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field floatLabel="always" class="por-pay-body-input por-pay-select" id="country-input" appearance="outline">
                      <mat-label class="input-title">{{'Country' | translate}}</mat-label>
                      <mat-select formControlName="Country">
                          <mat-option *ngFor="let country of countryCodesArray" [value]="country.code">
                              {{ country.code }}
                              - {{ country.name }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="por-pay-organization-info-input-fields" id="currency-code-div">
                  <mat-form-field class="por-pay-body-input por-pay-select" id="currency-code-input" appearance="outline">
                    <mat-label>{{ 'Default Currency Code' | translate }}</mat-label>
                    <mat-select formControlName="DefaultCurrencyCode">
                      <mat-option *ngFor="let currency of currencyArray" [value]="currency.code">
                        {{ currency.code }} - {{ currency.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="por-pay-button-container">
                    <div [ngClass]="isWizardSetup ? 'center-btn-container' : 'por-pay-right'" class="por-pay-button-save-container">
                        <button
                          matStepperPrevious
                          class="por-pay-cancel-button"
                          type="submit"
                          *ngIf="isWizardSetup"
                          id="por-pay-cancel-org-info"
                          (click)="onCancel()">
                          {{ 'Cancel' | translate}}
                        </button>
                        <button
                            matStepperNext
                            class="por-pay-process-button"
                            type="submit"
                            id="por-pay-save-org-info"
                            [disabled]="this.isWizardSetup ? this.paymentConfigForm.invalid : !(this.paymentConfigForm.dirty && this.paymentConfigForm.valid)"
                            (click)="save()">
                            {{ isWizardSetup ? ('Next' | translate) : ('Save' | translate) }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
