/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Processor, Terminal } from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { AdminService } from '../../../services';

@Component({
    selector: 'por-terminal-list',
    templateUrl: './terminal-list.component.html',
    styleUrls: ['./terminal-list.component.scss']
})
export class TerminalListComponent implements AfterViewChecked {
    @Input() terminalsInput = {
        processor: {} as Processor,
        terminals: [] as Terminal[],
        httpOptions: {} as PaymentApiServiceOptions
    };
    @Output() readonly terminalsInputChanged = new EventEmitter<{
        processor: Processor;
        terminals: Terminal[];
        httpOptions: PaymentApiServiceOptions;
    }>();
    @Output() readonly terminalEditEvent = new EventEmitter<Terminal>();
    @Output() readonly terminalCancelEvent = new EventEmitter<boolean>();

    @ViewChild('scrollableList') scrollableList: ElementRef = {} as ElementRef;
    scrollNewItemIntoView = false;

    selectedTerminal: Terminal = {} as Terminal;
    @Input() disabledList = false;

    constructor(private readonly paymentAdminService: AdminService, private readonly cdRef: ChangeDetectorRef) {}

    editTerminal(terminal: Terminal) {
        this.selectedTerminal = terminal;
        this.terminalEditEvent.emit(terminal);
    }

    addTerminal() {
        const newTerminal: Terminal = {
            TerminalId: '',
            ProcessorId: this.terminalsInput.processor.ProcessorId,
            OrganizationId: this.terminalsInput.processor.OrganizationId,
            Label: 'New Terminal'
        };

        if (this.terminalsInput.processor.ProcessorTypeEnum === undefined) {
            throw new Error('ProcessorTypeEnum is undefined');
        }

        this.terminalsInput.terminals.push(newTerminal);
        this.terminalsInputChanged.emit({
            ...this.terminalsInput,
            terminals: this.terminalsInput.terminals
        });
        this.selectedTerminal = newTerminal;
        this.editTerminal(newTerminal);
        this.scrollNewItemIntoView = true; // Indicate that the view should scroll to the new item
        this.cdRef.detectChanges(); // Trigger change detection
    }

    ngAfterViewChecked() {
        if (this.scrollNewItemIntoView) {
            const items = this.scrollableList.nativeElement.querySelectorAll('mat-list-item');
            const newItem = items[items.length - 1]; // Get the last item, which is the newly added one
            newItem.scrollIntoView({ behavior: 'smooth' }); // Scroll the new item into view
            this.scrollNewItemIntoView = false; // Reset the flag
        }
    }

    cancel() {
        this.terminalCancelEvent.emit(true);
    }
}
