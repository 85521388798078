/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This must be usable as a keyable and reverse keyable object
 */
 export enum ProcessorTypeEnum {
  Stripe = 1,
  Cayan = 2,
  PeachPayments = 3,
  // Elavon = 4,
  // Falabella = 5,
  // Moneris = 6,
  // Transbank = 7,
  EziDebit = 8,
  GPIMITC = 9
}

// Used for display purposes
export enum ProcessorTypeEnumPretty {
  Stripe = 1,
  Cayan = 2,
  'Peach Payments' = 3,
  // Elavon = 4,
  // Falabella = 5,
  // Moneris = 6,
  // Transbank = 7,
  EziDebit = 8,
  GPIMITC = 9
}

/**
* Processor Types that support Registration
*/
export enum ProcessorTypeEnumSupportsRegistration {
  Stripe = 1,
  Cayan = 2,
  'Peach Payments' = 3,
  EziDebit = 8
}

/**
* Processor Types that support Terminals
*/
export enum ProcessorTypeEnumSupportsTerminals {
  Stripe = 1,
  Cayan = 2
}
