<div *ngIf="paymentConfigInput" class="por-pay-organization-info por-pay-info-container">
    <div class="por-pay-ui-loading" *ngIf="paymentConfigInput.isLoading">
        <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
        <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div *ngIf="!paymentConfigInput.isLoading" class="por-pay-info-content por-pay-organization-info-content">
        <div class="por-pay-organization-info-container">
            <form id="adminForm" [formGroup]="paymentConfigForm" (keydown.enter)="$event.preventDefault()">
                <div class="por-pay-organization-info-input-fields">
                    <apx-form-field
                        [error]="((!primaryPhone?.valid)
                              ? primaryPhone?.errors?.['pattern']
                              ? ('Invalid phone number. Must be E.64 format with country code. ie: +18001234567.' | translate)
                              :  ((primaryPhone?.errors?.['required'] && primaryPhone?.invalid) ? ('Required Field' | translate) : '') : ''
                              )"
                        class="por-pay-body-input"
                        label="{{ 'Phone *' | translate }}"
                        hint="{{ 'Must be E.64 format with country code. ie: +18001234567.' | translate }}">
                        <input apxInput formControlName="PrimaryPhone" type="text" />
                    </apx-form-field>
                    <apx-form-field
                        [error]="((!email?.valid)
                              ? email?.errors?.['pattern']
                              ? ('Invalid Email address.' | translate)
                              : ((email?.errors?.['required'] && email?.invalid) ? ('Required Field' | translate) : '') : '')"
                        class="por-pay-body-input"
                        label="{{ 'Email *' | translate }}">
                        <input apxInput formControlName="Email" type="email" />
                    </apx-form-field>
                </div>
                <div class="por-pay-organization-info-input-fields">
                  <apx-form-field
                    [error]="((address1?.errors?.['required'] && address1?.invalid)) ? ('Required Field' | translate) : ''"
                    class="por-pay-body-input"
                    label="{{ 'Address *' | translate }}">
                    <input apxInput formControlName="Address1" type="text" />
                  </apx-form-field>
                  <apx-form-field class="por-pay-body-input" label="{{ 'Address (2)' | translate }}">
                      <input apxInput formControlName="Address2" type="text" />
                  </apx-form-field>
              </div>
              <div class="por-pay-organization-info-input-fields">
                <apx-form-field
                  [error]="((city?.errors?.['required'] && city?.invalid)) ? ('Required Field' | translate) : ''"
                  class="por-pay-body-input"
                  label="{{ 'City *' | translate }}">
                  <input apxInput formControlName="City" type="text" />
                </apx-form-field>
                <apx-form-field
                  [error]="((state?.errors?.['required'] && state?.invalid)) ? ('Required Field' | translate) : ''"
                  class="por-pay-body-input"
                  label="{{ 'State *' | translate }}">
                  <input apxInput formControlName="State" type="text" />
                </apx-form-field>
              </div>
              <div class="por-pay-organization-info-input-fields">
                  <apx-form-field
                      [error]="
              ((!postalCode?.valid)
                ? (postalCode?.errors?.['minlength'] || postalCode?.errors?.['maxlength'])
                  ? ('Postal Code should be 4-10 characters.' | translate) : ((postalCode?.errors?.['required'] && postalCode?.invalid) ? ('Required Field' | translate) : '')
                  : '')"
                      class="por-pay-body-input"
                      id="postal-code-input"
                      label="{{ 'Postal Code *' | translate }}">
                      <input apxInput formControlName="PostalCode" type="text" />
                  </apx-form-field>
                  <mat-form-field floatLabel="always" class="por-pay-body-input por-pay-select" id="country-input" appearance="outline">
                      <mat-label class="input-title" translate="Country"></mat-label>
                      <mat-select formControlName="Country">
                          <mat-option *ngFor="let country of countryCodesArray" [value]="country.code">
                              {{ country.code }}
                              - {{ country.name }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="por-pay-organization-info-input-fields" id="currency-code-div">
                  <mat-form-field class="por-pay-body-input por-pay-select" id="currency-code-input" appearance="outline">
                    <mat-label class="input-title" translate="Default Currency Code"></mat-label>
                    <mat-select formControlName="DefaultCurrencyCode">
                      <mat-option *ngFor="let currency of currencyArray" [value]="currency.code">
                        {{ currency.code }}
                        - {{ currency.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="por-pay-button-container">
                    <div [ngClass]="isWizardSetup ? 'center-btn-container' : 'por-pay-right'" class="por-pay-button-save-container">
                        <button
                          apxButton
                          matStepperPrevious
                          class="por-pay-process-button"
                          type="submit"
                          *ngIf="isWizardSetup"
                          id="por-pay-cancel-org-info"
                          variant="secondary"
                          (click)="onCancel()">
                          {{ 'Cancel' | translate}}
                        </button>
                        <button
                            apxButton
                            matStepperNext
                            class="por-pay-process-button"
                            type="submit"
                            id="por-pay-save-org-info"
                            [disabled]="this.isWizardSetup ? this.paymentConfigForm.invalid : !(this.paymentConfigForm.dirty && this.paymentConfigForm.valid)"
                            (click)="save()">
                            {{ isWizardSetup ? ('Next' | translate) : ('Save' | translate) }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
