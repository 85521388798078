/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, isDevMode, OnInit, Output } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ProcessorFeature, Processor, ProcessorTypeEnum, PaymentConfig } from '@por/por-pay/shared';
import { AdminService } from '../../../../services';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'por-processor-add-form',
    templateUrl: './processor-add-form.component.html',
    styleUrls: ['./processor-add-form.component.scss']
})
export class ProcessorAddFormComponent implements OnInit {
    @Output() readonly processorAdded = new ReplaySubject<Processor>(1);

    httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
    paymentConfig: PaymentConfig;

    prodReadyProcessorTypes$ = new BehaviorSubject<ProcessorFeature[]>([]);
    selectedProcessorType: ProcessorFeature = {} as ProcessorFeature;
    newProcessor?: Processor;

    constructor(
        private readonly paymentAdminService: AdminService,
        private readonly translateService: TranslateService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            httpOptions: PaymentApiServiceOptions;
            paymentConfig: PaymentConfig;
        } // Injecting the data here
    ) {
        this.httpOptions = dialogData.httpOptions;
        this.paymentConfig = dialogData.paymentConfig;
    }

    ngOnInit(): void {
        this.fetchProcessorFeatures();
    }

    fetchProcessorFeatures() {
        this.paymentAdminService.fetchProcessorFeatures().then((data: ProcessorFeature[]) => {
            const prodReadyAndCountrySupported = data.filter(feature =>
                // show only the processors that are supported in production
                feature.formSupport === true && isDevMode() ? true : feature.productionSupport && feature.country?.includes(this.paymentConfig.Country as string)
            );

            this.prodReadyProcessorTypes$.next(prodReadyAndCountrySupported);
        });
    }

    async cancel() {
        if (this.newProcessor && !this.newProcessor.ProcessorId) {
            await this.paymentAdminService.deleteProcessor(this.newProcessor, this.httpOptions);
        }
        this.dialog.closeAll();
    }

    async createProcessor() {
        if (!this.selectedProcessorType) {
            return this.paymentAdminService.handleError(this.translateService.instant('You must select a Payment Processor.'));
        }

        let currencyCode = this.paymentConfig.DefaultCurrencyCode ?? '';

        if (this.selectedProcessorType.currency && !this.selectedProcessorType.currency.includes(currencyCode)) {
            currencyCode = this.selectedProcessorType.currency[0];
        }

        const procData: Processor = {
            ProcessorTypeEnum: this.selectedProcessorType.processorType as unknown as ProcessorTypeEnum,
            DefaultCurrencyCode: currencyCode,
            CreditCard: 1,
            Config: {}
        };

        const newProc = await this.paymentAdminService.createProcessor(procData, this.httpOptions);

        this.newProcessor = newProc;
        this.processorAdded.next(newProc);
    }
}
