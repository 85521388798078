/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface TransactionDetails {
    /**
     * The primary key for the Payment App Transaction Meta Data table
     */
    TransactionDetailsId?: string;
    /**
     * The TransactionId that corresponds to the Payment App Transaction table
     */
    TransactionId?: string;
    /**
     * Whether this Transaction is soft deleted or not
     */
    Hidden?: number;
    /**
     * This unique value is assigned by the merchant to identify the order. (Contract #, JobsiteId, InvoiceId…etc)
     */
    OrderId?: string | null;
    /**
     * A reference field provided by the purchasing customer. (PO or Job Reference)
     */
    PONumber?: string | null;
    /**
     * An indicator that specifies whether the transactions's primary amount includes, omits, exempt of tax
     */
    IsTaxExempt?: number | null;
    /**
     * This field is used as a more general description of the goods involved in the sale
     */
    ProductDescription?: string | null;
    /**
     * If the merchant needs to ship the item, this is the postal code of the customer’s delivery address (destination)
     */
    ShippingToZipCode?: string | null;
    /**
     * If the merchant needs to ship the item, this is the postal code of the location the merchant ships the item from (source)
     */
    ShippingFromZipCode?: string | null;
    /**
     * If the merchant needs to ship the item, this is the amount of the transactions that the customer pays for shipping
     */
    ShippingAmount?: number | null;
    /**
     * This field would include the total discount amount of all the line items included in the transactions
     */
    DiscountAmount?: number | null;
    /**
     * This field would include the declared tax amount in the transactions
     */
    TaxAmount?: number | null;
    /**
     * If the merchant needs to ship the item, this is the country code of the customer’s delivery address
     */
    DestinationCountryCode?: string | null;
    LineItems?: Array<{
        /**
         * 12 characters (Stripe limit) that uniquely identify the product
         */
        ProductCode?: string | null;
        /**
         * 26 alphanumeric characters (Stripe limit) describing the product
         */
        ProductDescription?: string | null;
        /**
         * The line item’s Universal Product Code
         */
        UPC?: string | null;
        /**
         * The number of units that the line item contains. Some merchants use units for the line item as specified in the UnitOfMeasure field
         */
        Quantity?: number | null;
        /**
         * The unit of measure for the contents of the line item
         */
        UnitOfMeasure?: string | null;
        /**
         * The cost of a single unit from the line item
         */
        UnitCost?: number | null;
        /**
         * The extended amount of the line item
         */
        ExtendedAmount?: number | null;
        /**
         * The amount of tax this item had added to it
         */
        TaxAmount?: number | null;
        /**
         * The amount that the merchant deducts as a discount from the cost of the line item
         */
        DiscountAmount?: number | null;
        /**
         * The total amount of the line item after the merchant deducts all tax and discounts (Net amount)
         */
        TotalAmount?: number | null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [k: string]: any;
    }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
}
