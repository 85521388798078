import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import * as EnUSTranslations from './assets/i18n/en.json';
import {Language, UIMissingTranslationHandler, UITranslateLoader} from "@por/por-pay/shared";

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: () => {
                return new UITranslateLoader(
                  { [Language.EN_US]: EnUSTranslations }
                );
              },
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: UIMissingTranslationHandler },
            defaultLanguage: Language.EN_US
        })
    ],
    exports: []
})
export class PaymentUiSharedModule {}
