<div class="por-pay-depot-config-info por-pay-info-container">
  <div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
    <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
    <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
  </div>
  <div *ngIf="(isLoading$ | async) === false" class="por-pay-info-content por-pay-depot-config-info-content">
    <div *ngIf="(elementToShow$ | async) === DisplayEnum.Stores" class="por-pay-footer-buttons">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ 'Quick Actions' | translate }}</mat-card-title>
          <mat-card-subtitle>{{ 'Click one of the actions below to add a new merchant or terminal.' | translate
            }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content id="add-btn-container">
          <mat-card-actions>
            <button apxButton class="por-pay-button por-pay-button-add" variant="primary"
              (click)="changeElementToShow(DisplayEnum.ProcessorWizard)">
              {{ 'Add Merchant' | translate }}
            </button>
            <button apxButton class="por-pay-button por-pay-button-add" variant="primary"
              (click)="changeElementToShow(DisplayEnum.TerminalWizard)">
              {{ 'Add Terminal' | translate }}
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>

    <ng-container>
      <ng-template *ngIf="(elementToShow$ | async) === DisplayEnum.Stores"
        [ngTemplateOutlet]="showStores"></ng-template>
      <ng-template *ngIf="(elementToShow$ | async) === DisplayEnum.TerminalWizard"
        [ngTemplateOutlet]="showTerminalWizard"></ng-template>
      <ng-template *ngIf="(elementToShow$ | async) === DisplayEnum.ProcessorWizard"
        [ngTemplateOutlet]="showProcessorWizard"></ng-template>
    </ng-container>
    <ng-template #showTerminalWizard>
      <por-terminal-wizard [httpOptions]="httpOptions" [existingOrgConfig]="existingOrgConfig"
        (wizardComplete)="onWizardComplete($event)" (wizardCanceled)="onWizardCanceled()">
      </por-terminal-wizard>
    </ng-template>

        <ng-template #showProcessorWizard>
          <por-processor-wizard
            [httpOptions]="httpOptions"
            [existingOrgConfig]="existingOrgConfig"
            (wizardComplete)="onWizardComplete()"
            (wizardCanceled)="onWizardCanceled()"
            (changedPaymentConfig)="onChangedPaymentConfig($event)"
            (savedProcessor)="onNewProcessor($event)"
          ></por-processor-wizard>
        </ng-template>
        <ng-template #showStores>
          <div class="por-pay-footer-buttons">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ 'Your Stores' | translate }}</mat-card-title>
                <mat-form-field class="search-box">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput (input)="applyFilter($event)" placeholder="Search by ID or Name">
                </mat-form-field>
              </mat-card-header>
              <mat-card-content>
                <div *ngIf="(isLoading$ | async) === false" id="existingStoresList" (keydown.enter)="$event.preventDefault()">
                  <div class="store-card" *ngFor="let config of filteredData | slice:0:displayLimit; let i = index">
                    <div class="store-card-header">
                      <div class="store-info" [class.centered]="!config.Name || config.Name.trim() === ''">
                        <h3 class="store-id">Store ID: {{ config.Id }}</h3>
                        <h4 class="store-name" *ngIf="config.Name && config.Name.trim() !== ''">Store Name: {{ config.Name }}</h4>
                      </div>
                      <div class="store-actions" (click)="editConfiguration(config)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="store-card" *ngIf="filteredData.length === 0">
                    <h4>{{ 'No results' | translate }}</h4>
                  </div>
                  <button mat-button *ngIf="displayLimit < filteredData.length" (click)="loadMore()" class="load-more-button">Load More</button>
                </div>
              </mat-card-content>
            </mat-card>

      </div>
      <div class="por-pay-footer-buttons" *ngIf="unassignedProcessorDisplay$.value.length">
        <mat-card>
          <mat-card-header class="unassignedProcessorsHeader">
            <mat-card-title>{{ 'Merchants Not Assigned To A Store' | translate }}</mat-card-title>
            <mat-card-subtitle>{{'These merchants need to be assigned to a store.'| translate}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="(isLoading$ | async) === false" id="unassignedProcessorsList"
              (keydown.enter)="$event.preventDefault()">
              <div *ngFor="let rowProcessorDisplay of unassignedProcessorDisplay$ | async; let i = index"
                class="store-card processors-card por-pay-row-status-{{ rowProcessorDisplay.registrationStatus.RegistrationStatusCode }}"
                   [matTooltip]="getTooltipMessage(rowProcessorDisplay.registrationStatus.RegistrationStatusCode) | translate">
                <div class="store-card-header">
                  <div class="processor-info">
                    <h3 class="por-pay-processor-label">{{ rowProcessorDisplay.processor.Label }}</h3>
                    <p class="por-pay-processor-name">
                      {{'Merchant:' | translate}} {{ getProcessorType(rowProcessorDisplay.processor) }}
                    </p>
                    <button mat-button id="assign-depot-btn" [matMenuTriggerFor]="depots" class="arrow-button">
                      {{ 'Assign' | translate }}
                    </button>
                    <mat-menu #depots="matMenu">
                      <div *ngFor="let config of existingDepots; let j = index">
                        <button (click)="saveDepot(config.Id, rowProcessorDisplay)" mat-menu-item>{{ config.Id }} <span *ngIf="config.Name && config.Id !== ''"> - </span> <span *ngIf="config.Name">{{ config.Name }}</span></button>
                      </div>
                    </mat-menu>
                  </div>
                  <div class="store-actions" (click)="editProcessor(rowProcessorDisplay)">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                      <path
                        d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-template>
    <ng-template #showStoreConfig>
      <por-store-config-edit>
      </por-store-config-edit>
    </ng-template>
  </div>
</div>
