import { WizardProcessorStepsEnum } from '../processor-wizard/wizard-processor-steps.enum';

export enum WizardTerminalStepsEnum {
  Loading = 0,
  StoreQuestion = 1,
  ChooseProcessor = 2,
  Terminal = 3,
  Summary = 4,
  Error = 5
}

export type WizardStepsUnion = WizardProcessorStepsEnum | WizardTerminalStepsEnum;

