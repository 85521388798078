/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  Depot,
  PaymentConfig,
  Processor,
  ProcessorFeature,
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { AdminService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { WizardTerminalStepsEnum } from '../../terminal-wizard/wizard-terminal-steps.enum';
import { ProcessorTypeEnumPretty } from '../../../models';

@Component({
  selector: 'por-processor-list',
  templateUrl: './processor-list.component.html',
  styleUrls: ['./processor-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessorListComponent implements OnInit {
  @Input() httpOptions: PaymentApiServiceOptions = {} as PaymentApiServiceOptions;
  @Input() processors: Processor[] = [];
  @Input() depot: Depot = {} as Depot;
  @Input() selectedSavedProcessor: Processor = {} as Processor;

  @Output() readonly newProcessorAdded = new ReplaySubject<Processor>(1);
  @Output() editStep = new EventEmitter<WizardTerminalStepsEnum>();
  @Output() readonly processorEditEvent = new EventEmitter<Processor>();
  @Output() readonly previous = new EventEmitter<void>();

  paymentConfig?: PaymentConfig;
  constructor(
    private readonly paymentAdminService: AdminService,
    private readonly translateService: TranslateService,
  ) {
  }
  selectedProcessorCurrencies: Array<{ code: string; name: string }> = [];
  selectedProcessorType: ProcessorFeature = {} as ProcessorFeature;
  selectedProcessor: Processor = {} as Processor;
  protected readonly ProcessorTypeEnumPretty = ProcessorTypeEnumPretty;

  async ngOnInit() {
    if(this.depot) {
      this.processors = this.processors.filter(processor => processor.RemoteDepotId === this.depot.toString());
    }
    this.paymentConfig = await this.paymentAdminService.fetchPaymentConfig(this.httpOptions);
  }

  async cancel() {
    this.editStep.emit(WizardTerminalStepsEnum.ChooseProcessor);
  }

  async next() {
    if (!this.selectedSavedProcessor.ProcessorId) {
      return this.paymentAdminService.handleError(this.translateService.instant('You must select a Merchant.'));
    }
    this.processorEditEvent.emit(this.selectedSavedProcessor);
  }

  getProcessorType(processor: Processor): string {
    return processor.ProcessorTypeEnum ? this.ProcessorTypeEnumPretty[processor.ProcessorTypeEnum] : '';
  }

  getHeader() {
    if(this.depot) {
      return 'Choose the Merchant you will be adding to ' + this.depot;
    } else {
      return 'Choose the Merchant you will be adding to';
    }
  }
}
