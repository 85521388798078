/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCodes, Currencies, emailValidatorPattern, phoneNumberValidatorPattern } from '@por/por-pay/shared';
import {PaymentConfigExtended} from "../../models";
import { BehaviorSubject } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';

interface PaymentConfigInput {
    paymentConfig: PaymentConfigExtended;
    isLoading: boolean;
}

@Component({
    selector: 'por-payment-config-admin-form',
    templateUrl: './payment-config-admin-form.component.html',
    styleUrls: ['./payment-config-admin-form.component.scss'],
    providers: [{provide: CdkStepper, useExisting: PaymentConfigAdminFormComponent}]
})
export class PaymentConfigAdminFormComponent implements OnInit, OnChanges {
    @Input() paymentConfigInput: {
        paymentConfig: PaymentConfigExtended;
        isLoading: boolean;
    } | null = null;
  @Input() isWizardSetup = false;
  @Output() readonly savePaymentConfigEvent = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();
  @Input() configChanged$ = new BehaviorSubject<boolean>(false);

  paymentConfigForm!: FormGroup;
    countryCodesArray: Array<{ code: string; name: string }> = [];
    currencyArray: Array<{ code: string; name: string }> = [];

    get email() {
        return this.paymentConfigForm.get('Email');
    }
    get address1() {
        return this.paymentConfigForm.get('Address1');
    }
    get primaryPhone() {
        return this.paymentConfigForm.get('PrimaryPhone');
    }
    get city() {
        return this.paymentConfigForm.get('City');
    }
    get state() {
        return this.paymentConfigForm.get('State');
    }
    get postalCode() {
        return this.paymentConfigForm.get('PostalCode');
    }

    ngOnInit(): void {
        this.setupArrays();
        const emailControl = this.paymentConfigForm.get('Email');
        if (emailControl) {
            emailControl.valueChanges.subscribe(value => {
                if (value !== value.toLowerCase()) {
                    emailControl.setValue(value.toLowerCase(), { emitEvent: false }); // prevent infinite loop
                }
            });
        }
    }

    ngOnChanges(
        changes: SimpleChanges & {
            paymentConfigInput: Omit<SimpleChange, 'currentValue'> & {
                currentValue: PaymentConfigInput;
            };
        }
    ): void {
        if (changes['paymentConfigInput']) {
            this.paymentConfigInput = changes.paymentConfigInput.currentValue;
        }
        this.setupPaymentConfigForm();
    }

    private setupArrays() {
        Object.entries(CountryCodes).forEach(countryCode => {
            this.countryCodesArray.push({
                code: countryCode[0] as string,
                name: countryCode[1] as string
            });
        });
        const cur = Currencies;
        // eslint-disable-next-line guard-for-in
        for (const currency in cur) {
            this.currencyArray.push({
                code: cur[currency].code,
                name: cur[currency].name
            });
        }
    }

    private setupPaymentConfigForm() {
        const { PrimaryPhone, Email, Address1, Address2, City, State, PostalCode, Country, DefaultCurrencyCode } = {
            ...this.paymentConfigInput?.paymentConfig
        };

        this.paymentConfigForm = new FormGroup({
            PrimaryPhone: new FormControl(PrimaryPhone, [Validators.pattern(phoneNumberValidatorPattern), Validators.required]),
            Email: new FormControl(Email, [Validators.pattern(emailValidatorPattern), Validators.required]),
            Address1: new FormControl(Address1, [Validators.required]),
            Address2: new FormControl(Address2),
            City: new FormControl(City, [Validators.required]),
            State: new FormControl(State, [Validators.required]),
            PostalCode: new FormControl(PostalCode, [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
            Country: new FormControl(Country, [Validators.required]),
            DefaultCurrencyCode: new FormControl(DefaultCurrencyCode, [Validators.required])
        },{ updateOn: 'change' }
        );
        this.paymentConfigForm.markAllAsTouched(); // Ensures all errors are displayed
    }

    public save() {
        // TODO Remove this short circuit when apx button disablement is properly fixed
        if (this.paymentConfigForm.invalid) {
            return;
        }

        const { Address1, Address2, City, State, Country, DefaultCurrencyCode, PrimaryPhone, Email, PostalCode } = this.paymentConfigForm.value;

        if (this.paymentConfigInput === null) {
            throw new Error('paymentConfigInput is null');
        }

        this.savePaymentConfigEvent.emit({
            ...this.paymentConfigInput.paymentConfig,
            Address1,
            Address2,
            City,
            State,
            Country,
            DefaultCurrencyCode,
            PrimaryPhone,
            Email,
            PostalCode,
            isValid: true
        });
    }

  onCancel() {
    this.cancel.emit();
  }
}
