import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AdminService } from '../../services';
import { PaymentConfigExtended } from '../../models';
import {
  Depot,
  PaymentMethodEnum,
  Processor,
  ProcessorFeature,
  ProcessorTypeEnum,
  SelectedProcessorPaymentMethods,
  Terminal
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { WizardProcessorStepsEnum } from '../processor-wizard/wizard-processor-steps.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'por-payment-methods-component',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatStepper, useExisting: PaymentMethodsComponent }]
})
export class PaymentMethodsComponent implements OnInit {
  paymentMethods!: FormGroup;
  constructor(
    public paymentAdminService: AdminService,
  ) {
  }

  @Input() orgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;
  @Input() processorInput = {
    processor: {} as Processor,
    terminals: [] as Terminal,
    httpOptions: {} as PaymentApiServiceOptions
  };
  @Input() depot: Depot = {} as Depot;
  @Input() selectedPaymentMethods: SelectedProcessorPaymentMethods[] = [];

  @Output() readonly savedProcessorEvent = new EventEmitter<boolean>();
  @Output() readonly processorConfigChangeEvent = new EventEmitter<boolean>();
  @Output() readonly updatedProcessor = new EventEmitter<Processor>();
  @Output() readonly previous = new EventEmitter<void>();
  @Output() readonly processorPaymentMethods = new EventEmitter<SelectedProcessorPaymentMethods[]>();
  @Output() editStep = new EventEmitter<WizardProcessorStepsEnum>();
  @Output() readonly userHasFinishedThisPage = new EventEmitter<boolean>();
  @Output() readonly existingACHProcessorName$ = new EventEmitter<string>();
  @Output() readonly existingCFProcessorName$ = new EventEmitter<string>();
  @Output() readonly existingCCProcessorName$ = new EventEmitter<string>();

  selectedProcessorTypeEnum?: ProcessorTypeEnum;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ProcessorTypeEnum = ProcessorTypeEnum;

  selectedProcessorCurrencies: Array<{ code: string; name: string }> = [];
  selectedProcessorType: ProcessorFeature[] = [];
  selectedProcessor: Processor = {} as Processor;

  existingCFProcessorName = '';
  existingACHProcessorName = '';
  existingCCProcessorName = '';
  isLoading$ = new BehaviorSubject<boolean>(true);

  async ngOnInit(): Promise<void> {
    let hasProcessors = false;
    const rawProcessors: Processor[] = await this.paymentAdminService.fetchProcessors(this.processorInput.httpOptions);
    const processors: Processor[] = rawProcessors.filter(processor => processor.RemoteDepotId === this.depot.toString());

    const aCHProcessor = processors?.find(processor => processor.PaymentMethods?.includes(PaymentMethodEnum.ACH));
    const cFProcessor = processors?.find(processor => processor.IsCustomerFacing === 1);
    const cCProcessor = processors?.find(processor => processor.PaymentMethods?.includes(PaymentMethodEnum.CreditCard));
    if (processors.length > 0) {
      hasProcessors = true;
    }
    this.paymentMethods = new FormGroup({
      creditCard: new FormControl({ value: (this.selectedPaymentMethods.includes(SelectedProcessorPaymentMethods.CreditCard) || !cCProcessor) ? 1 : 0, disabled: !hasProcessors }),
      creditCardCustomerFacing: new FormControl(this.selectedPaymentMethods.includes(SelectedProcessorPaymentMethods.CreditCardCustomerFacing) ? 1 : 0),
      aCH: new FormControl(this.selectedPaymentMethods.includes(SelectedProcessorPaymentMethods.ACH) ? 1 : 0)
    });

    if (aCHProcessor) {
      this.existingACHProcessorName = aCHProcessor.Label ?? '';
      this.existingACHProcessorName$.emit(this.existingACHProcessorName);
    }
    if (cFProcessor) {
      this.existingCFProcessorName = cFProcessor.Label ?? '';
      this.existingCFProcessorName$.emit(this.existingCFProcessorName);
    }
    if (cCProcessor) {
      this.existingCCProcessorName = cCProcessor.Label ?? '';
      this.existingCCProcessorName$.emit(this.existingCCProcessorName);
    }

    this.selectedProcessor = this.processorInput.processor;
    this.selectedProcessorTypeEnum = this.selectedProcessor.ProcessorTypeEnum as ProcessorTypeEnum;
    this.isLoading$.next(false)
  }

  getFormValue() {
    return this.paymentMethods.value;
  }
}
