<div class="question-container">
  <p>{{ question }}</p>
  <div class="buttons-container">
    <button
      apxButton
      *ngFor="let button of buttons"
      (click)="button.callback()"
      matStepperNext
    >
      {{ button.label }}
    </button>
  </div>
</div>
