<div class="por-pay-processor-edit-modal modal-content">
    <div class="por-pay-modal-messages por-pay-modal-center">
        <div id="por-pay-modal-success-message" class="por-pay-message success">
            <div class="por-pay-message-header">
                <span class="por-pay-message-title" translate="Success"></span>
                <span (click)="paymentAdminService.hideModalSuccessMessage()" class="por-pay-message-close">&times;</span>
            </div>
            <span class="por-pay-message-text"></span>
        </div>
        <div id="por-pay-modal-failure-message" class="por-pay-message failure">
            <div class="por-pay-message-header">
                <span class="por-pay-message-title" translate="Error"></span>
                <span (click)="paymentAdminService.hideModalFailureMessage()" class="por-pay-message-close">&times;</span>
            </div>
            <span class="por-pay-message-text"></span>
        </div>
    </div>
  <div class="por-pay-header-container">
    <h4 class="por-pay-title por-pay-ui-form-fields-header">
      {{ getHeader() }}
    </h4>
    <div class="id-container por-pay-title por-pay-ui-form-fields-header" *ngIf="processorEditInput && processorEditInput.processorWRegistrationStatus">
      <span>{{'Merchant ID: ' + processorEditInput.processorWRegistrationStatus.processor.ProcessorId}}</span>
      <button [cdkCopyToClipboard]="processorId" class="copyButton" (cdkCopyToClipboardCopied)="onClipboardCopy($event, 'processor')">
        <svg class="copy-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7
          0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64
          128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0
          35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z" />
        </svg>
      </button>
    </div>
  </div>
  <mat-tab-group class="por-pay-tab-group por-pay-processor-edit">
    <mat-tab>
      <ng-template class="por-pay-tab-label" mat-tab-label>
        {{'Configuration' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <div class="por-pay-processor-config-input-fields-container">
          <p class="isGAdminWarning" *ngIf="!processorEditInput.isGAdmin && (selectedProcessorType === ProcessorTypeEnum.Cayan)">{{'Changing your Site ID and Key are HIGH risk changes that can affect the systems functionality. Please contact support for assistance.' | translate}}</p>
          <div class="por-pay-processor-config-input-fields" *ngIf="processorEditInput && processorEditInput.processorWRegistrationStatus">
            <div class="input-container">
              <mat-form-field class="por-pay-header-input">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input matInput [(ngModel)]="processorEditInput.processorWRegistrationStatus.processor.Label" type="text" />
              </mat-form-field>
              <mat-form-field class="por-pay-header-input por-pay-select" appearance="outline">
                <mat-label class="input-title" translate="Currency"></mat-label>
                <mat-select [(ngModel)]="processorEditInput.processorWRegistrationStatus.processor.DefaultCurrencyCode" (selectionChange)="newCurrencySelected()">
                  <mat-option *ngFor="let currency of selectedProcessorCurrencies" [value]="currency.code">
                    {{ currency.code }} - {{ currency.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="stripeAccountId" id="stripeAccountId" class="input-container">
                <span>{{'Stripe Account ID: ' + stripeAccountId}}</span>
                <button [cdkCopyToClipboard]="stripeAccountId" class="copyButton" (cdkCopyToClipboardCopied)="onClipboardCopy($event, 'account')">
                  <svg class="copy-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7
                    0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64
                    128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0
                    35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z" />
                  </svg>
                </button>
            </div>
          </div>
          <div class="por-pay-processor-config-input-fields por-pay-stripe-registration-component-container" *ngIf="selectedProcessorType === ProcessorTypeEnum.Stripe">
            <por-stripe-registration class="por-pay-center" [stripeRegistrationInput]="stripeRegistrationInput"></por-stripe-registration>
          </div>
          <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.Cayan">
            <!--Cayan-->
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Merchant Name' | translate }}</mat-label>
              <input matInput [(ngModel)]="cayanConfig.MerchantName" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Site ID' | translate }}</mat-label>
              <input matInput [disabled]="!processorEditInput.isGAdmin" [(ngModel)]="cayanConfig.MerchantSiteId" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Key' | translate }}</mat-label>
              <input matInput [disabled]="!processorEditInput.isGAdmin" [(ngModel)]="cayanConfig.MerchantKey" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Web API Key' | translate }}</mat-label>
              <input matInput [(ngModel)]="cayanConfig.ApiKey" type="text" />
            </mat-form-field>
          </div>
          <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.PeachPayments">
            <!--Peach-->
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Authorization Header' | translate }}</mat-label>
              <input matInput [(ngModel)]="peachConfig.AuthorizationHeader" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ '3D Secure Entity ID' | translate }}</mat-label>
              <input matInput [(ngModel)]="peachConfig.ThreeDSecureEntityId" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'Recurring Entity ID' | translate }}</mat-label>
              <input matInput [(ngModel)]="peachConfig.RecurringEntityId" type="text" />
            </mat-form-field>
          </div>
          <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.EziDebit">
            <!--EziDebit-->
            <mat-form-field class="por-pay-body-input ezDebitInput">
              <mat-label>{{ 'Digital Key' | translate }}</mat-label>
              <input matInput [(ngModel)]="eziDebitConfig.DigitalKey" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input ezDebitInput">
              <mat-label>{{ 'Public Key' | translate }}</mat-label>
              <input matInput [(ngModel)]="eziDebitConfig.PublicApiKey" type="text" />
            </mat-form-field>
          </div>
          <div class="por-pay-processor-config-input-fields" *ngIf="selectedProcessorType === ProcessorTypeEnum.GPIMITC">
            <!--GPI PayFields/MITC-->
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'X-Web ID' | translate }}</mat-label>
              <input matInput [(ngModel)]="mITCConfig.XwebId" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'MOTO Terminal ID' | translate }}</mat-label>
              <input matInput [(ngModel)]="mITCConfig.XwebTerminalId" type="text" />
            </mat-form-field>
            <mat-form-field class="por-pay-body-input">
              <mat-label>{{ 'MOTO AuthKey' | translate }}</mat-label>
              <input matInput [(ngModel)]="mITCConfig.XwebKey" type="text" />
            </mat-form-field>
          </div>
          <div class="por-pay-button-container por-pay-left">
            <div class="por-pay-button-delete-container">
              <button id="por-pay-delete-processor-edit" class="por-pay-process-button" (click)="deleteProcessor()">
                {{ 'Delete Merchant' | translate }}
              </button>
            </div>
            <div class="por-pay-button-save-container por-pay-right">
              <button id="por-pay-save-processor-edit" class="por-pay-process-button" (click)="save()" translate="Save"></button>
              <button id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" (click)="cancel()">
                {{ 'Cancel' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="supportsTerminals()">
      <ng-template class="por-pay-tab-label" mat-tab-label>
        {{'Terminals' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <div class="por-pay-terminal-list-container" [ngClass]="{ editing: (isEditingTerminal$ | async) }">
          <por-terminal-list
            class="por-pay-terminal-list"
            [disabledList]="(isEditingTerminal$ | async) ?? false"
            [class.full-width]="(isEditingTerminal$ | async) === false"
            [terminalsInput]="(terminalsInput$ | async) ?? defaultTerminalsInput"
            (terminalsInputChanged)="setTerminalList($event)"
            (terminalEditEvent)="editTerminal($event)"
            (terminalCancelEvent)="cancel()"></por-terminal-list>
          <por-terminal-edit
            *ngIf="isEditingTerminal$ | async"
            class="por-pay-terminal-edit"
            [isGAdmin]="processorEditInput.isGAdmin"
            [terminalConfig]="getTerminalToEditValue()"
            (terminalConfigChangeEvent)="setTerminalIsDirty($event)"
            (terminalCancelEditEvent)="cancelTerminalEdit($event)"
            (terminalDeletedEvent)="deleteTerminal($event)"
            (terminalSavedEvent)="savedTerminal($event)"></por-terminal-edit>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
