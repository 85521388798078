export enum WizardProcessorStepsEnum {
  Error = -1,
  Loading = 0,
  OrgConfig = 1,
  StoreQuestion = 2,
  ChooseProcessor = 3,
  AddProcessor = 4,
  ProcessorMethods = 5,
  TerminalQuestion = 6,
  Terminal = 7,
  Summary = 8,
  StripeRegistration = 9
}
