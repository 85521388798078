<div id="apx">
  <div class="por-pay-tab-group new-por-pay-processor-edit">
    <h2 class="por-pay-title por-pay-ui-form-fields-header">
      {{ getHeader() | translate }}
    </h2>
    <p>{{'Add your merchant credentials.' | translate}}</p>
      <div class="por-pay-processor-config-input-fields-container">
        <div class="add-form-input-container" *ngIf="processorInput.processor">
          <apx-form-field class="por-pay-header-input" label="{{ 'Name' | translate }}">
            <input apxInput [(ngModel)]="processorInput.processor['Label']" type="text" />
          </apx-form-field>
          <mat-form-field class="por-pay-header-input por-pay-select" appearance="outline">
            <mat-label class="input-title" translate="Currency"></mat-label>
            <mat-select [(ngModel)]="processorInput.processor.DefaultCurrencyCode" (selectionChange)="newCurrencySelected()">
              <mat-option *ngFor="let currency of selectedProcessorCurrencies" [value]="currency.code">
                {{ currency.code }} - {{ currency.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--Cayan-->
        <div class="por-pay-processor-add-form-input-fields" *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.Cayan">
          <apx-form-field class="por-pay-header-input" label="{{ 'Merchant Name' | translate }}">
            <input apxInput [(ngModel)]="cayanConfig.MerchantName" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'Site ID' | translate }}">
            <input apxInput [disabled]="!orgConfig.isGAdmin" [(ngModel)]="cayanConfig.MerchantSiteId" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-add-form-input-fields" *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.Cayan">
          <apx-form-field class="por-pay-header-input" label="{{ 'Key' | translate }}">
            <input apxInput [disabled]="!orgConfig.isGAdmin" [(ngModel)]="cayanConfig.MerchantKey" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'Web API Key' | translate }}">
            <input apxInput [(ngModel)]="cayanConfig.ApiKey" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-add-form-input-fields" *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.PeachPayments">
          <!--Peach-->
          <apx-form-field class="por-pay-header-input" label="{{ 'Authorization Header' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.AuthorizationHeader" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ '3D Secure Entity ID' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.ThreeDSecureEntityId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'Recurring Entity ID' | translate }}">
            <input apxInput [(ngModel)]="peachConfig.RecurringEntityId" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-add-form-input-fields" *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.EziDebit">
          <!--EziDebit-->
          <apx-form-field class="por-pay-header-input" label="{{ 'Digital Key' | translate }}">
            <input apxInput [(ngModel)]="eziDebitConfig.DigitalKey" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'Public Key' | translate }}">
            <input apxInput [(ngModel)]="eziDebitConfig.PublicApiKey" type="text" />
          </apx-form-field>
        </div>
        <div class="por-pay-processor-add-form-input-fields" *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.GPIMITC">
          <!--GPI PayFields/MITC-->
          <apx-form-field class="por-pay-header-input" label="{{ 'X-Web ID' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'Terminal ID' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebTerminalId" type="text" />
          </apx-form-field>
          <apx-form-field class="por-pay-header-input" label="{{ 'AuthKey' | translate }}">
            <input apxInput [(ngModel)]="mITCConfig.XwebKey" type="text" />
          </apx-form-field>
        </div>
        <div *ngIf="depot">
          <por-payment-methods-component
            [selectedPaymentMethods]="selectedPaymentMethodsInput"
            [orgConfig]="orgConfig"
            [depot]="depot"
            (existingCCProcessorName$)="getExistingCCProcessorName($event)"
            (existingACHProcessorName$)="getExistingACHProcessorName($event)"
            (existingCFProcessorName$)="getExistingCFProcessorName($event)"
            [processorInput]="processorInput">
          </por-payment-methods-component>
        </div>
        <div class="por-pay-button-container por-pay-left">
          <div class="por-pay-button-save-container">
            <button matStepperPrevious apxButton id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" variant="secondary" (click)="cancel()">
              {{ 'Back' | translate }}
            </button>
            <button matStepperNext apxButton id="por-pay-save-processor-edit" class="por-pay-process-button" color="primary" (click)="next()" translate="Next"></button>
          </div>
        </div>
      </div>
  </div>
</div>
