/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface Customer_Processor {
    Customer_ProcessorId?: number | string;
    Hidden?: number;
    CustomerId?: number;
    /**
     * The ProcessorTypeEnum that this TransactionLog corresponds to.
     */
    ProcessorId?: string;
    ProcessorCustomerId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
}
