import { Component, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { BottomSheetElement } from '../../models';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'por-app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  providers: [{provide: MatStepper, useExisting: BottomSheetComponent}]
})
export class BottomSheetComponent {
  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { message: string; elements: BottomSheetElement[] }
  ) {}

  elementClicked(element: BottomSheetElement) {
    this.bottomSheetRef.dismiss(element);
  }
}
