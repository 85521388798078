import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Language, UIMissingTranslationHandler, UITranslateLoader } from '@por/por-pay/shared';
import * as enUSTranslations from '../assets/en-US.json';
import * as frCATranslations from '../assets/fr-CA.json';
import {
  BottomSheetComponent,
  DepotConfigListComponent,
  TerminalListComponent,
  StripeRegistrationComponent,
  PaymentConfigAdminFormComponent,
  PaymentAdminComponent, ProcessorAdminListComponent
} from './components';
import { AdminService } from './services';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { ProcessorEditFormComponent } from './components/processor-edit/processor-edit-form/processor-edit-form.component';
import { TerminalEditComponent } from './components/terminals/terminal-edit/terminal-edit.component';
import { MatListModule } from '@angular/material/list';
import { ProcessorAddFormComponent } from './components/processor/add/processor-add-form/processor-add-form.component';
import { StoreConfigEditComponent } from './components/store-config/edit/store-config-edit.component';
import { ChooseAStoreComponent } from './components/choose-a-store/choose-a-store.component';
import { QuestionComponent } from './components/question';
import { ProcessorWizardComponent } from './components/processor-wizard';
import { TerminalWizardComponent } from './components/terminal-wizard/terminal-wizard.component';
import { ProcessorAddComponent } from './components/processor-add/add-processor/processor-add.component';
import { SummaryComponent } from './components/summary';
import { ChooseProcessorComponent } from './components/processor-add/choose-processor/choose-processor.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { ProcessorListComponent } from './components/processor-add/processor-list/processor-list.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    ClipboardModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    CdkStepperModule,
    MatStepperModule,
    RouterModule.forRoot(
      [
        {
          path: 'payment-admin',
          component: PaymentAdminComponent
        }
      ]
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: () => {
          return new UITranslateLoader({
            [Language.EN_US]: enUSTranslations,
            [Language.FR_CA]: frCATranslations
          });
        }
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: UIMissingTranslationHandler
      },
      defaultLanguage: Language.EN_US
    }),
    MatTooltipModule,
    MatTabsModule,
    MatListModule,
    MatRadioModule,
    MatStepperModule
  ],
  declarations: [
    BottomSheetComponent,
    PaymentAdminComponent,
    PaymentConfigAdminFormComponent,
    ProcessorAdminListComponent,
    StripeRegistrationComponent,
    TerminalListComponent,
    ProcessorEditFormComponent,
    TerminalEditComponent,
    ProcessorAddFormComponent,
    DepotConfigListComponent,
    StoreConfigEditComponent,
    ChooseAStoreComponent,
    QuestionComponent,
    ProcessorWizardComponent,
    TerminalWizardComponent,
    ProcessorAddComponent,
    SummaryComponent,
    ChooseProcessorComponent,
    PaymentMethodsComponent,
    ProcessorListComponent
  ],
  providers: [
    AdminService,
    MatBottomSheet,
  ],
  exports: [PaymentAdminComponent, StripeRegistrationComponent],
})
export class PaymentAdminElementModule {}
