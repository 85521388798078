<div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
  <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
  <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
</div>
<form class="por-pay-terminal-edit-form" *ngIf="(isLoading$ | async) === false" [formGroup]="terminalForm" (keydown.enter)="$event.preventDefault()">
    <div [ngClass]="isWizardSetup ? 'wizard-setup' : ''" class="por-pay-terminal-edit-form-container">
      <h2 class="por-pay-title" *ngIf="isWizardSetup">{{ getHeader() | translate }}</h2>
      <p *ngIf="isWizardSetup">{{ 'Add your terminal credentials.' | translate }}</p>
        <div [ngClass]="isWizardSetup ? 'wizard-setup-form-fields' : ''" class="por-pay-terminal-edit-form-fields">
            <div [ngClass]="isWizardSetup ? 'wizard-center-div' : ''" class="por-pay-terminal-edit-header-fields">
              <mat-form-field id="por-pay-terminal-edit-label" class="por-pay-header-input">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input matInput formControlName="terminalLabel" type="text" />
              </mat-form-field>
              <mat-form-field [ngClass]="(isWizardSetup || isTerminalWizard) ? 'wizard-no-delete' : 'por-pay-header-input'" id="por-pay-terminal-edit-terminalId">
                <mat-label>{{ 'Terminal Id' | translate }}</mat-label>
                <input matInput type="text" formControlName="terminalId" placeholder="{{ 'New Terminal Id' | translate }}" />
                  <button matSuffix [cdkCopyToClipboard]="terminal.TerminalId ?? ''" (cdkCopyToClipboardCopied)="onClipboardCopy($event)" [hidden]="!terminal.TerminalId">
                      <svg class="copy-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                          d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7
                          0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64
                          128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0
                          35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z" />
                      </svg>
                  </button>
              </mat-form-field>
              <ng-container *ngFor="let termProp of terminalFormProps">
                <ng-container *ngIf="termProp.key === 'IpAddress'; else normalField">
                  <mat-form-field
                  [ngClass]="isWizardSetup ? 'por-pay-header-input' : 'por-pay-body-input'">
                    <mat-label>{{ termProp.label | translate }}</mat-label>
                    <input matInput formControlName="{{ termProp.key }}" type="text" />
                    <mat-hint *ngIf="(ipAddress?.valid && ipAddress?.value)">{{ 'Valid IP address' | translate }}</mat-hint>
                    <mat-error *ngIf="(!ipAddress?.valid && !!ipAddress?.value) && ipAddress?.errors?.['terminalUnreachable']">{{ 'Unable to reach the Terminal IP Provided' | translate }}</mat-error>
                    <mat-error *ngIf="(!ipAddress?.valid && !!ipAddress?.value) && ipAddress?.errors?.['invalidIp']">{{ 'Invalid IP address' | translate }}</mat-error>
                    <mat-error *ngIf="ipAddress?.invalid && !ipAddress?.value">{{ 'Required Field' | translate }}</mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-template #normalField>
                  <mat-form-field [ngClass]="(isWizardSetup || isTerminalWizard) ? (termProp.key === 'TerminalId' ? 'wizard-no-delete' : 'por-pay-header-input') : (termProp.key === 'XwebKey') ? 'mITCPadding' : 'por-pay-body-input'">
                    <mat-label>{{ termProp.label | translate }}</mat-label>
                    <input matInput formControlName="{{ termProp.key }}" type="text" />
                    <mat-hint>{{ termProp.hint | translate }}</mat-hint>
                    <mat-error *ngIf="isRegistrationRequired(termProp.key)">{{ 'Required Field' | translate }}</mat-error>
                  </mat-form-field>
                </ng-template>
              </ng-container>
            </div>
          <div class="por-pay-terminal-edit-body-fields">
            <ng-container *ngIf="processor.ProcessorTypeEnum === processorTypeEnum.Stripe && (terminalLocationLoaded$ | async)">
              <form [ngClass]="isWizardSetup ? 'wizard-center-div' : ''" class="por-pay-terminal-location-form" [formGroup]="terminalLocationAddressForm" (keydown.enter)="$event.preventDefault()">
                <ng-container *ngFor="let termLocationProp of terminalLocationAddressFormProps">
                  <mat-form-field
                    *ngIf="termLocationProp.key !== 'country'"
                    [ngClass]="isWizardSetup ? 'por-pay-header-input' : 'por-pay-body-input'">
                    <mat-label>{{ termLocationProp.label | translate }}</mat-label>
                    <input matInput formControlName="{{ termLocationProp.key }}" type="text" />
                    <mat-hint>{{ termLocationProp.hint | translate }}</mat-hint>
                    <mat-error *ngIf="isRequired(termLocationProp.key)">{{ 'Required Field' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field [ngClass]="isWizardSetup ? 'por-pay-header-input' : 'por-pay-body-input'" class="por-pay-select" appearance="outline" *ngIf="termLocationProp.key === 'country'">
                    <mat-label class="input-title" translate="Country"></mat-label>
                    <mat-select formControlName="{{ termLocationProp.key }}">
                      <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                        {{ country.code }}
                        - {{ country.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </form>
            </ng-container>
          </div>
          <div class="por-pay-button-container">
            <div [ngClass]="isWizardSetup ? 'wizard-no-delete' : ''" class="por-pay-button-delete-container por-pay-left">
              <button id="por-pay-delete-terminal" class="por-pay-process-button" *ngIf="terminal.TerminalId" (click)="delete()">
                {{ 'Delete Terminal' | translate }}
              </button>
            </div>
            <div [ngClass]="isWizardSetup ? 'center-btn-container' : 'por-pay-right flip-buttons'" class="por-pay-button-save-container">
              <button matStepperPrevious id="por-pay-cancel-terminal" class="por-pay-cancel-button" (click)="cancel()">
                {{ isWizardSetup ? ('Back' | translate) : ('Cancel' | translate) }}
              </button>
              <button
                matStepperNext
                id="por-pay-save-terminal"
                class="por-pay-process-button"
                (click)="save()">{{ isWizardSetup ? ('Next' | translate) : ('Save' | translate) }}</button>
            </div>
          </div>
        </div>
    </div>
</form>
