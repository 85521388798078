<div id="por-pay-terminal-list-component">
    <div class="por-pay-terminal-list">
        <mat-list>
            <h4 id="terminalListTitle">{{ 'Terminals' | translate }}</h4>
            <div class="scrollable-list" #scrollableList>
                <mat-list-item
                    id="por-pay-terminal-list-item"
                    *ngFor="let terminal of terminalsInput.terminals; let i = index"
                    [class.alternate]="i % 2 === 0"
                    [class.selected]="terminal === selectedTerminal"
                    [disabled]="disabledList"
                    (click)="!disabledList && editTerminal(terminal)">
                    <h4 mat-line>{{ terminal.Label }}</h4>
                    <mat-divider [inset]="true"></mat-divider>
                </mat-list-item>
                <mat-list-item *ngIf="terminalsInput.terminals.length === 0">
                    <h4 mat-line>{{ 'No terminal registered.' | translate }}</h4>
                </mat-list-item>
            </div>
        </mat-list>
    </div>
    <div class="por-pay-button-container" *ngIf="!disabledList">
        <div class="por-pay-button-save-container por-pay-right">
            <button apxButton id="por-pay-add-terminal" class="por-pay-button" variant="primary" (click)="addTerminal()">
                {{ 'Add Terminal' | translate }}
            </button>
            <button apxButton id="por-pay-cancel-terminal-list" class="por-pay-cancel-button" variant="secondary" (click)="cancel()">
                {{ 'Cancel' | translate }}
            </button>
        </div>
    </div>
</div>
