/* eslint-disable @typescript-eslint/naming-convention */
import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import {
  Depot,
  Processor,
  ProcessorFeature,
  ProcessorTypeEnum,
  RegistrationStatusCode,
  SelectedProcessorPaymentMethods,
  Terminal
} from '@por/por-pay/shared';
import { PaymentApiServiceOptions } from '@por/por-pay/shared/ui';
import { BehaviorSubject } from 'rxjs';
import { PaymentAdminStripeRegistrationInput, PaymentConfigExtended, ProcessorExtended } from '../../models';
import { AdminService, BottomSheetService } from '../../services';
import { WizardStepsUnion } from '../terminal-wizard/wizard-terminal-steps.enum';
import { TerminalEditConfig } from '../terminals/terminal-edit/terminalEditConfig';
import { WizardProcessorStepsEnum } from './wizard-processor-steps.enum';

@Component({
  selector: 'por-processor-wizard',
  templateUrl: './processor-wizard.component.html',
  styleUrls: ['./processor-wizard.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: ProcessorWizardComponent }]
})
export class ProcessorWizardComponent implements OnInit {

  @Input() httpOptions: PaymentApiServiceOptions = {};
  @Input() existingOrgConfig: PaymentConfigExtended = {} as PaymentConfigExtended;

  @Output() readonly wizardComplete = new EventEmitter<void>();
  @Output() readonly terminalEditEvent = new EventEmitter<Terminal>();
  @Output() readonly changedTerminalEvent = new EventEmitter<boolean>();
  @Output() readonly terminalsInputChanged = new EventEmitter<{
    processor: ProcessorExtended;
    terminals: Terminal[];
    httpOptions: PaymentApiServiceOptions;
  }>();
  @Output() readonly changedPaymentConfig = new EventEmitter<PaymentConfigExtended>();
  @Output() readonly wizardCanceled = new EventEmitter<void>();
  @Output() savedProcessor = new EventEmitter<Processor>();

  @ViewChild('stepper') stepper!: MatStepper;
  // Variables
  protected readonly wizardStepsEnum = WizardProcessorStepsEnum;
  currentStep$: BehaviorSubject<WizardStepsUnion> = new BehaviorSubject<WizardStepsUnion>(WizardProcessorStepsEnum.Loading);
  isEditingProcessor$ = new BehaviorSubject<boolean>(false);
  defaultProcessorInput = {
    processor: {} as ProcessorExtended,
    terminals: [] as Terminal[],
    httpOptions: {} as PaymentApiServiceOptions
  };
  terminalsInput$ = new BehaviorSubject<{
    processor: Processor;
    terminals: Terminal[];
    httpOptions: PaymentApiServiceOptions;
  }>(this.defaultProcessorInput);
  // Data to save
  selectedDepot: Depot = {} as Depot;
  selectedDepotName = '';
  selectedProcessor: ProcessorExtended = {};
  selectedTerminal: Terminal = {} as Terminal;
  selectedPaymentMethods: SelectedProcessorPaymentMethods[] = [];
  terminalToEdit$ = new BehaviorSubject<TerminalEditConfig>({} as TerminalEditConfig);
  defaultTerminalInput = {
    processor: {} as ProcessorExtended,
    terminal: {} as Terminal,
    httpOptions: {} as PaymentApiServiceOptions
  };
  isEditingTerminal$ = new BehaviorSubject<boolean>(false);
  stripeRegistrationInput: PaymentAdminStripeRegistrationInput = {} as PaymentAdminStripeRegistrationInput;
  errorMessageToShow = '';
  isSetupWizard = false;
  selectedProcessorType: ProcessorFeature = {} as ProcessorFeature;
  userHasFinishedPaymentMethods = false
  selectedProcessorTypeEnum?: ProcessorTypeEnum;

  constructor(readonly paymentAdminService: AdminService, public translate: TranslateService, private readonly bottomSheetService: BottomSheetService) { }

  ngOnInit(): void {
    this.loadDepotData().then(() => {
      if (this.existingOrgConfig.isValid) {
        const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
        this.stepper.selectedIndex = summaryStepIndex;
        this.currentStep$.next(WizardProcessorStepsEnum.StoreQuestion);
      }
      else {
        this.isSetupWizard = true;
        const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Organization Info');
        this.stepper.selectedIndex = summaryStepIndex;
        this.currentStep$.next(WizardProcessorStepsEnum.OrgConfig);
      }
    }).catch(() => {
      this.errorMessageToShow = 'Unable to retrieve your depot information. Please call support.'
      this.currentStep$.next(WizardProcessorStepsEnum.Error);
    });
  }

  // Organization Info
  async onSavePaymentConfig(orgConfigToSave: PaymentConfigExtended): Promise<void> {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
    this.stepper.selectedIndex = summaryStepIndex;
    this.existingOrgConfig = orgConfigToSave;
    this.currentStep$.next(WizardProcessorStepsEnum.StoreQuestion);
  }

  // Choose a Store
  orgDepots: Depot[] = [];
  async loadDepotData() {
    this.orgDepots = await this.paymentAdminService.fetchDepots(this.httpOptions, false, true);
    this.orgDepots = this.orgDepots.filter(depot => depot.Id);
  }

  onChooseStoreComplete($event: Depot) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant');
    this.stepper.selectedIndex = summaryStepIndex;
    this.selectedDepot = $event;
    this.currentStep$.next(WizardProcessorStepsEnum.ChooseProcessor);
  }

  onSelectDepotName($event: string) {
    this.selectedDepotName = $event;
  }

  getStripeRegistrationInput($event: PaymentAdminStripeRegistrationInput) {
    this.stripeRegistrationInput = $event;
  }

  onChooseStoreCancel() {
    this.wizardCanceled.emit();
  }

  // Processor

  onProcessorComplete($event: Processor) {
    this.selectedProcessor = $event;
    // stripe registration happens after the processor has been saved so dont check registration status here
    if (this.selectedProcessor.ProcessorTypeEnum !== ProcessorTypeEnum.Stripe) {
      this.paymentAdminService.fetchRegistrationStatusForProcessor(this.selectedProcessor, this.httpOptions).then((status) => {
        if (status.RegistrationStatusCode === RegistrationStatusCode.Nay) {
          const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant');
          this.stepper.selectedIndex = summaryStepIndex;
          this.currentStep$.next(WizardProcessorStepsEnum.AddProcessor);
          return this.paymentAdminService.handleError(this.translate.instant(status.Message));
        }
      });
    }
  }

  selectedProcessorPaymentMethods($event: SelectedProcessorPaymentMethods[]) {
    const summaryStepIndex = ((this.selectedProcessor.ProcessorTypeEnum === ProcessorTypeEnum.PeachPayments) || (this.selectedProcessor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe)) ?
      this.stepper?.steps?.toArray().findIndex(step => step.label === 'Summary') :
      this.stepper?.steps?.toArray().findIndex(step => step.label === 'Terminal');
    this.stepper.selectedIndex = summaryStepIndex;
    this.selectedPaymentMethods = $event;
    const nextStep = ((this.selectedProcessor.ProcessorTypeEnum === ProcessorTypeEnum.PeachPayments) || (this.selectedProcessor.ProcessorTypeEnum === ProcessorTypeEnum.Stripe)) ?
      WizardProcessorStepsEnum.Summary : WizardProcessorStepsEnum.TerminalQuestion;
    this.currentStep$.next(nextStep);
  }

  userHasFinishedThisPage(event: boolean) {
    this.userHasFinishedPaymentMethods = event
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onProcessorBack($event: WizardProcessorStepsEnum) {
    let storeName = '';
    if ($event === WizardProcessorStepsEnum.StoreQuestion) {
      storeName = 'Choose Store'
    } else {
      storeName = 'Merchant'
    }
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === storeName);
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);
  }

  async onChooseStoreBack($event: WizardStepsUnion) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Choose Store');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardProcessorStepsEnum.StoreQuestion);
  }


  async onProcessorAddBack($event: WizardProcessorStepsEnum) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);
  }

  async onUseCaseBack($event: WizardProcessorStepsEnum) {
    const summaryStepIndex = $event === WizardProcessorStepsEnum.AddProcessor ? this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant') : this.stepper?.steps?.toArray().findIndex(step => step.label === 'Use Case');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);

  }

  // Terminal Question
  terminalQuestion = 'Do you have a terminal that you would like to setup?';
  buttonOptions = [
    { label: 'Yes', callback: this.onTerminalQuestionYes.bind(this) },
    { label: 'No', callback: this.onTerminalQuestionNo.bind(this) }
  ];

  onTerminalQuestionYes() {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Terminal');
    this.stepper.selectedIndex = summaryStepIndex;
    this.defaultProcessorInput.processor = this.selectedProcessor;
    this.defaultProcessorInput.httpOptions = this.httpOptions;
    if (!this.selectedTerminal.Terminal) {
      const newTerminal: Terminal = {
        TerminalId: '',
        ProcessorId: this.defaultProcessorInput.processor.ProcessorId,
        OrganizationId: this.defaultProcessorInput.processor.OrganizationId,
        Label: 'New Terminal'
      };

      if (this.defaultProcessorInput.processor.ProcessorTypeEnum === undefined) {
        throw new Error('ProcessorTypeEnum is undefined');
      }

      this.defaultProcessorInput.terminals.push(newTerminal);
      this.terminalsInputChanged.emit({
        ...this.defaultProcessorInput,
        terminals: this.defaultProcessorInput.terminals
      });

      this.terminalToEdit$.next({
        processor: this.defaultProcessorInput.processor,
        httpOptions: this.defaultProcessorInput.httpOptions,
        terminal: newTerminal
      });
      this.selectedTerminal = newTerminal;
    }
    this.editTerminal(this.selectedTerminal);
    this.isSetupWizard = true;
    this.currentStep$.next(WizardProcessorStepsEnum.Terminal);
  }

  onTerminalQuestionNo() {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Summary');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(WizardProcessorStepsEnum.Summary);
  }

  // Terminal
  async savedTerminal(updatedTerminal: Terminal) {
    if (!updatedTerminal) { return; }

    this.selectedTerminal = updatedTerminal;
    this.terminalEditEvent.emit(updatedTerminal);
  }
  editTerminal(terminal: Terminal) {
    this.selectedTerminal = terminal;
    this.terminalEditEvent.emit(terminal);
  }

  setTerminalIsDirty(isTerminalDirty: boolean) {
    this.isEditingTerminal$.next(isTerminalDirty);
  }

  onTerminalBack($event: WizardStepsUnion) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Terminal');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next($event);
  }

  onTerminalComplete($event: Terminal) {
    this.selectedTerminal = $event;
    this.terminalEditEvent.emit(this.selectedTerminal);
    this.terminalToEdit$.next({ httpOptions: this.httpOptions, processor: this.selectedProcessor, terminal: this.selectedTerminal });
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Summary');
    this.stepper.selectedIndex = summaryStepIndex;
    this.currentStep$.next(this.wizardStepsEnum.Summary);
  }

  // Summary
  onSummaryCancel() {
    this.wizardCanceled.emit();
  }

  onRegistrationCancel() {
    this.changedPaymentConfig.emit(this.existingOrgConfig);
    this.wizardComplete.emit();
  }

  onSummaryComplete() {
    this.changedPaymentConfig.emit(this.existingOrgConfig);
    this.wizardComplete.emit();
  }

  onSavedProcessor(processor:Processor) {
    this.savedProcessor.emit(processor);
  }

  onSummaryEdit($event: WizardStepsUnion) {
    this.userHasFinishedPaymentMethods = false
    let summaryStepIndex = 0;
    if ($event === WizardProcessorStepsEnum.Terminal) {
      summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Terminal');
    } else if ($event === WizardProcessorStepsEnum.AddProcessor) {
      summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant');
    } else if ($event === WizardProcessorStepsEnum.OrgConfig) {
      summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Organization Info');
    } else {
      summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Stripe Registration');
    }
    this.stepper.selectedIndex = summaryStepIndex;

    this.isSetupWizard = true;
    this.currentStep$.next($event);
  }

  async editProcessor(processorToEdit: Processor) {
    const summaryStepIndex = this.stepper?.steps?.toArray().findIndex(step => step.label === 'Merchant');
    this.stepper.selectedIndex = summaryStepIndex;
    this.isEditingProcessor$.next(true);
    this.terminalsInput$.next({ httpOptions: this.httpOptions, processor: processorToEdit, terminals: [] });
    this.currentStep$.next(WizardProcessorStepsEnum.AddProcessor);
    this.selectedProcessorTypeEnum = processorToEdit.ProcessorTypeEnum;
  }

  async savedProcessorType($event: ProcessorFeature) {
    this.selectedProcessorType = $event;
  }

  protected readonly ProcessorTypeEnum = ProcessorTypeEnum;
}
