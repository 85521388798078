/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-control-regex
export const emailValidatorPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberValidatorPattern = /^\+?\d{1,3}\d{10}$/;
export const ccNumberValidatorPattern = /^[0-9]*$/;
export const ipValidatorPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const porDebugApiConfigLocalStoreKey = 'por-debug-api-config';

export function getDataFromStorage<T>(key: string): T {
    return <T>JSON.parse(localStorage.getItem(key) as string);
}

export interface PaymentApiConfig {
    ApiKey?: string;
    AdminApiKey?: string;
    OrganizationId?: number;
    DbId?: number;
    ApiUrl?: string;
    Locale?: string;
    FailureUrl?: string;
    SuccessUrl?: string;
}
